@import '../../assets/theme/variable.scss';
.order-summry {
	width: 34%;
	@media (max-width: 1200px) {
		width: 37%;
	}

	.ordr-summary {
		background: #ffffff;
		border: 1px solid rgba(254, 110, 107, 0.3490196078);
		border-radius: 10px;
		padding: 10px 20px;
		h2 {
			font-weight: 600;
			font-size: 24px;
			line-height: 42px;
			color: #565656;
			@media (max-width: 1200px) {
				font-size: 20px;
				line-height: 32px;
			}
		}
		p {
			font-weight: 400;
			font-size: 14px;
			// line-height: 34px;
			color: rgb(86 86 86 / 54%);
			padding-bottom: 10px;
			@media (max-width: 1200px) {
				font-size: 13px;
				// line-height: 32px;
			}
		}
		.css-9mgopn-MuiDivider-root {
			border-bottom: 1px solid rgba(254, 110, 107, 0.3490196078);
			// padding-top: 10px;
		}
	}
	.ordr_card_bx {
		background-color: #fff;
		border-radius: 20px;
		padding: 15px;
		display: flex;
		align-items: center;
		gap: 15px;

		figure {
			width: 90px;
			height: 90px;
			@media (max-width: 1200px) {
				width: 80px;
				height: 80px;
			}
			img {
				border-radius: 15px;
			}
		}
		h4 {
			color: #565656;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			@media (max-width: 1200px) {
				font-size: 16px;
				line-height: 20px;
			}
		}
		p {
			color: #565656;
			opacity: 0.6;
			font-weight: 400;
			font-size: 14px;
			line-height: 26px;
			padding: 0;
			@media (max-width: 1200px) {
				font-size: 13px;
				line-height: 22px;
			}
		}
		h2 {
			color: #fe6e6b;
			font-weight: 600;
			font-size: 20.3369px;
			line-height: 30px;
			@media (max-width: 1200px) {
				font-size: 18.3369px;
				line-height: 28px;
			}
		}
	}
	.align_div {
		display: flex;
		justify-content: space-between;
		padding-top: 10px;
		@media (max-width: 1200px) {
			padding-top: 6px;
		}
		p {
			color: #565656;
			font-size: 14px;
			@media (max-width: 1200px) {
				font-size: 13px;
			}
		}
		h4 {
			color: #333;
			font-weight: 700;
			font-size: 18px;
			line-height: 36px;
			@media (max-width: 1200px) {
				font-size: 16px;
			}
		}
	}
	.cntr-txt {
		text-align: center;
	}
	.sr_align {
		display: flex;
		justify-content: space-between;
		p {
			color: #565656;
			font-size: 14px;
			@media (max-width: 1200px) {
				font-size: 13px;
			}
		}
		h4 {
			color: #333;
			font-weight: 700;
			font-size: 22px;
			line-height: 43px;
		}
	}

	.cpn-code {
		.MuiFormControl-root {
			width: 100%;
			background: rgb(156 151 151 / 8%) !important;
			// border-bottom: 1px solid $white !important;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
			border-radius: 40px !important;
			margin-top: 20px !important;
			margin-right: 20px !important;
			margin-bottom: 0 !important;
			@media (max-width: 1200px) {
				margin-top: 10px !important;
			}
			& > div {
				padding-right: 2px !important;
			}
			input {
				padding: 8px 14px;
				&::placeholder {
					font-size: 14px !important;
					@media (max-width: 1200px) {
						font-size: 13px;
					}
				}
			}
			fieldset {
				display: none;
			}
		}
		.applybtn {
			background: green;
			border: none;
			outline: none;
			cursor: pointer;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			padding: 10px;
			border-radius: 50px;
			color: white;
			font-weight: 600;
		}
	}

	.ordernline {
		display: flex;
		align-items: flex-start;
		padding-top: 10px;
		figure {
			width: 20px;
			height: 20px;
			margin-right: 10px;
			@media (max-width: 1200px) {
				width: 17px;
				height: 20px;
			}
		}
		p {
			font-size: 14px;
			color: $secondary;
			padding-bottom: 0;
			font-weight: 600;
			@media (max-width: 1200px) {
				font-size: 13px;
			}
			span {
				font-weight: 400;
			}
		}
	}
	.coup_code {
		@extend .align_div;
		h4 {
			color: #fe6e6b;
		}
	}
}
.book-apntmnt {
	// margin-top: 86px;
	padding-top: 90px;
	display: flex;
	// flex-wrap: wrap;
	justify-content: space-between;
	gap: 20px;
	margin-bottom: 120px;
	.book-appont-sec {
		width: 60%;
		background: #ffffff;
		border: 1px solid #5656561c;
		border-radius: 10px;
		padding: 17px 17px;
		.check-avlbl {
			background: #ffffff;
			border: 1px solid rgba(254, 110, 107, 0.3490196078);
			border-radius: 10px;
			padding: 22px 30px;
			h2 {
				font-weight: 600;
				font-size: 28px;
				line-height: 52px;
				color: #565656;
				span {
					font-style: italic;
					color: #fe6e6b;
					font-weight: 400;
					font-size: 16px;
					line-height: 52px;
				}
			}
			p {
				font-weight: 400;
				font-size: 15px;
				line-height: 34px;
				color: rgb(86 86 86 / 54%);
			}
			.css-9mgopn-MuiDivider-root {
				border-bottom: 1px solid #fe6e6b59;
				padding-top: 20px;
				margin-bottom: 20px;
			}
			.serv_list {
				.rdo {
					display: flex;
					align-items: flex-start;
					h4 {
						font-weight: 500;
						font-size: 18px;
						line-height: 25px;
						color: #565656;
						opacity: 0.5;
					}
					span {
						padding: 0;
						margin-right: 4px;
						color: #fe6e6b;
					}
				}
				.chk_list {
					padding: 20px 30px;
					.lst {
						display: flex;
						align-items: center;
						.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
						.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
							color: #fe6e6b;
						}
						svg {
							path {
								fill: #fe6e6b;
							}
						}
					}
				}
			}
		}
		.bk-aptmnt {
			// background: rgb(92 95 103 / 10%);
			// opacity: 0.1;
			background: $white;
			border: 1px solid rgba($secondary, 0.3);
			border-radius: 10px;
			margin: 28px 0;
			h2 {
				font-weight: 700;
				font-size: 34px;
				line-height: 61px;
				color: #565656;
				padding: 20px 30px 12px;
			}
			.MuiDivider-root {
				border-bottom: 1px solid rgba(86, 86, 86, 0.1);
			}
		}
	}
}

.check_date {
	display: flex;
	justify-content: space-between;
	padding: 20px 30px;
	align-items: end;
	h6 {
		font-weight: 500;
		font-size: 18px;
		line-height: 37px;
		color: #565656;
	}
	input {
		background: #ffffff;
		border-radius: 10px;
		padding: 16px 10px;
		width: 150px;
		border: 1px solid rgba($secondary, 0.3);
	}
	.chk {
		div {
			display: flex;
			gap: 14px;
		}
		figure {
			width: 42px;
		}
		.btn2 {
			width: 300px;
		}
	}
	.avability {
		width: 40%;
		button {
			width: 100%;
		}
	}
}
.shipping {
	display: flex;
	justify-content: space-between;
	.btn {
		padding: 14px 40px;
	}
}
