@import '../../assets/theme/variable.scss';

.presalemain {
	.savingcoupn {
		background-color: $secondary;
		padding: 50px 0;
		.bnrdiv {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.sleft {
				width: 40%;
				h1 {
					font-size: 46px;
					line-height: 64px;
					padding-bottom: 20px;
					width: 80%;
					color: $white;
				}
				p {
					font-size: 15px;
					line-height: 27px;
					color: #9a0a07;
				}
			}
			.sryt {
				width: 50%;
				figure {
					width: 200px;
					height: 500px;
					margin: auto;
				}
			}
		}
	}
	.coupcard {
		background: white;
		border-radius: 20px;
		padding: 30px;
		border: 1px solid;
		margin: 20px 0;
		display: flex;
		justify-content: space-around;
		align-items: center;
		.cpnleft {
			width: 50%;
			.title-sec {
				display: flex;
				justify-content: space-between;
				align-items: center;
				// padding-bottom: 10px;
				h2 {
					font-size: 25px;
				}
				figure {
					width: 50px;
					height: 50px;
				}
			}
			h3 {
				font-size: 14px;
			}
			.rtng {
				display: flex;
				align-items: center;
				.rating {
					display: flex;
					align-items: center;
					background: #fec36b;
					padding: 2px 10px;
					border-radius: 5px;
					margin-right: 10px;
					figure {
						width: 15px;
						height: 18px;
						margin-right: 10px;
					}
					h6 {
						font-size: 11px;
						color: $white;
					}
				}
				p {
					font-size: 13px;
					color: $text;
				}
			}
			p {
				font-size: 13px;
				padding: 10px 0;
			}
		}
		.cpnRight {
			width: 50%;
		}
	}
}
