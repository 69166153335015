@import '../../assets/theme/variable.scss';

.pRegister {
	display: flex;
	justify-content: space-between;
	padding-top: 90px;
	// background: radial-gradient(
	// 	309.84% 79.39% at 69.31% 47.44%,
	// 	rgba(251, 103, 103, 0) 0%,
	// 	#fb6767 100%
	// );
	.prleft {
		width: 50%;
		figure {
			width: 100%;
			height: 400px;
			@media (max-width: 1020px) {
				width: 80%;
				height: 400px;
			}
		}
	}
	.prRight {
		width: 50%;
		figure {
			width: 100%;
			height: 400px;
			@media (max-width: 1020px) {
				width: 80%;
				height: 400px;
				margin: 0 0 0 auto;
			}
		}
	}
}
.registForm {
	position: relative;
	// top: -175px;
	top: 0;
	padding-bottom: 20px;
	.regimain {
		display: flex;
		justify-content: space-between;
		.rFleft {
			width: 70%;
			background: #ffffff;
			box-shadow: 0px 70px 120px 5px rgba(0, 0, 0, 0.07);
			border-radius: 15px 0px 0px 15px;
			padding: 30px;
			h3 {
				font-size: 26px;
				text-align: center;
				color: $text;
			}
			p {
				font-size: 14px;
				text-align: center;
				padding: 10px 0;
				color: $text;
			}
			.personal,
			.bussiness,
			.confirmpasswrd {
				border-bottom: 1px solid rgba($secondary, 0.2);
				// padding-top: 20px;
				padding-bottom: 10px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.partner-reg-fld {
					width: 49%;
					padding-top: 10px;
					& > div {
						background: white;
						border: 1px solid rgba($secondary, 0.3);
						input {
							padding: 11.5px 14px !important;
						}
					}
				}
				fieldset {
					display: none;
				}
				.selectr {
					width: 49%;

					.partner-reg-fld-rg-selll {
						width: 100%;
						padding-top: 10px;
						& > div {
							background: white;
							border: 1px solid rgba($secondary, 0.3);
							padding: 11.5px 14px !important;
						}
					}
				}
			}
			.register {
				padding: 40px 0;
				display: flex;
				align-items: center;
				.partner-reg-fld {
					width: 20%;
					padding-top: 10px;
					& > div {
						background: white;
						border: 1px solid rgba($secondary, 0.3);
						input {
							padding: 11.5px 14px !important;
						}
					}
				}
				fieldset {
					display: none;
				}
				.btndiv {
					margin-left: auto;
				}
			}
		}
		.rFright {
			width: 30%;
			background: radial-gradient(
				83.54% 50% at 50% 50%,
				rgba(92, 95, 103, 0) 0%,
				rgba(92, 95, 103, 0.697917) 42.71%,
				#5c5f67 100%
			);
			border-radius: 0px 15px 15px 0px;
			display: flex;
		}
	}
}
.rg-sel > div {
	color: rgba($text, 0.5);
}
