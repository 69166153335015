@import './assets/theme/variable.scss';
.container {
	margin: 0 auto;
	width: 90%;
	@media (max-width: 1200px) {
		width: 95%;
	}
}
.container2 {
	width: 80%;
	margin: 0 auto;
}

li {
	list-style: none;
}
ul {
	padding-left: 0;
}
* {
	padding: 0;
	margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
figure,
ul {
	margin: 0;
}
img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
body {
	overflow-x: hidden;
	// overflow-y: scroll !important;
	font-family: 'Poppins', sans-serif;
	// background: #f7f7f7;
	background: white;
	// padding-right: 3px !important;
	margin: 0;
	padding: 0;
}
// .pt {
// 	padding-top: 100px;
// }
button.btn {
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	font-size: 14px;
	font-weight: 500;
	padding: 14px 20px;
	width: max-content;
	border-radius: 30px;
	border: 1px solid $secondary;
	background: $secondary;
	color: $white;
	cursor: pointer;
	@media (max-width: 1300px) {
		font-size: 14px;
		font-weight: 500;
		padding: 14px 22px;
	}
	@media (max-width: 1200px) {
		font-size: 12px;
		font-weight: 500;
		padding: 12px 20px;
	}
}
.btn:hover {
	background: rgba($secondary, 0.8);
	border: 1px solid rgba($secondary, 0.8);
}

.btn2 {
	border: 1px solid $secondary;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
	background: transparent;
	color: $secondary;
	font-size: 14px;
	font-weight: 500;
	padding: 14px 20px;
	width: max-content;
	border-radius: 30px;
	cursor: pointer;
	@media (max-width: 1300px) {
		font-size: 14px;
		padding: 14px 22px;
		font-weight: 500;
	}
	@media (max-width: 1200px) {
		font-size: 12px;
		padding: 12px 20px;
	}

	&:hover {
		background: $secondary;
		color: white;
	}
}

.undrln_hdng::after {
	content: '';
	position: absolute;
	background: url('../public/static/images/underlined_hdng.svg');
	height: 14px;
	width: 400px;
	background-repeat: no-repeat;
	bottom: 0;
	left: 10%;
	background-size: contain;
}
.signUp {
	width: 60%;
	margin: 0 auto;
	h3 {
		font-size: 24px;
		text-align: center;
		color: #343a40;
	}
}

.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
	background: none !important;
	border: none !important;
}
.css-2y464i-MuiInputBase-root-MuiFilledInput-root:before {
	border-bottom: none !important;
}

/* width */
::-webkit-scrollbar {
	width: 3px;
}
::-webkit-scrollbar-track {
	background: $primary;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $secondary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $secondary;
}

.pmmain .prmain .pfdivmain .professiondiv:nth-child(2) {
	background: $secondary;
	position: relative;
	bottom: 16px;
}

.pmmain .prmain .pfdivmain .professiondiv:nth-child(2) .pdtop p {
	color: white;
}
.pmmain .prmain .pfdivmain .professiondiv:nth-child(2) .lang p {
	color: white;
}

.pmmain .prmain .pfdivmain .professiondiv:nth-child(2) .tools ul li {
	color: white;
}

.prdct-fltr {
	background: #ffffff;
	border: 1px solid rgba(86, 86, 86, 0.1019607843);
	border-radius: 5px;
	margin-bottom: 15px;
}
.ctry-mn {
	padding: 10px 20px;
	.ctry {
		font-size: 14px;
		color: #565656cc;
		margin-bottom: 5px;
	}
}
.radio {
	display: flex;
	align-items: center;
	padding: 6px 0;

	label > span {
		color: $secondary !important;
		padding: 0 0 0 10px;
	}

	p {
		padding: 0 5px;
		font-size: 14px;
		color: rgba(86, 86, 86, 0.8);
	}
}
.chk_bx {
	display: flex;
	align-items: center;
	padding: 6px 0;

	span {
		color: $secondary !important;
		padding: 0px;
	}
	p {
		padding: 0 0 0 10px;
		font-size: 14px;
		color: rgba(86, 86, 86, 0.8);
	}
}

.stardiv {
	display: flex;
	h5 {
		padding: 0 5px;
		font-size: 14px;
		color: rgba(86, 86, 86, 0.8);

		font-weight: 400;
	}
}
.MuiAutocomplete-listbox li {
	color: rgba($text, 0.5);
}
.MuiAutocomplete-listbox li:hover {
	background-color: rgba($secondary, 0.5) !important;
}

.prdcts-lst .mn-prdcts-lst .prdct-sectop {
	.lst-lft {
		@media (max-width: 1920px) {
			max-height: 1220px;
		}
		@media (max-width: 1500px) {
			max-height: 1040px;
		}
		@media (max-width: 1200px) {
			width: 30%;
		}
	}
}
.prdct-dtl {
	@media (max-width: 1300px) {
		padding-top: 80px;
		padding-bottom: 60px;
	}

	.productdmain {
		.pdleft {
			@media (max-width: 1200px) {
				width: 47%;
			}

			.views {
				@media (max-width: 1300px) {
					padding-bottom: 10px;
					padding-top: 10px;
					.vleft {
						@media (max-width: 1200px) {
							width: 40%;
						}
					}
					.vright {
						@media (max-width: 1200px) {
							width: 56%;
						}
					}
				}
			}
			.content {
				p {
					@media (max-width: 1300px) {
						font-size: 13px;
					}
					@media (max-width: 1200px) {
						font-size: 12px;
					}
				}
				.dots_para {
					li {
						@media (max-width: 1300px) {
							margin-left: 10px;
						}
					}
				}
			}
		}
		.pdright {
			.buttn {
				.btn {
					@media (max-width: 1200px) {
						width: 37%;
					}
				}
			}
		}
	}
}

.MuiAutocomplete-endAdornment {
	display: flex !important;
	position: inherit !important;
}

.paddig {
	padding-top: 40px !important;
}
.magin {
	margin-top: 10px;
}
.MuiDivider-fullWidth {
	border-color: #5a5f68 !important;
	opacity: 0.1 !important;
}

.quantiooooo {
	background: #fff4f4 !important;
	width: 118px;
	color: #565656 !important;
}
.quantiooooo div {
	padding: 10px 30px;
}
.quantiooooo fieldset {
	display: none;
}

li:nth-child(2) .dashline {
	background: $white !important;
}

.menu_iitem div {
	border-radius: 10px !important;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}
.menu_iitem div ul li {
	border-bottom: 1px solid #e4e4e4;
	font-size: 14px !important;
	color: #858c8e !important;
}
.menu_iitem div ul li:last-child {
	border-bottom: 1px solid white;

	color: $secondary !important;
}
.account_item div {
	box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 12px !important;
	border-radius: 10px !important;
}
.account_item div ul li {
	border-bottom: 1px solid #e4e4e4;
	font-size: 14px !important;
	color: #858c8e !important;
}
.account_item div ul li:last-child {
	border-bottom: 1px solid white;
}
.accnt
	.chatmain
	.chatcontainer
	.chatleft
	.users
	ul
	li:nth-child(1)
	.dbleft
	.profile
	h5 {
	color: $secondary !important;
}
.accnt
	.chatmain
	.chatcontainer
	.chatleft
	.users
	ul
	li:nth-child(4)
	.dbleft
	.profile
	h5 {
	color: $secondary !important;
}
.accnt
	.chatmain
	.chatcontainer
	.chatleft
	.users
	ul
	li:nth-child(2)
	.dbleft
	.ppic
	h1 {
	background: #ffe144;
}
.accnt
	.chatmain
	.chatcontainer
	.chatleft
	.users
	ul
	li:nth-child(3)
	.dbleft
	.ppic
	h1 {
	background: #f74b81;
}
.accnt
	.chatmain
	.chatcontainer
	.chatleft
	.users
	ul
	li:nth-child(4)
	.dbleft
	.ppic
	h1 {
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 0%,
		#dbdbdb 71.54%
	);
}

.sharemodal {
	width: 100%;
	margin: auto;
	.crossbar {
		background: #565656;
		opacity: 0.5;
		border-radius: 15px 15px 0px 0px;
		padding: 10px;
		figure {
			width: 35px;
			height: 35px;
			margin-left: auto;
			cursor: pointer;
		}
	}
	.sharedata {
		padding: 20px 0;
		.Gmail {
			width: 30%;
			margin: auto;
			background: #ffffff;
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
			border-radius: 500px;
			padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			figure {
				width: 20px;
				height: 20px;
				margin-right: 10px;
			}
		}
		.sharediv {
			width: 70%;
			margin: auto;
			padding-top: 20px;
			h3 {
				font-size: 16px;
				color: $text;
			}
			ul {
				display: flex;
				width: 100%;
				justify-content: space-between;
				padding: 14px 0;
				li {
					padding: 6px 15px;
					border: 1px solid rgba(86, 86, 86, 0.2);
					border-radius: 10px;
					&:hover {
						background: rgba(254, 110, 107, 0.1);
						border: 1px solid rgba(254, 110, 107, 0.3);
						img {
							filter: none !important;
						}
					}
					figure {
						width: 20px;
						height: 20px;
					}
					img {
						filter: brightness(0.2);
					}
				}
			}
			.copycode {
				display: flex;
				align-items: center;
				padding-top: 10px;
				fieldset {
					display: none !important;
				}
				.MuiFormControl-root {
					width: 90%;
					margin-right: 14px;
					background: rgba(86, 86, 86, 0.02) !important;
					border-bottom: 1px solid rgba(86, 86, 86, 0.2) !important;
					border-radius: 5px !important;
					// margin-bottom: 20px !important;
				}
				.w_100 {
					width: 100% !important;
				}
				.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
					background: none !important;
					border: none !important;
				}
				.css-2y464i-MuiInputBase-root-MuiFilledInput-root:before {
					border-bottom: none !important;
				}
				h3 {
					font-size: 14px;
					color: $secondary;
					font-weight: 600;
					cursor: pointer;
				}
			}
		}
	}
}

.validation_msg {
	color: $red !important;
	opacity: 1 !important;
	margin: 0 !important;
	font-size: 13px !important;
	line-height: 18px !important;
	margin-top: 10px !important;
	text-align: left !important;
}
.MuiFormHelperText-root {
	color: $red !important;
	opacity: 1 !important;
	margin: 0 !important;
	font-size: 13px !important;
	line-height: 18px !important;
	margin-top: 7px !important;
	text-align: left !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline.css-85zwa9-MuiInputBase-root-MuiFilledInput-root {
	border-radius: 10px !important;
	&::after {
		display: none !important;
	}
	&::before {
		display: none !important;
	}
}

.partner-reg-fld {
	& > div {
		background: rgba(217, 217, 217, 0.2);
	}
}

//telephone style

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
	border-color: #f7f4f4 !important;
	box-shadow: none !important;
}

.react-tel-input .country-list {
	outline: none;
	z-index: 1;
	list-style: none;
	position: absolute;
	padding: 0px 20px 20px 20px !important;
	margin: 10px 0 10px -1px;
	box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
	background-color: white;
	width: 400px !important;
	max-height: 220px !important;
	overflow-y: scroll;
	border-radius: 0px !important;
	height: 600px !important;
	@media (max-width: 1280px) {
		width: 300px !important;
	}
	@media (max-width: 992px) {
		width: 230px !important;
	}
}
.react-tel-input .form-control {
	font-size: 16px;
	background: white;
	border: 1px solid rgba($secondary, 0.3) !important;
	width: 100% !important;
	outline: none;
	padding: 18.5px 14px 18.5px 60px;
	transition: box-shadow ease 0.25s, border-color ease 0.25s;
	color: #495057;
	border-radius: 4px !important;
	margin-bottom: 10px !important;
	display: flex !important;
	&:focus {
		border-color: 'red' !important;
		box-shadow: none !important;
	}
}
.react-tel-input .flag-dropdown {
	border: none !important;
	background: none !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
	background: none !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
	background: none !important;
}

.react-tel-input .country-list .search {
	z-index: 2;
	position: sticky;
	top: 0;
	background-color: red;
	padding: 10px 0 6px 10px;
	width: 100%;
}
.react-tel-input .country-list .search-box {
	border: 1px solid $secondary !important;
	border-radius: 3px;
	font-size: 15px;
	line-height: 15px;
	margin-left: 6px;
	padding: 7px 11px 9px !important;
	outline: none;
	width: 94%;
	box-sizing: border-box;
	&::placeholder {
		text-transform: capitalize;
	}
}

.book-apntmnt .book-appont-sec {
	.check-avlbl {
		h2 {
			@media (max-width: 1440px) {
				font-size: 26px;
				line-height: 40px;
			}
			@media (max-width: 1300px) {
				font-size: 24px;
			}
			@media (max-width: 1200px) {
				font-size: 22px;
			}
		}
		p {
			@media (max-width: 1200px) {
				font-size: 14px;
				line-height: 26px;
			}
		}
		.css-9mgopn-MuiDivider-root {
			@media (max-width: 1440px) {
				padding-top: 14px;
			}
		}

		.serv_list {
			.rdo {
				h4 {
					@media (max-width: 1440px) {
						font-size: 16px;
					}
					@media (max-width: 1300px) {
						font-size: 15px;
					}
					@media (max-width: 1200px) {
						font-size: 13px;
					}
				}
			}
			.chk_list {
				@media (max-width: 1300px) {
					padding: 10px 30px;
				}
				.lst {
					.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
					.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
						color: #fe6e6b;
					}
					svg {
						path {
							fill: #fe6e6b;
						}
					}
					p {
						@media (max-width: 1300px) {
							font-size: 13px;
						}
						@media (max-width: 1200px) {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
	.bk-aptmnt {
		// background: rgb(92 95 103 / 10%);
		// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

		border: 1px solid rgba($secondary, 0.3);
		border-radius: 10px;
		margin: 28px 0;
		h2 {
			@media (max-width: 1440px) {
				font-size: 32px;
				line-height: 58px;
			}
			@media (max-width: 1300px) {
				font-size: 28px;
				line-height: 50px;
			}
			@media (max-width: 1200px) {
				font-size: 24px;
				line-height: 42px;
			}
		}
	}
}
