@import '../../assets/theme/variable.scss';
.presale-pg {
	.pre-bg {
		background: url('../../../public/static/images/hero-bg.svg');
		height: 600px;
		width: 100%;
		background-size: 100%;
		background-repeat: no-repeat;
		@media (max-width: 1300px) {
			height: 560px;
		}
		@media (max-width: 1200px) {
			height: 470px;
		}

		.mn-pre {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 70px;
			.pre-lft {
				width: 45%;
				padding-left: 40px;
				h1 {
					font-size: 44px;
					color: $text;
					width: 95%;
					@media (max-width: 1300px) {
						font-size: 40px;
					}
					@media (max-width: 1200px) {
						font-size: 38px;
					}
				}
				p {
					color: rgba(154, 10, 7, 0.5);
					font-size: 14px;
					padding-top: 20px;
					line-height: 26px;
					@media (max-width: 1300px) {
						font-size: 12px;
					}
				}
			}
			.pre-ryt {
				width: 45%;
				.pre_lottie {
					width: 100%;
					height: 400px;
					@media (max-width: 1200px) {
						height: 380px;
					}
				}
			}
		}
	}

	.presale_crds_sec {
		padding: 40px 0;
		@media (max-width: 1300px) {
			padding: 30px 0;
		}

		.pre-crd {
			display: flex;
			justify-content: space-between;
			width: 100%;
			align-items: stretch;
			margin: 20px 0;
			border-radius: 16px;
			box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
			padding: 20px;
			box-sizing: border-box;
			cursor: pointer;
			@media (max-width: 1300px) {
				padding: 16px;
			}

			.pre-crd-lft {
				figure {
					height: 30px;
					width: 30px;
					margin-bottom: 8px;
					@media (max-width: 1300px) {
						height: 27px;
						width: 27px;
						margin-bottom: 7px;
					}
					@media (max-width: 1200px) {
						height: 26px;
						width: 26px;
						margin-bottom: 5px;
					}
					img {
						border-radius: 0;
					}
				}
			}
			.pre-crd-mdl {
				padding-left: 20px;
				figure {
					width: 220px;
					height: 220px;
					@media (max-width: 1300px) {
						width: 200px;
						height: 200px;
					}
					@media (max-width: 1200px) {
						width: 180px;
						height: 180px;
					}

					img {
						border-radius: 10px;
					}
				}
				.tym-lft {
					display: flex;
					justify-content: center;
					align-items: center;
					padding-top: 20px;
					width: 90%;
					margin: 0 auto;
					div {
						background: #5c5f67;
						color: $white;

						font-size: 24px;
						font-weight: 600;
						width: 18%;
						border-radius: 10px;
						height: 56px;
						width: 56px;
						margin: auto;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						@media (max-width: 1300px) {
							font-size: 20px;
							height: 50px;
							width: 55px;
						}
						@media (max-width: 1200px) {
							font-size: 18px;
							height: 48px;
							width: 50px;
						}
						h6 {
							color: rgba($white, 0.3);
							font-size: 12px;
							font-weight: 400;
							line-height: 5px;
						}
					}
				}
			}
			.pre-crd-ryt {
				padding-left: 40px;
				.title-sec {
					display: flex;
					justify-content: space-between;
					align-items: stretch;
					h2 {
						font-size: 24px;
						color: $headings;
						@media (max-width: 1300px) {
							font-size: 22px;
						}
						@media (max-width: 1200px) {
							font-size: 21px;
						}
					}
					figure {
						height: 17px;
						width: 17px;
						background: $secondary;
						padding: 6px;
						border-radius: 50%;
						// @media (max-width: 1200px) {
						// 	height: 34px;
						// 	width: 33px;
						// }
					}
				}
				h4 {
					font-size: 12px;
					font-weight: 500;
					color: $headings;
					margin: 10px 0;
					@media (max-width: 1200px) {
						margin: 8px 0;
					}
				}
				.rtng {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-bottom: 10px;

					div {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						background: #fec36b;
						color: $white;
						padding: 4px 6px;
						border-radius: 6px;
						figure {
							width: 11px;
							height: 20px;
						}
						h6 {
							font-size: 14px;
							margin-left: 6px;
						}
					}
					p {
						font-size: 12px;
						color: $text;
						margin-left: 6px;
					}
				}

				p {
					font-size: 14px;
					line-height: 28px;
					width: 90%;
					color: $text;
					@media (max-width: 1300px) {
						font-size: 13px;
						line-height: 26px;
					}
					@media (max-width: 1200px) {
						font-size: 12px;
						line-height: 24px;
					}
				}
				.adrss-dtls {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin: 10px 0;
					@media (max-width: 1300px) {
						margin: 5px 0;
					}
					@media (max-width: 1200px) {
						margin: 7px 0;
					}
					figure {
						height: 18px;
						width: 15px;
					}
					p {
						font-size: 14px;
						font-weight: 500;
						margin-left: 6px;
						span {
							font-size: 13px;
							color: rgba($text, 0.5);
						}
					}
				}
				.prc-sec {
					display: flex;
					justify-content: space-between;
					align-items: center;
					// margin-bottom: 10px;
					h4 {
						font-size: 26px;
						font-weight: 700;
						color: $secondary;
						@media (max-width: 1300px) {
							font-size: 22px;
						}
						@media (max-width: 1200px) {
							font-size: 20px;
						}
						span {
							font-size: 18px;
							font-weight: 400;
							color: rgba($text, 0.5);
						}
					}
					.btn2 {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 14px 30px;
						@media (max-width: 1200px) {
							padding: 12px 20px;
						}
						svg {
							margin-left: 10px;

							font-size: 16px;
						}
					}
				}
			}
		}
	}
}
