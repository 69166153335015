@import '../../assets/theme/variable.scss';

.partnermain {
	padding: 80px 0 80px;

	position: relative;
	&::after {
		content: '';
		position: absolute;
		background: url(../../../public/static/images/partbg1.svg);
		background-repeat: no-repeat;
		background-size: 100%;
		width: 700px;
		height: 100%;
		top: 0;
		right: 0;
		z-index: 0;
		@media (max-width: 1200px) {
			width: 570px;
		}
	}
	&::before {
		content: '';
		position: absolute;
		background: url(../../../public/static/images/part2.svg);
		background-repeat: no-repeat;
		background-size: 100%;
		width: 700px;
		height: 100%;
		top: 0;
		right: 0;
		z-index: 0;
		@media (max-width: 1200px) {
			width: 570px;
		}
	}
	.partner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media (max-width: 1200px) {
			align-items: flex-start;
		}
		.pleft {
			width: 50%;
			@media (max-width: 1200px) {
				width: 50%;
			}
			h1 {
				font-size: 42px;
				line-height: 60px;
				color: #3c3c3c;
				@media (max-width: 1920px) {
					font-size: 48px;
				}
				@media (max-width: 1500px) {
					font-size: 42px;
				}
				span {
					color: $secondary;
				}
			}
			p {
				font-size: 16px;
				line-height: 32px;
				padding: 10px 0;
				color: $text;
				@media (max-width: 1100px) {
					padding: 20px 0;
				}
			}
			.btnDiv {
				width: 30%;
				padding-top: 6px;
				button {
					width: 100%;
				}
			}
		}
		.pright {
			width: 50%;
			z-index: 1;
			.prtnr-bnr-lottie {
				width: 550px;
				height: 400px;
				margin: auto;
				@media (max-width: 1920px) {
					width: 600px;
				}
				@media (max-width: 1500px) {
					width: 550px;
				}
				@media (max-width: 1200px) {
					width: 450px;
					height: 310px;
				}
			}
		}
	}
}
.countmain {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 70%;
	margin: auto;

	@media (max-width: 1200px) {
		width: 80%;
	}
	@media (max-width: 1100px) {
		width: 90%;
	}
	.count {
		h2 {
			font-size: 50px;
			color: $secondary;
			span {
				font-size: 26px;
				color: $headings;
			}
		}
	}
}
.partnercard {
	background-image: url('../../../public/static/images/shade.png');
	background-position: right;
	background-size: contain;
	background-repeat: no-repeat;
	padding: 60px 0;
	.ptnrcard {
		display: flex;
		align-items: center;
		justify-content: space-between;

		li {
			width: 33%;
			text-align: center;
		}
	}
}

.profitable {
	padding: 70px 0;
	position: relative;

	&::before {
		content: '';
		background-image: url('../../../public/static/images/profitable_bg.png');
		position: absolute;
		top: 50%;
		background-repeat: no-repeat;
		background-size: contain;
		left: 0;
		height: 350px;
		width: 350px;
		@media (max-width: 1280px) {
			height: 300px;
			width: 250px;
		}
	}
	.vec_tor {
		position: absolute;
		right: 0;
		top: 0;
	}

	.triangle {
		position: absolute;
		right: 10%;
		z-index: -1;
		top: 20px;
		max-width: 610px;
	}
	.profit {
		position: relative;
		min-height: 1000px;
		.pleft {
			max-width: 430px;
			position: absolute;
			top: 20%;
			left: 10%;
			@media (max-width: 1920px) {
				left: 16%;
			}
			@media (max-width: 1366px) {
				left: 10%;
			}
			@media (max-width: 1200px) {
				// left: 0%;
			}

			h3 {
				font-size: 32px;
				color: #565656;
				font-weight: 600;
				@media (max-width: 1920px) {
					font-size: 38px;
				}
				@media (max-width: 1500px) {
					font-size: 32px;
				}
				@media (max-width: 1100px) {
					font-size: 28px;
				}
			}
			p {
				font-size: 14px;
				padding-top: 10px;
				color: $text;
				@media (max-width: 1920px) {
					font-size: 18px;
				}
				@media (max-width: 1500px) {
					font-size: 14px;
				}
			}
		}
		.pright {
			li {
				border-radius: 50%;
				background: #ffffff;
				border: 5px solid rgba(254, 110, 107, 0.4);
				box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.1);
				text-align: center;
				cursor: pointer;

				&:hover {
					h2 {
						color: #fe6e6b;
						opacity: 1;
					}
				}

				&:nth-child(1) {
					// width: 500px;
					// height: 500px;
					width: 460px;
					height: 460px;
					position: absolute;
					right: 12%;
					top: 40px;
					@media (max-width: 1366px) {
						width: 400px;
						height: 400px;
					}
					@media (max-width: 1200px) {
						width: 380px;
						height: 380px;
					}

					h2 {
						height: 180px;
						@media (max-width: 1366px) {
							height: 170px;
						}
						@media (max-width: 1200px) {
							height: 190px;
						}
					}
				}
				&:nth-child(2) {
					width: 370px;
					height: 370px;
					position: absolute;
					right: 40%;
					bottom: 0;
					@media (max-width: 1500px) {
						width: 370px;
						height: 370px;
					}
					@media (max-width: 1200px) {
						width: 351px;
						height: 351px;
					}
					@media (max-width: 992px) {
						width: 371px;
						height: 371px;
					}
					p {
						@media (max-width: 1200px) {
							padding: 50px 50px;
						}
					}
				}
				&:nth-child(3) {
					width: 356px;
					height: 356px;
					position: absolute;
					right: 0;
					bottom: 0;
					@media (max-width: 1366px) {
						width: 336px;
						height: 336px;
					}
					@media (max-width: 1200px) {
						width: 331px;
						height: 331px;
					}
					@media (max-width: 992px) {
						width: 331px;
						height: 351px;
					}
					p {
						@media (max-width: 1200px) {
							padding: 20px 56px;
						}
					}
				}
				h2 {
					// font-size: 200px;
					font-size: 170px;
					color: #565656;
					opacity: 0.2;
					height: 150px;
					@media (max-width: 1366px) {
						height: 170px;
					}
					@media (max-width: 1200px) {
						font-size: 170px;
					}
				}
				p {
					padding: 60px;
					color: #565656;
					font-size: 16px;
					@media (max-width: 1366px) {
						padding: 50px 60px;
						font-size: 14px;
					}
					@media (max-width: 1200px) {
						padding: 20px 50px;
					}
				}
			}
		}
	}
}
.howitworks {
	padding: 150px 0;
	position: relative;
	@media (max-width: 1200px) {
		padding: 80px 0 150px;
	}

	&::before {
		content: '';
		background-image: url('../../../public/static/images/tp_shade.png');
		background-repeat: no-repeat;
		width: 100%;
		position: absolute;
		top: -70px;
		height: 100%;
		background-size: contain;
	}
	&::after {
		content: '';
		background-image: url('../../../public/static/images/btm_shade.png');
		background-repeat: no-repeat;
		width: 100%;
		position: absolute;
		bottom: -20px;
		height: 25%;
		background-size: contain;
	}

	.howitworks_main {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.howitwork_lhs {
			width: 30%;
			figure {
				height: 740px;
				width: 370px;
				@media (max-width: 1920px) {
					height: 970px;
					width: 480px;
				}
				@media (max-width: 1500px) {
					height: 740px;
					width: 370px;
				}
				@media (max-width: 1200px) {
					height: 660px;
					width: 320px;
				}
			}
		}
		.howitwrk {
			width: 60%;
			padding: 0 30px;

			h3 {
				font-size: 38px;
				color: #565656;
				font-weight: 500;
			}
			p {
				color: $text;
				font-weight: 500;
			}
		}
		.worksoption {
			margin: 30px 0;
		}

		.womain {
			display: flex;
			align-items: center;
			margin-bottom: 30px;

			figure {
				margin-right: 20px;
				background: #fe6e6b14;
				border-radius: 16px;
				width: 80px;
				height: 80px;

				img {
					object-fit: contain;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 50px;
					margin: 0 auto;
				}
			}
			p {
				color: $text;
				font-weight: 500;
			}
		}
	}
}
//packages
.packagemain {
	background: url(../../../public/static/images/packbg.png);
	background-repeat: no-repeat;
	padding: 60px 0 40px;
	ul {
		display: flex;
		justify-content: space-between;
		// align-items: center;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: center;
		.choose {
			border: none;
			background-color: transparent;
			padding: 0;
			h3 {
				font-size: 32px;
				color: $text;
				padding-bottom: 4px;
				@media (max-width: 1440px) {
					font-size: 22px;
				}

				@media (max-width: 1200px) {
					font-size: 20px;
				}
			}
			p {
				font-size: 14px;
				line-height: 26px;
				// padding: 20px 0;
				color: $text;
				@media (max-width: 1200px) {
					font-size: 14px;
				}
			}
			.choosearrow {
				display: flex;
				padding: 4px 0;
				h6 {
					font-size: 18px;
					color: $secondary;
					margin-right: 10px;
				}
				h6 {
					font-size: 18px;
					color: $secondary;
				}
			}
		}
		li {
			background: $white;
			// padding: 20px 0;
			width: 30%;
			margin-bottom: 30px;
			margin-right: 30px;
			border: 1px solid rgba($text, 0.2);
			border-radius: 20px;
			@media (max-width: 1100px) {
				padding: 10px 0;
			}
			.packagecard {
				cursor: pointer;
				h3 {
					text-align: center;
					font-size: 28px;
					padding: 20px 0;
					color: #3c3c3c;
					border-bottom: 1px solid rgba($secondary, 0.2);
					@media (max-width: 1440px) {
						font-size: 22px;
					}

					@media (max-width: 1200px) {
						font-size: 20px;
					}
					// @media (max-width: 1100px) {
					// 	font-size: 22px;
					// }
				}
				figure {
					width: 150px;
					height: 150px;
					margin: auto;
					padding: 10px 0;
					position: relative;
					z-index: 1;
					@media (max-width: 1200px) {
						width: 130px;
						height: 130px;
					}
					&::after {
						content: '';
						position: absolute;
						background: url(../../../public/static/images/d1.svg);
						background-repeat: no-repeat;
						background-size: 100%;
						width: 160px;
						height: 160px;
						z-index: -1;
						top: 20px;
						left: 0;
						@media (max-width: 1200px) {
							width: 150px;
							height: 150px;
						}
					}
					&::before {
						content: '';
						position: absolute;
						background: url(../../../public/static/images/d2.svg);
						background-repeat: no-repeat;
						background-size: 100%;
						width: 150px;
						height: 160px;
						z-index: -1;
						top: 10px;
						left: 0;
						@media (max-width: 1200px) {
							width: 130px;
							height: 140px;
						}
					}
				}
			}
		}
	}
}

//how does it cost
.costmain {
	padding: 30px 0;
	margin-bottom: 70px;
	.costm {
		display: flex;
		justify-content: center;
		align-items: center;
		.cleft {
			width: 40%;
			.heading {
				h3 {
					font-size: 30px;
					color: $text;
				}
				h2 {
					font-size: 150px;
					line-height: 142px;
					font-weight: 500;
					color: $secondary;
					position: relative;
				}
			}
			p {
				font-size: 14px;
				// width: 270px;
				line-height: 19px;
				margin-left: auto;
				position: relative;
				// left: 70px;
				padding-top: 10px;
				color: $text;
			}
		}
		.cright {
			width: 60%;
			.hw-cst-lottie {
				width: 100%;
				height: 100%;
			}
		}
	}
}
