@import '../../assets/theme/variable.scss';
.pmmain {
	position: relative;
	cursor: pointer;

	.pmbnr {
		background: #fb6767;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 150px 0;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			background: url(../../../public/static/images/pricingmodel1.svg);
			width: 250px;
			height: 300px;
			background-repeat: no-repeat;
			background-size: 100%;
			left: 0;
			top: 0;
		}
		&::after {
			content: '';
			position: absolute;
			background: url(../../../public/static/images/pricingmodel2.svg);
			width: 250px;
			height: 300px;
			background-repeat: no-repeat;
			background-size: 100%;
			right: 0;
			bottom: -70px;
		}
		.ppbanner {
			width: 60%;
			text-align: center;
			h2 {
				font-size: 25px;
				@media (max-width: 1100px) {
					font-size: 23px;
				}
			}
			h1 {
				font-size: 35px;
				@media (max-width: 1100px) {
					font-size: 31px;
				}
			}
			p {
				font-size: 17px;
				padding: 15px 0;
				@media (max-width: 1100px) {
					font-size: 15px;
					padding: 11px 0;
				}
			}
			.tab {
				display: flex;
				width: 40%;
				margin: auto;
				background: #f95353;
				border-radius: 50px;
				padding: 5px;
				justify-content: space-between;
				.active {
					background: white;
					color: #fb6767;
					font-size: 13px;
					padding: 10px;
					border-radius: 50px;
					width: 50%;
					cursor: pointer;
					@media (max-width: 1100px) {
						font-size: 11px;
					}
				}
				.inactive {
					color: white;
					background: #f95353;
					font-size: 13px;
					padding: 10px;
					border-radius: 50px;
					width: 50%;
					cursor: pointer;
					@media (max-width: 1100px) {
						font-size: 11px;
					}
				}
			}
		}
	}
	.prmain {
		.pfdivmain {
			display: flex;
			justify-content: center;
			padding-top: 50px;
			.professiondiv {
				background: #ffffff;
				border-radius: 10px;
				padding: 20px;
				width: 25%;
				margin-right: 20px;
				.pdtop {
					padding-bottom: 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					h3 {
						font-size: 15px;
						color: $text;
					}
					p {
						font-size: 13px;
						color: $secondary;
					}
				}
				p {
					font-size: 13px;
					color: $text;
				}
				.lang {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 20px 0;
					h3 {
						font-size: 15px;
						color: $text;
					}
					p {
						font-size: 13px;
						color: $secondary;
					}
				}
				.tools {
					h2 {
						text-align: center;
						font-size: 16px;
						background: #fff0f0;
						padding: 5px 0;
						font-weight: 600;
						color: $text;
					}
					ul {
						padding-top: 20px;
						padding-left: 30px;
						li {
							font-size: 13px;
							list-style: disc;
							color: $secondary;
							padding-bottom: 15px;
						}
					}
				}
			}
		}
		.faqdiv {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 60px 0;
			.faqleft {
				width: 50%;
				figure {
					width: 400px;
					height: 400px;
					margin: auto;
				}
			}
			.faqright {
				width: 50%;

				h2 {
					font-size: 24px;
					padding-bottom: 20px;
				}
				.faqsdiv {
					.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.accd.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
						background: #ecf6fd !important;
						margin-bottom: 10px !important;
					}
					.accd {
						box-shadow: none !important;
						margin-bottom: 10px !important;
						background: #ecf6fd !important;
						border-radius: 50px !important;
					}
					.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
						position: absolute;
						left: 0;
						top: -1px;
						right: 0;
						height: 1px;
						content: '';
						opacity: 1;
						background-color: transparent !important;
						-webkit-transition: opacity 150ms
								cubic-bezier(0.4, 0, 0.2, 1) 0ms,
							background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)
								0ms;
						transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1)
								0ms,
							background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)
								0ms;
					}
				}
			}
		}
	}
}
