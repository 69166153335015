@import '../../assets/theme/variable.scss';
.prdcts-lst {
	// position: relative;
	overflow: hidden;
	.mn-prdcts-lst {
		padding-top: 80px;
		.dscount_srvc {
			background: transparent;
			margin: 0;
			.cpn_crd {
				margin-right: 18px;
			}
		}
		.prdct-sectop {
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			gap: 22px;
			.lst-lft {
				width: 25%;
				max-height: 1160px;
				overflow-y: scroll;
				margin-top: 90px;
				min-width: 250px;
				.mn-cpns-lst {
					// background: #ffffff;
					// border: 1px solid #5656561a;
					// border-radius: 5px;
					// margin-bottom: 15px;
					.fltr-hdng {
						background: #fe6e6b;
						border: 1px solid rgba(86, 86, 86, 0.1);
						border-radius: 5px;
						display: flex;
						justify-content: space-between;
						padding: 4px 11px;
						color: #fff;
						h2 {
							font-weight: 500;
							font-size: 16px;
							line-height: 34px;
						}

						.ctry {
							// padding: 0 30px;
							font-size: 16px;
							color: #565656cc;
							margin-bottom: 5px;
							&:hover {
								background: $secondary;
							}
						}

						.radio {
							display: flex;
							align-items: center;
							padding: 10px 30px;
							svg {
								color: '#fe6e6b' !important;
							}
						}
					}
					h3 {
						font-size: 14px;
						color: #565656cc;
						font-weight: 500;
						padding: 10px 10px 0 10px;
						display: flex;
						align-items: center;
						svg {
							font-size: 20px;
						}
					}
					.srch-fltr {
						width: 85%;
						height: 40px;

						margin: 10px 20px;
						border: 1px solid rgba(86, 86, 86, 0.2);
						border-radius: 5px;
						box-shadow: none;

						fieldset {
							display: none;
						}
						& > div {
							svg {
								color: rgba(86, 86, 86, 0.3);
							}
							input {
								font-family: 'poppins';
								color: $text;
								font-size: 14px;
								padding: 11.5px 14px;
								&::-webkit-input-placeholder {
									color: rgba(86, 86, 86, 0.5);
									font-family: 'poppins';
								}
							}
						}
					}
					.fltr-dta {
						// padding: 0px 21px;
						.maincheck {
							display: flex;
							align-items: center;
							flex-direction: column;
							padding: 0 10px;
							.check {
								display: flex;
								align-items: center;
								width: 100%;
							}
							.fltr-chckbx {
								color: $secondary;
								padding: 0px 9px !important;
							}
							h6 {
								padding: 0 6px;
								font-weight: 400;
								font-size: 14px;
								line-height: 34px;
								color: rgba(86, 86, 86, 0.8);
							}
						}
					}
					h6 {
						padding: 4px 21px;
						font-weight: 500;
						font-size: 14px;
						line-height: 34px;
						color: #3c1c1b;
						cursor: pointer;
						&:hover {
							color: $secondary;
						}
					}
				}
				&::-webkit-scrollbar {
					width: 3px;
					// height: 3px;
				}

				&::-webkit-scrollbar-button {
					background: #ccc;
				}

				&::-webkit-scrollbar-track-piece {
					background: #fff;
				}

				&::-webkit-scrollbar-thumb {
					background: #fe6e6b;
				}
			}
			.lst-ryt {
				width: 75%;
				.lst-ryt-top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					h4 {
						font-weight: 400;
						font-size: 16px;
						line-height: 34px;
						align-items: center;
						color: #5656566b;
					}
					.align_dropdown {
						display: flex;
						align-items: center;
						gap: 12px;
						.srvc-sel {
							width: 120px;
							color: #5656566b;
							border: 2px solid rgba($text, 0.2);
							cursor: pointer;
							&:hover {
								border: 2px solid #fb6767;
							}
							div {
								padding: 12.5px 14px !important;
							}

							fieldset {
								display: none;
							}
						}

						.srcv-lst {
							font-size: 14px;
							color: rgba($text, 0.5);
						}

						.fltr-optns {
							width: 230px;
							// border: 1px solid rgba($text, 0.2);
							border-radius: 4px;
							.auto-inpt > div {
								padding: 4px !important;
								overflow-x: scroll;
								flex-wrap: nowrap;
								position: relative;
								border: 2px solid rgba($text, 0.2);
								// border: 1px solid transparent;
								&:hover {
									border: 2px solid #fb6767;
								}
								&::-webkit-scrollbar {
									height: 2px;
								}
								&::-webkit-scrollbar-track {
									background: white;
								}

								.MuiAutocomplete-endAdornment {
									padding: 0 !important;
								}
							}

							fieldset {
								display: none;
							}
						}
					}
				}
				.lst-ryt-btm {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					row-gap: 18px;
					margin-top: 30px;
					.mn-cpns-lst {
						width: 32% !important;
						@media (max-width: 1130px) {
							width: 50% !important;
						}
						.cpn_crd {
							width: inherit;
							h2 {
								@media (max-width: 1440px) {
									font-size: 16px;
								}
								@media (max-width: 1300px) {
									font-size: 14px;
								}
							}
							.adrss {
								p {
									@media (max-width: 1300px) {
										font-size: 11px;
										width: 100%;
									}
								}
							}
							.prc2 {
								h2 {
									font-size: 22px !important;
									margin-top: 4px !important;
								}
							}
							.crd_btns {
								margin-top: 4px !important;
								.btn {
									padding: 12px 20px;
									@media (max-width: 1440px) {
										padding: 10px 12px;
									}
									@media (max-width: 1300px) {
										font-size: 13px;
									}
									@media (max-width: 1200px) {
										padding: 10px 14px;
									}
								}
								.btn2 {
									padding: 12px 20px;
									@media (max-width: 1440px) {
										padding: 10px 12px;
									}
									@media (max-width: 1300px) {
										font-size: 12px;
									}
									@media (max-width: 1200px) {
										padding: 10px 14px;
									}
								}
							}
						}
					}
					.prdct-sec-btm {
						width: 100%;
						h4 {
							text-align: center;
							font-weight: 500;
							font-size: 18px;
							line-height: 37px;
							color: $text;
							cursor: pointer;
							opacity: 0.5;
							padding: 20px 0;
							span {
								vertical-align: text-top;
							}
						}
					}
				}
			}
		}
	}
	.dscount_srvc {
		background: transparent;
		margin-top: 0;
	}
}
