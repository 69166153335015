.policycontent {
	margin-top: -244px;
	.content1 {
		p {
			color: #565656b2;
			line-height: 32px;
			font-size: 15px;
			@media (max-width: 1200px) {
				line-height: 30px;
				font-size: 14px;
			}

			span {
				font-weight: bold;
			}
			// margin-bottom: 28px;
		}
		h5 {
			color: #565656b2;
		}
		h6 {
			color: #565656b2;
			line-height: 34px;
			font-size: 15px;
			font-weight: 500;
			// margin-bottom: 28px;
		}
		.limitdiv {
			ul {
				li {
					color: #565656b2;
					line-height: 34px;
				}
			}
		}
	}
	.con_tnt {
		padding-top: 30px;
	}
}
// .policycontent h5 {
//     color: #565656B2;
// }
.policycontent h5.upd_te {
	color: #565656b2;
	font-weight: 500;
}
.upd_text p {
	margin-bottom: 28px;
	color: rgba(86, 86, 86, 0.7);
}
// .policycontent p {
//     color: #565656B2;
//     line-height: 34px;
//     // margin-bottom: 28px;
// }
// .policycontent h6 {
//     color: #565656B2;
//     line-height: 34px;
//     font-size: 15px;
//     font-weight: 500;
//     // margin-bottom: 28px;
// }
.dots_para {
	margin-top: 30px;
}
.dots_para li {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	margin-left: 12px;
}
.dots_para li span {
	display: inline-block;
	margin-top: 3px;
	color: #565656b2;
}
