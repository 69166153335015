@import '../../assets/theme/variable.scss';
.contactmain {
	padding-top: 0 !important;
	padding-bottom: 73px;
	background: white !important;
}
.contactbnr {
	background: url(../../../public/static/images/map_img.svg);
	width: 100%;
	height: 550px;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
	@media (max-width: 1230px) {
		height: 530px;
	}
	@media (max-width: 1200px) {
		height: 510px;
	}
	@media (max-width: 1150px) {
		height: 480px;
	}
	@media (max-width: 1040px) {
		height: 450px;
	}
	@media (max-width: 991px) {
		height: 420px;
	}
	h1 {
		font-weight: 600;
		font-size: 44px;
		line-height: 61px;
		color: #ffffff;
		padding-top: 90px;
		padding-left: 20px;
	}
}
.cForm {
	display: flex;
	justify-content: space-between;
	// flex-wrap: wrap;
	background-color: #fff;
	border-radius: 15px;
	margin-top: -222px;
	box-shadow: 0px 70px 120px 5px rgba(0, 0, 0, 0.07);
	@media (max-width: 991px) {
		margin-top: -132px;
	}
	.cfleft {
		width: 50%;
		padding: 48px 50px;
		h3 {
			font-weight: 600;
			font-size: 36px;
			line-height: 52px;
			color: #565656;
			padding-bottom: 10px;
		}
		p {
			font-size: 16px;
			line-height: 34px;
			color: #565656;
			opacity: 0.4;
		}
		.personal {
			input {
				background: white;

				border-radius: 10px;
				padding: 15px 12px;
				color: #565656;
				font-weight: 400;

				&::-webkit-input-placeholder {
					color: #565656;
					font-weight: 400;
				}
			}
			.email-div {
				display: flex;
				width: 100%;
				justify-content: space-between;
				.phoneinputt {
					width: 48.8%;
					@media (max-width: 1366px) {
						width: 48.5%;
					}
					input {
						background: #d9d9d91a;
						border-radius: 10px;
						padding: 26px 42px;
						color: #565656;
						font-weight: 400;
						// border-bottom: transparent;
						&::-webkit-input-placeholder {
							color: #565656;
							font-weight: 400;
						}
					}
				}
			}
			.email-div {
				display: flex;
				width: 100%;
				.phoneinputt {
					input {
						background: white !important;
						border-radius: 10px;
						padding: 26px 42px;
						color: #565656;
						font-weight: 400;
						// border-bottom: transparent;
						&::-webkit-input-placeholder {
							color: #565656;
							font-weight: 400;
						}
					}
				}
			}
			.css-1ff8729-MuiInputBase-root-MuiFilledInput-root:hover:not(
					.Mui-disabled,
					.Mui-error
				):before,
			.css-1ff8729-MuiInputBase-root-MuiFilledInput-root:after,
			.css-1ff8729-MuiInputBase-root-MuiFilledInput-root:before {
				// border-bottom: transparent;
			}
			.MuiFormControl-root {
				width: 48.8%;
				margin-bottom: 20px;
				@media (max-width: 1366px) {
					width: 48.5%;
				}
			}
		}
		.bussiness {
			@extend .personal;
			.MuiFormControl-root {
				width: 100%;
			}
		}
		.css-1ff8729-MuiInputBase-root-MuiFilledInput-root {
			border-radius: 10px;
		}
		.register {
			margin-top: 30px;
		}
	}
	.cfRight {
		width: 50%;
		background-color: #000;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
		.cont_info {
			background: #484e54;
			box-shadow: 0px 65px 50px rgba(0, 0, 0, 0.1);
			border-radius: 0px 0px 10px 10px;
			padding: 0 27px;
			margin: 0 28px 33px;
			position: relative;
			h4 {
				font-weight: 600;
				font-size: 32px;
				line-height: 52px;
				color: #ffffff;
				margin-bottom: 22px;
				padding-top: 110px;
			}
			.cOption {
				display: flex;
				align-items: center;
				gap: 20px;
				.cOleft {
					figure {
						height: 90px;
						width: 100px;
					}
				}
				.cOright {
					h5 {
						font-weight: 600;
						font-size: 22px;
						line-height: 43px;
						color: #ffffff;
						padding-bottom: 6px;
						@media (max-width: 1440px) {
							font-size: 19px;
							line-height: 23px;
						}
						@media (max-width: 992px) {
							font-size: 16px;
						}
					}
					h6 {
						font-weight: 500;
						font-size: 18px;
						line-height: 37px;
						color: #888888;
						@media (max-width: 1440px) {
							line-height: 27px;
						}
						@media (max-width: 1200px) {
							font-size: 15px;
							line-height: 27px;
						}
						@media (max-width: 992px) {
							font-size: 15px;
							line-height: 20px;
						}
					}
					.underline {
						text-decoration-line: underline;
					}
				}
			}
			&::after {
				content: '';
				position: absolute;
				background: url(../../../public/static/images/grey_dots.svg);
				width: 300px;
				height: 300px;
				background-repeat: no-repeat;
				background-size: 100%;
				background-position: center;
				right: 0;
				top: -92px;
			}
			li {
				border-bottom: 1px solid #ffe8e8;
				padding: 14px 0 7px;
				&:nth-child(3) {
					border-bottom: none;
				}
			}
			.cotct_icons {
				display: flex;
				align-items: center;
				gap: 17px;
				width: 100%;
				justify-content: center;
				padding-top: 17px;
				li {
					border-bottom: none;
					figure {
						cursor: pointer;
					}
				}
			}
		}
	}
	form {
		margin-top: 35px;
	}
}
.cntct-inpt {
	& > div {
		background: white;
		border: 1px solid rgba($secondary, 0.3);
	}
	fieldset {
		display: none;
	}
}
