.contactmain .howitwork {
	padding-bottom: 121px;
	.privacybnr .ppbanner {
		.ppleft {
			width: 48%;
			h3 {
				margin-top: 27px;
			}
		}
		.ppright {
			width: 48%;
			figure {
				height: 260px;
			}
		}
	}
}
.howitwork {
	.privacybnr {
		&:before {
			position: absolute;
			content: '';
			background: url('../../../public/static/images/logo-bg.svg');
			height: 460px;
			width: 200px;
			top: 650px;
			background-repeat: no-repeat;
			background-size: contain;
		}
		.ppbanner {
			.ppleft {
				width: 48%;
				h3 {
					margin-top: 27px;
					@media (max-width: 1200px) {
						padding-left: 20px;
					}
				}
			}
			.ppright {
				width: 48%;
				figure {
					height: 260px;
				}
			}
		}
	}

	.tickets {
		margin-top: -130px;
		position: relative;
		background-color: transparent;

		// @media (max-width: 1224px) {
		// 	margin-top: -130px;
		// }
		// @media (max-width: 1044px) {
		// 	margin-top: -100px;
		// }
		ul {
			li {
				background: #ffffff;
				box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.06);
				border-radius: 10px;
				padding: 16px 30px;
				margin-bottom: 25px;
				h3 {
					border-bottom: 1px solid rgba(86, 86, 86, 0.2);
					padding: 0px 0 8px;
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
					color: #565656;
				}
				p {
					margin-top: 12px;
					font-size: 14px;
					line-height: 21px;
					letter-spacing: 0.01em;
					color: #565656;
					opacity: 0.4;
					font-weight: 500;
				}
			}
		}
	}
	.tickets2 {
		margin-top: 40px;
		position: relative;
		background-color: transparent;
		h3 {
			color: #565656;
			font-size: 25px;
			font-weight: 500;
			margin-bottom: 20px;
		}
		ul {
			li {
				background: #ffffff;
				box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.06);
				border-radius: 10px;
				padding: 16px 30px;
				margin-bottom: 25px;
				h3 {
					border-bottom: 1px solid rgba(86, 86, 86, 0.2);
					padding: 0px 0 8px;
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
					color: #565656;
				}
				p {
					margin-top: 12px;
					font-size: 14px;
					line-height: 21px;
					letter-spacing: 0.01em;
					color: #565656;
					opacity: 0.4;
					font-weight: 500;
				}
			}
		}
	}
	.termsofuse {
		background: #ffffff;
		border: 1px solid rgba(86, 86, 86, 0.2);
		box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.06);
		border-radius: 10px;
		margin-top: 20px;
		// padding: 20px;
		h5 {
			color: #808080;
			font-size: 17px;
			font-weight: 500;
			border-bottom: 1px solid rgba(86, 86, 86, 0.2);
			padding: 10px;
			text-transform: capitalize;
			span {
				color: #fa6866;
			}
		}
		p {
			color: #565656;
			font-size: 14px;
			padding: 10px;
		}
	}
}
