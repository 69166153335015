$primary: #ffe8e8;
$secondary: #fe6e6b;
$text: #565656;
$white: #fff;
$headings: #565656;
$background: #fca177;
$lighttext: #979797;
$para: #acacac;
$darktext: #253d4e;
$red: #d32f2f;
