@import '../../assets/theme/variable.scss';

section.bnr {
	overflow: hidden;

	// padding: 70px 0 0;
	.mn_bnr {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.bnr_lft {
			width: 45%;
			// padding-top: 100px;
			.fr_hdng {
				font-size: 50px;
				margin-bottom: 10px;
				font-weight: 700;
				width: 100%;
				line-height: 70px;
				color: #565656;
				@media (max-width: 1500px) {
					font-size: 48px;
				}
				@media (max-width: 1500px) {
					font-size: 42px;
				}
				.undrln_hdng_fr {
					position: relative;
					&::after {
						content: '';
						position: absolute;
						background: url('../../../public/static/images/underlined_hdng.svg');
						width: 100%;
						height: 20px;
						bottom: -15px;
						left: 0;
						background-repeat: no-repeat;
					}
				}
			}
			h1 {
				font-size: 60px;
				font-weight: 700;
				width: 100%;
				line-height: 90px;
				color: $headings;

				span {
					color: $secondary;
					position: relative;

					&::after {
						height: 12px;
						width: 100%;
						bottom: 0;
					}
				}
			}

			p {
				font-size: 16px;
				font-weight: 500;
				line-height: 32px;
				color: $text;
				padding-top: 16px;
			}

			.explr {
				padding-top: 30px;

				button {
					margin-right: 20px;
				}
			}
		}

		.bnr_ryt {
			width: 50%;
			padding-top: 20px;

			.hm-bnr-lottie {
				height: 570px;
				width: 100%;
			}
		}
	}
}

.ofrs {
	padding: 0px 0 70px;

	.mn_ofrs {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		.ofr-dv {
			padding: 30px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 32%;
			border-radius: 10px;
			background: rgba($primary, 50%);
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			cursor: pointer;
			&:last-child {
				li h2 {
					width: 100% !important;
				}
			}
			@media (max-width: 1920px) {
				padding: 40px 0;
			}
			@media (max-width: 1500px) {
				padding: 30px 0;
			}

			// &:hover {

			// }
			li {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: auto;

				.ofr-fig {
					height: 60px;
					width: 60px;

					background: white;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					margin-right: 20px;
					padding: 10px;
				}

				figure {
					width: 60px;
					height: 60px;
				}

				h2 {
					width: 50%;
					line-height: 36px;
					font-size: 24px;
					color: $headings;
					font-weight: 600;
				}
			}
		}
		.active {
			background: $secondary;
			li {
				h2 {
					color: $white;
				}
			}
		}
	}
}

section.cat_gries {
	padding: 0px 0 30px;

	h2 {
		font-size: 38px;
		text-align: center;
		padding-bottom: 70px;
		color: $headings;

		span {
			color: $secondary;
			position: relative;

			&::after {
				height: 22px;
				width: 220px;
				bottom: -20px;
			}
		}
	}

	.mn_categories {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		div {
			width: calc(25% - 56px);
			border: 1px solid rgba($text, 0.1);
			margin-bottom: 20px;
			border-radius: 10px;
			padding: 20px 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			cursor: pointer;
			&:hover {
				background: rgba($primary, 50%);
			}

			figure {
				width: 120px;
				height: 120px;
			}

			h4 {
				font-size: 24px;
				font-weight: 600;
				color: $headings;
				margin-top: 20px;
			}
		}
	}
}

.wishOrder {
	padding: 10px;
	.cpn_crd {
		background: $white;
		padding: 10px;
		width: 92%;
		border-radius: 14px;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		cursor: pointer;
		.crd-img-mn {
			position: relative;
			figure {
				width: 100%;
				height: auto;
			}
			.crd-div {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 100%;
				position: absolute;
				top: 6px;
				.crd-rtng {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-left: 8px;
					background: #fec36b;
					padding: 6px 10px;
					border-radius: 6px;
					@media (max-width: 1200px) {
						padding: 6px 6px;
					}

					h4 {
						color: white;
						margin-left: 6px;
						@media (max-width: 1200px) {
							margin-right: 4px;
							font-size: 12px;
						}
					}
				}
				.crd-like {
					background: white;
					margin-right: 10px;
					height: 20px;
					width: 20px;
					border-radius: 50%;
					padding: 6px;
					box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
				}
			}
		}

		.ctgy_nm h6,
		h2 {
			font-size: 18px;
			font-weight: 600;
			color: $headings;
			// margin-top: 10px;
			@media (max-width: 1440px) {
				font-size: 17px;
			}
			@media (max-width: 1300px) {
				font-size: 16px;
			}
		}
		.ctgy_nm {
			h6 {
				color: $secondary !important;
				margin-top: 0 !important;
			}
		}
		.adrss {
			display: flex;
			justify-content: flex-start;
			color: $text;
			margin-top: 5px;

			svg {
				margin-right: 6px;
				color: $text;
			}

			p {
				font-size: 12px;
				font-weight: 400;
				line-height: 20px;
				// opacity: 50%;
				width: 80%;
				@media (max-width: 1920px) {
					width: 65%;
				}
				@media (max-width: 1500px) {
					width: 80%;
				}
			}
		}
		.rtng-crd {
			display: flex;
			padding-top: 10px;
			figure {
				height: 18px;
				width: 18px;
				margin-right: 4px;
			}
		}

		.prc {
			display: flex;
			// margin: 10px 0 0;
			align-items: center;
			justify-content: space-between;
			// justify-content: flex-start;
			margin-top: 10px;

			h2 {
				color: $secondary;
				font-size: 24px;
				@media (max-width: 1440px) {
					font-size: 22px;
				}

				span {
					color: $text;
					font-weight: 300;
					// opacity: 50%;
				}
			}
			button {
				@media (max-width: 1440px) {
					font-size: 12px;
					padding: 12px 20px;
				}
			}
		}

		.prc2 {
			display: flex;
			flex-direction: column;

			h2 {
				color: $secondary;
				font-size: 24px;

				span {
					color: rgba($text, 0.5);
					font-weight: 300;
				}
			}
		}

		.crd_btns {
			display: flex;
			justify-content: flex-start;
			margin-top: 10px;

			button {
				margin-right: 10px;
			}
		}
	}
}
.wishOrder {
	padding: 10px;
	.cpn_crd2 {
		// background: rgba($text, 6%);
		padding: 10px;
		width: 92%;
		border-radius: 14px;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		cursor: pointer;

		.crd-img-mn {
			position: relative;
			figure {
				width: 100%;
				height: auto;
			}
			.crd-div {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 100%;
				position: absolute;
				top: 6px;
				.crd-rtng {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-left: 8px;
					background: #fec36b;
					padding: 6px 10px;
					border-radius: 6px;
					@media (max-width: 1200px) {
						padding: 6px 6px;
					}

					h4 {
						color: white;
						margin-left: 6px;
						@media (max-width: 1200px) {
							margin-right: 4px;
							font-size: 12px;
						}
					}
				}
				.crd-like {
					background: white;
					margin-right: 10px;
					height: 20px;
					width: 20px;

					padding: 6px;
					border-radius: 50%;
					box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
				}
			}
		}

		.ctgy_nm h6,
		h2 {
			font-size: 18px;
			font-weight: 600;
			color: $headings;
			margin-top: 10px;
			@media (max-width: 1440px) {
				font-size: 17px;
			}
			@media (max-width: 1300px) {
				font-size: 16px;
			}
		}
		.ctgy_nm h6 {
			color: $secondary !important;
			margin-top: 0 !important;
		}
		.adrss {
			display: flex;
			justify-content: flex-start;
			color: $text;
			margin-top: 5px;

			svg {
				margin-right: 6px;
				color: $text;
			}

			p {
				font-size: 12px;
				font-weight: 400;
				line-height: 20px;

				width: 80%;
				@media (max-width: 1920px) {
					width: 65%;
				}
				@media (max-width: 1500px) {
					width: 80%;
				}
			}
		}
		.rtng-crd {
			display: flex;
			padding-top: 10px;
			figure {
				height: 18px;
				width: 18px;
				margin-right: 4px;
			}
		}
		.prc {
			margin: 10px 0;
			display: flex;
			justify-content: space-between;

			h2 {
				color: $secondary;
				font-size: 24px;

				span {
					color: rgba($text, 0.5);
					font-weight: 300;
					opacity: 50%;
				}
			}
		}

		.prc2 {
			display: flex;
			flex-direction: column;

			h2 {
				color: $secondary;
				font-size: 24px;
				@media (max-width: 1440px) {
					font-size: 22px;
				}
				@media (max-width: 1300px) {
					font-size: 20px;
				}

				span {
					color: rgba($text, 0.5);
					font-weight: 300;
				}
			}
		}

		.crd_btns {
			display: flex;
			justify-content: flex-start;
			margin-top: 10px;

			button {
				margin-right: 10px;
				@media (max-width: 1440px) {
					font-size: 12px;

					padding: 12px 20px;
				}
			}
		}
	}
}

// --------------------------Responsive-----------------
section.bnr {
	.mn_bnr {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 90%;
		flex-wrap: wrap;
		margin-left: auto;

		.bnr_lft {
			@media (max-width: 1200px) {
				// padding-top: 80px;
			}

			@media (max-width: 992px) {
				width: 100%;
				text-align: center;
			}
			@media (max-width: 480px) {
				// padding-top: 30px;
			}

			h1 {
				@media (max-width: 1500px) {
					font-size: 52px;
				}
				@media (max-width: 1300px) {
					font-size: 48px;
					line-height: 78px;
					width: 90%;
				}

				@media (max-width: 1200px) {
					font-size: 42px;
					line-height: 70px;
				}

				@media (max-width: 992px) {
					font-size: 34px;
					line-height: 62px;
				}

				@media (max-width: 480px) {
					font-size: 32px;
					line-height: 56px;
				}

				span {
					&::after {
						@media (max-width: 992px) {
							bottom: -6px;
						}
					}
				}
			}

			p {
				@media (max-width: 1300px) {
					font-size: 13px;
					line-height: 30px;
				}

				@media (max-width: 1200px) {
					font-size: 12px;
					line-height: 28px;
				}

				@media (max-width: 992px) {
					width: 90%;
				}
			}
		}

		.bnr_ryt {
			@media (max-width: 1200px) {
				// padding-top: 20px;
			}

			@media (max-width: 992px) {
				width: 100%;
			}
			@media (max-width: 480px) {
				display: none;
			}
			.hm-bnr-lottie {
				@media (max-width: 1500px) {
					height: 500px;
				}
				@media (max-width: 1300px) {
					width: 100%;
					height: 500px;
				}
				@media (max-width: 1200px) {
					width: 100%;
					height: 500px;
				}

				@media (max-width: 992px) {
					height: 480px;
					padding-top: 20px;
				}

				@media (max-width: 767px) {
					height: 450px;
				}

				@media (max-width: 600px) {
					height: 410px;
				}
			}
		}
	}
}

.ofrs {
	@media (max-width: 480px) {
		padding: 50px 0 40px;
	}
	.mn_ofrs {
		div {
			// @media (max-width: 1200px) {
			// 	// width: 24%;
			// }

			// @media (max-width: 992px) {
			// 	padding: 26px 28px;
			// }
			// @media (max-width: 767px) {
			// 	width: 80%;
			// 	margin: 0 auto;
			// 	margin-bottom: 20px;
			// }
			li {
				@media (max-width: 767px) {
					justify-content: center;
				}

				.ofr-fig {
					@media (max-width: 1200px) {
						height: 50px;
						width: 50px;
					}

					@media (max-width: 992px) {
						height: 40px;
						width: 40px;

						margin-right: 10px;
					}
					@media (max-width: 767px) {
						margin-right: 20px;
					}
					figure {
						@media (max-width: 992px) {
							width: 40px;
							height: 40px;
						}
					}
				}

				h2 {
					@media (max-width: 1300px) {
						line-height: 34px;
						font-size: 22px;
					}

					@media (max-width: 1200px) {
						line-height: 32px;
						font-size: 18px;
					}

					@media (max-width: 992px) {
						line-height: 30px;
						font-size: 18px;
					}
				}
			}
		}
	}
}

section.cat_gries {
	@media (max-width: 1300px) {
		// padding: 50px 0 50px;
	}
	@media (max-width: 992px) {
		// padding: 0px 0 40px;
	}

	h2 {
		@media (max-width: 1300px) {
			font-size: 34px;
		}
		@media (max-width: 1200px) {
			font-size: 32px;
		}

		@media (max-width: 992px) {
			font-size: 30px;
		}
		span {
			&::after {
				width: 100%;
				height: 18px;
				bottom: -18px;
			}
		}
	}

	.mn_categories div {
		figure {
			@media (max-width: 1200px) {
				width: 90px;
				height: 90px;
			}

			@media (max-width: 992px) {
				width: 80px;
				height: 80px;
			}
		}

		h4 {
			@media (max-width: 1200px) {
				font-size: 20px;
			}

			@media (max-width: 992px) {
				font-size: 18px;
			}
		}
	}
}

.cpn_crd {
	figure {
		@media (max-width: 1300px) {
			height: auto;
		}
	}

	h2 {
		@media (max-width: 1300px) {
			font-size: 16px;
			margin-top: 8px;
		}

		@media (max-width: 1200px) {
			font-size: 14px;
			margin-top: 6px;
		}
	}

	.adrss p {
		@media (max-width: 1300px) {
			font-size: 12px;
			line-height: 20px;
		}

		@media (max-width: 1200px) {
			font-size: 11px;
		}
	}

	.prc h2 {
		@media (max-width: 1300px) {
			font-size: 24px !important;
		}

		@media (max-width: 1200px) {
			font-size: 20px !important;
		}
	}
	.prc2 h2 {
		@media (max-width: 1300px) {
			font-size: 24px !important;
		}

		@media (max-width: 1200px) {
			font-size: 20px !important;
		}
	}
}
.cpn_crd2 {
	figure {
		@media (max-width: 1300px) {
			height: auto;
		}
	}

	h2 {
		@media (max-width: 1300px) {
			font-size: 16px;
			margin-top: 8px;
		}

		@media (max-width: 1200px) {
			font-size: 14px;
			margin-top: 6px;
		}
	}

	.adrss p {
		@media (max-width: 1300px) {
			font-size: 12px;
			line-height: 20px;
		}

		@media (max-width: 1200px) {
			font-size: 11px;
		}
	}

	.prc h2 {
		@media (max-width: 1300px) {
			font-size: 24px;
		}

		@media (max-width: 1200px) {
			font-size: 20px !important;
		}
	}
	.prc2 h2 {
		@media (max-width: 1300px) {
			font-size: 24px;
		}

		@media (max-width: 1200px) {
			font-size: 20px !important;
		}
	}
}
