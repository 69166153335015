@import '../../assets/theme/variable.scss';

.auth-sec {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100vh;
	min-height: 760px;
	background-image: url('../../../public/static/images/auth_bg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	// overflow: hidden;
	.form_sc {
		height: 100%;
		display: flex;
		align-items: center;
	}
	.auth-lft {
		width: 49%;
		position: relative;
		height: 100%;
		.auth_logo {
			padding: 20px;
			margin-bottom: 20px;
			position: absolute;
			top: 0;
			width: 160px;
			height: 50px;
		}
		.signUp-screen {
			text-align: center;
			max-width: 420px;
			margin: 0 auto;

			.grey_text {
				color: $text;
			}

			h3 {
				font-size: 26px;
				margin-bottom: 10px;
				font-weight: 600;
				color: #343a40;

				margin-top: 30px;
			}
			p {
				font-size: 14px;
				color: #565656;
				span {
					color: $secondary;
					cursor: pointer;
					font-weight: 500;
				}
			}
		}
		.scl-login {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 10px;

			figure {
				width: 48%;
				cursor: pointer;
				margin-right: 10px;
			}
		}
		.or_opt {
			position: relative;

			margin: 20px 0;

			p {
				position: absolute;
				background: white;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.sign_bx {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			.names-sec {
				display: flex;
				justify-content: space-between;
				// align-items: center;
				width: 100%;
				.txt-fld-auth {
					width: 49%;
					// margin-bottom: 10px;
				}
			}
			.txt-fld-auth {
				p {
					color: #d32f2f !important;
					font-size: 12px !important;
					margin: -6px 0 10px !important;
				}
				& > div {
					width: 100%;
					margin-bottom: 10px;
					border-bottom: 1px solid rgba(86, 86, 86, 0.2);
					background: rgba(86, 86, 86, 0.04);
					border-radius: 6px;
					height: 50px;
					input {
						font-size: 14px;
						&::placeholder {
							font-size: 14px;
						}
					}
					fieldset {
						display: none;
					}
				}
			}
		}
		.chckbx-sec {
			display: flex;
			align-items: flex-start;
			margin: 20px 0;
			span {
				padding: 0;
				color: $secondary;
				cursor: pointer;
				svg {
					font-size: 20px;
				}
			}

			p {
				font-size: 14px;
				margin: 0;
				text-align: left;
				margin-left: 4px;
				// line-height: 27px;

				span {
					color: $secondary;
				}
			}
		}
		.btn {
			width: 100%;
			margin-bottom: 14px;
		}
		.btn2 {
			width: 100%;
			margin-bottom: 30px;
		}
		.btm-auth {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 20px;
			position: relative;
			bottom: 0;
			width: 100%;

			h5 {
				font-size: 14px;
				font-weight: 400;
				color: #979797;
			}
			h6 {
				font-size: 14px;
				font-weight: 400;
				color: $secondary;
				margin-bottom: 10px !important;
			}
			// .css-2y464i-MuiInputBase-root-MuiFilledInput-root {
			// 	background-color: none !important;
			// }
		}
	}
	.auth-ryt {
		width: 49%;
		text-align: right;

		figure {
			// position: absolute;
			// bottom: 0;
			height: 100vh;
			position: fixed;
			right: 0;
			top: 0;

			img {
				width: inherit !important;
				height: 100vh !important;
				min-height: 640px;
			}
		}
	}
}
.frgt-sec {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.frgt_pass {
		text-decoration: underline;
		cursor: pointer;
		color: $secondary !important;
	}
}
