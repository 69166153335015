@import '../../assets/theme/variable.scss';
.prdcts-lst {
	.no-ofr {
		padding-top: 60px;
		h4 {
			text-align: center;
			width: 60%;
			margin: 0 auto;
			font-size: 30px;
			line-height: 44px;
			color: rgba($text, 0.5);
		}
	}

	.signUp {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		border-radius: 10px;
		margin-top: 60px;
		padding-bottom: 40px;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

		.signUp-screen {
			text-align: center;
			max-width: 520px;
			margin: 0 auto;
			width: 70%;

			.grey_text {
				color: $text;
			}

			h3 {
				font-size: 28px;
				margin-bottom: 15px;
				font-weight: 600;
				padding-top: 20px;
			}
			p {
				font-size: 14px;
				color: $text;
				margin-bottom: 20px;

				span {
					color: $secondary;
					cursor: pointer;
				}
			}
		}
		.scl-login {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 10px;

			figure {
				width: 44%;
				cursor: pointer;
			}
		}
		.or_opt {
			position: relative;
			margin: 50px 0;

			p {
				position: absolute;
				background: #f7f7f7;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.sign_bx {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			.names-sec {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				.txt-fld-auth {
					width: 49%;
					margin-bottom: 10px;
				}
			}
			.txt-fld-auth {
				width: 100%;
				margin-bottom: 10px;
				border-bottom: 1px solid rgba(86, 86, 86, 0.2);
				background: rgba(86, 86, 86, 0.04);
				border-radius: 6px;
				fieldset {
					display: none;
				}
			}
		}
		.chckbx-sec {
			display: flex;
			align-items: center;
			margin: 20px 0;

			p {
				font-size: 14px;
				margin: 0;

				span {
					color: $secondary;
				}
			}
		}
		button {
			width: 100%;
			margin-bottom: 30px;
		}
		.btm-auth {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 40px;
			padding: 10px 20px;
			position: absolute;
			bottom: 0;
			width: 100%;

			h5 {
				font-size: 14px;
				font-weight: 400;
			}
			h6 {
				font-size: 14px;
				font-weight: 400;
				color: $secondary;
				margin-bottom: 10px !important;
			}
			// .css-2y464i-MuiInputBase-root-MuiFilledInput-root {
			// 	background-color: none !important;
			// }
		}
	}
	.auth-ryt {
		width: 49%;

		figure {
			position: absolute;
			bottom: 0;
		}
	}
}
.frgt-sec {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.frgt_pass {
		text-decoration: underline;
		cursor: pointer;
		color: $secondary !important;
	}
}
