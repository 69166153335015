@import '../../assets/theme/variable.scss';
section.brws_cpns {
	padding: 10px;

	.mn_brws_cpns {
		padding: 10px;
		// background: #fafafa;
		background: $white;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		border-radius: 10px;
		.cpn_to_sell {
			border-radius: 10px;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			background: $white;
			padding: 10px;
			.to-sell-heading {
				padding: 20px 14px;
				h2 {
					font-size: 36px;
					font-weight: 700;
					color: $headings;
					@media (max-width: 1440px) {
						font-size: 32px;
					}

					@media (max-width: 1300px) {
						font-size: 30px;
					}
					@media (max-width: 1200px) {
						font-size: 26px;
					}
				}
			}

			.inr_to_sell {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				// padding: 20px 0 70px;
				.cpn-img {
					width: 30%;
					figure {
						height: 300px;
						width: 100%;
						img {
							object-fit: cover;
						}
					}
				}

				.inpt-fld-sec {
					width: 32%;
					p {
						color: #ff0000;
						font-size: 18px;
						font-weight: 400;
						margin-bottom: 10px;
					}
					input[type='text'] {
						padding: 20px;
						width: 86%;
						border-radius: 30px;
						border: 1px solid rgba($text, 0.5);
						&::placeholder {
							font-size: 16px;
						}
					}
				}
				.btn-sec {
					width: 30%;
					div {
						display: flex;
						justify-content: space-between;
						align-items: center;
						background: #289f25;
						height: 40px;
						box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
						border-radius: 30px;
						padding: 10px;
						cursor: pointer;
						margin-top: 30px;
						figure {
							width: 40px;
							height: 40px;
						}
						p {
							color: $white;
							font-size: 20px;
							font-weight: 500;
							margin: 0;
						}
						svg {
							border: 1px solid #238121;
							background: #238121;
							border-radius: 50%;
							padding: 8px;
							color: $white;
						}
					}
				}
			}
		}
		ul.sell_cpn_ftrs {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			// padding-top: 200px;
			padding-top: 140px;

			li {
				width: 30%;
				// background: rgba($text, 0.1);
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
				padding: 20px 10px;
				border-radius: 10px;
				div {
					display: flex;
					justify-content: flex-start;

					.sell-ftrs-img {
						position: relative;
						bottom: 60px;
						background: $primary;
						padding: 30px;
						border-radius: 20px;
						box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
						figure {
							height: 70px;
							width: 70px;
						}
					}
					.sell-ftrs-hdng {
						margin-left: 20px;
						h4 {
							text-align: left;
							text-align: left;
							font-size: 22px;
							font-weight: 500;
							color: $headings;
						}
					}
				}
				p {
					font-size: 13px;
					color: $text;
					margin-top: -40px;
				}
			}
		}
		.cpns_to_resell {
			padding-top: 70px;
			h2 {
				font-size: 36px;
				font-weight: 700;
				color: $headings;
				margin-bottom: 20px;
				@media (max-width: 1440px) {
					font-size: 32px;
				}

				@media (max-width: 1300px) {
					font-size: 30px;
				}
				@media (max-width: 1200px) {
					font-size: 26px;
				}
			}
			.mn-cpns-lst {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-top: 16px;
				h2 {
					font-size: 46px;
					font-weight: 700;
					color: rgba($text, 0.5);
				}
				.cpn_crd3 {
					display: flex;
					justify-content: space-between;
					align-items: stretch;
					padding: 10px;
					margin: 10px 0 10px 20px;
					background: $white;
					border-radius: 10px;
					box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
					width: 92%;
					.crd_lft {
						width: 36%;
						@media (max-width: 1440px) {
							width: 45%;
						}

						.crd-img-mn {
							position: relative;
							figure {
								width: 100%;
								height: auto;
							}
							.crd-div {
								display: flex;
								justify-content: space-between;
								align-items: center;
								width: 100%;
								position: absolute;
								top: 6px;
								.crd-rtng {
									display: flex;
									justify-content: flex-start;
									align-items: center;
									margin-left: 8px;
									background: #fec36b;
									padding: 6px 10px;
									border-radius: 6px;
									@media (max-width: 1200px) {
										padding: 6px 6px;
									}

									h4 {
										color: white;
										margin-left: 6px;
										@media (max-width: 1200px) {
											margin-right: 4px;
											font-size: 12px;
										}
									}
								}
								.crd-like {
									background: white;
									margin-right: 10px;
									height: 20px;
									width: 20px;
									border-radius: 50%;
									padding: 6px;
									box-shadow: rgba(149, 157, 165, 0.2) 0px 8px
										24px;
								}
							}
						}
					}

					.crd-mddl {
						width: 50%;
						padding: 0 30px;
						h2 {
							font-size: 20px !important;
							font-weight: 600 !important;
							color: #565656;
							@media (max-width: 1300px) {
								margin-bottom: 12px;
							}
						}
						.adrss {
							display: flex;
							svg {
								margin-right: 8px;
								color: rgba($text, 0.5);
							}
							p {
								color: rgba($text, 0.5);
								font-size: 13px;
								font-weight: 400;
							}
						}
						p {
							font-size: 12px;
							line-height: 24px;
							font-weight: 500;
							color: rgba($text, 0.5);
						}
					}
					.crd-ryt {
						width: 25%;
						border-left: 1px solid rgba($text, 0.1);
						padding: 10px 20px;
						.prc {
							// background-color: rgba($text, 0.1);
							box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
							display: flex;
							justify-content: center;
							align-items: center;
							padding: 10px;
							border-radius: 10px;
							margin-bottom: 16px;
							@media (max-width: 1300px) {
								padding: 6px;
								margin-bottom: 10px;
							}

							h2 {
								font-size: 34px;
								font-weight: 500;
								color: $secondary;
								margin: 0;
								@media (max-width: 1300px) {
									font-size: 30px;
								}
								span {
									font-size: 16px;
									color: rgba($text, 0.5);
								}
							}
						}
						.btns-sec {
							display: block;
							margin-top: 16px;
							@media (max-width: 1300px) {
								margin-top: 10px;
							}

							.btn2 {
								width: 100%;
								margin-bottom: 10px;
								font-weight: 500;
							}
							.btn {
								width: 100%;
								background-color: #289f25;
								border-color: #289f25;
							}
						}
					}
				}
			}
		}
	}
}
.cpn_details {
	padding: 10px;
	.mn_cpn-dtls {
		padding: 10px;
		background: #ffff;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		border-radius: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		.cpn-details-ryt {
			width: 30%;
			.cpn_crd {
				width: 90%;
				.crd_btns {
					display: none;
				}
			}
		}
		.cpn-details-lft {
			width: 60%;

			border-radius: 0 0 10px 10px;

			div {
				h2 {
					padding: 10px;
					// background: rgba($text, 0.5);
					background: white;
					font-size: 28px;
					font-weight: 500;
					color: $text;
					border-radius: 10px 10px 0 0;
					border: 1px solid rgba($secondary, 0.3);
					user-select: none;
				}
			}
			.mn-dtls-lft {
				padding: 10px 30px;
				background: $white;
				border: 1px solid rgba($secondary, 0.3);
				.stppr-sec {
					padding: 40px 0;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					&::after {
						position: absolute;
						content: '';
						background: url('../../../public/static/images/bg-line.svg');
						width: 300px;
						height: 10px;
						top: 50%;
						bottom: 0;
						background-repeat: no-repeat;
						background-size: 100%;
						left: 190px;
						z-index: 1;
					}

					.stpr_inr {
						position: relative;
						background-color: $secondary;
						position: relative;
						width: 70px;
						height: 70px;
						margin: 0 40px;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						box-shadow: rgba(255, 156, 154, 0.14) 0px 0px 1px 14px;
						z-index: 2;

						h2 {
							font-size: 44px;
							font-weight: 700;
							background-color: transparent;
							color: white;
							border: none;
						}
					}
					.stpr_inr2 {
						position: relative;
						background-color: #e0e0e0;
						position: relative;
						width: 70px;
						height: 70px;
						margin: 0 40px;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						box-shadow: rgba(86, 86, 86, 0.1) 0px 0px 1px 14px;
						z-index: 2;

						h2 {
							font-size: 44px;
							font-weight: 700;
							color: rgba($text, 0.5);
							border: none;
							background-color: transparent;
						}
					}
				}
				.cpn-count {
					padding: 30px 0;
					h4 {
						font-weight: 500;
						font-size: 22px;
						color: rgba($text, 0.5) !important;
					}
					.no-of-cpns {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						padding-top: 30px;
						p {
							font-size: 14px;
							color: rgba($text, 0.5);
							margin-right: 10px;
						}
						.cpn-counter {
							display: flex;
							justify-content: space-between;
							align-items: center;
							width: 28%;
							// background: rgba($text, 0.1);
							box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
							padding: 10px;
							border-radius: 40px;
							margin-right: 10px;
							.count {
								background: $white;
								box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
								height: 20px;
								width: 20px;
								display: flex;
								justify-content: center;
								align-items: center;
								border-radius: 50%;
								padding: 10px;
								color: $secondary;
								cursor: pointer;
								&:hover {
									background: $secondary;
									color: $white;
									transition: ease-in 0.1s;
								}
								h5 {
									font-size: 30px;
									font-weight: 400;
									user-select: none;
								}
								// h2 {
								// 	color: $secondary;
								// 	background: transparent;
								// 	font-size: 32px;
								// 	padding: 0;
								// 	// width: 100%;
								// 	// height: 100%;
								// 	border-radius: 0;
								// 	// &:hover{
								// 	// 	color: $white;
								// 	// }
								// }
							}
							.count-value {
								h2 {
									padding: 2px;
									// background: rgba(86, 86, 86, 0.2);

									background: white;
									font-size: 28px;
									box-shadow: rgba(99, 99, 99, 0.2) 0px 2px
										8px 0px;
									font-weight: 500;
									color: rgba($text, 0.5);
									border-radius: 10px;
									width: 50px;
									display: flex;
									justify-content: center;
									align-items: center;
								}
							}
							.count-val {
								border-radius: 10px;
								background: rgba($text, 0.2);
								padding: 10px 20px;
								h2 {
									color: rgba($text, 0.5);
								}
							}
						}
					}
					.entr-id {
						padding-top: 20px;
						width: 50%;
						input {
							padding: 12px;
							border-radius: 10px;
							// background: rgba($text, 0.08);
							border: 1px solid rgba($text, 0.5);
							width: 80%;
							outline: none;
						}
					}
				}
				.slct-cpn-typ {
					padding: 10px 0;
					div {
						display: flex;
						justify-content: flex-start;
						// align-items: center;
						margin: 20px 0;
						input {
							margin-right: 10px;
							accent-color: $secondary;
						}
						p {
							font-size: 14px;
							color: rgba($text, 0.5);
						}
						label {
							span {
								color: $secondary !important;
							}
						}
					}
				}
				.cpn-dtl-btns {
					padding: 30px 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.btn {
						min-width: 90px;
						background-color: #289f25;
						border: #289f25;
					}
					.btn2 {
						min-width: 100px;
					}
				}
			}
		}
	}
}
.cpn_details .mn_cpn-dtls .cpn-details-lft .mn-dtls-lft .stppr-sec::after {
	@media (max-width: 1920px) {
		left: 300px;
	}
	@media (max-width: 1700px) {
		left: 240px;
	}
	@media (max-width: 1500px) {
		left: 190px;
	}
	@media (max-width: 1300px) {
		left: 150px;
	}
	@media (max-width: 1200px) {
		left: 122px;
	}
}
section.brws_cpns .mn_brws_cpns .cpn_to_sell .inr_to_sell {
	.inpt-fld-sec {
		p {
			@media (max-width: 1440px) {
				font-size: 14px;
				margin-bottom: 6px;
			}
		}
		input[type='text'] {
			@media (max-width: 1440px) {
				padding: 16px;
			}
		}
	}
	.btn-sec {
		@media (max-width: 1440px) {
			width: 28%;
		}
		@media (max-width: 1200px) {
			width: 32%;
		}
		div {
			@media (max-width: 1440px) {
				height: 30px;
			}
			figure {
				@media (max-width: 1440px) {
					width: 36px;
					height: 36px;
				}
			}
			p {
				@media (max-width: 1440px) {
					font-size: 18px;
				}
				@media (max-width: 1200px) {
					font-size: 16px;
				}
			}
			svg {
				@media (max-width: 1440px) {
					padding: 5px;
				}
			}
		}
	}
}
