@import '../../assets/theme/variable.scss';

.mn-ordr-sccs {
	.orderbnr {
		padding: 170px 0 70px;
		background-image: radial-gradient(
			circle farthest-side,
			rgba(251, 103, 103, 0.6) 0%,
			#fb6767 100%
		);

		figure {
			height: 200px;
			width: 200px;
			margin: 0 auto;
		}
	}
	.orderstatus {
		text-align: center;
		padding-top: 20px;
		h2 {
			font-size: 26px;
			line-height: 60px;
			color: #008000;
		}
		h3 {
			font-size: 18px;
			color: $text;
			padding-bottom: 10px;
			span {
				color: #0071eb;
			}
		}
		p {
			font-size: 16px;
			padding-bottom: 10px;
			font-weight: 500;
			color: $text;
		}
		.btndiv {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 20px;
			padding-bottom: 100px;
			width: 740px;
			margin: 0 AUTO;
			.btn-scts-wdth {
				// width: 100%;
				margin-right: 10px;
				&:last-child {
					margin-right: 0 !important;
				}
				.btn {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					figure {
						height: 16px;
						width: 16px;
						margin-right: 10px;
					}
				}
				.btn2 {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					figure {
						height: 16px;
						width: 16px;
						margin-right: 10px;
					}
				}
				p {
					font-size: 12px;
					text-align: left;
					padding: 0;
				}
			}
		}
	}
}
