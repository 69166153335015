@import '../../assets/theme/variable.scss';

section.sell_nw_stpr {
	padding: 70px 0 40px;
	.mn_stpr {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-wrap: wrap;
		.sell_nw_stps {
			width: 15%;
			padding: 20px 10px;
			background: $secondary;
			border-radius: 16px;
			box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
			cursor: pointer;
			.inr_stps {
				background: url('../../../public//static/images/stepper-img.svg');
				height: 40px;
				width: 95%;
				background-repeat: no-repeat;
				display: flex;
				justify-content: right;
				background-size: contain;
				align-items: center;
				margin-left: auto;

				h4 {
					color: rgba($text, 0.5);
					font-size: 18px;
					font-weight: 500;
				}
				h2 {
					color: rgba($text, 0.5);
					margin-right: 10px;
					font-size: 30px;
				}
			}
			.stp_nm {
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				h3 {
					text-align: center;
					font-size: 20px;
					line-height: 26px;
					padding-top: 6px;
					color: white;
					font-weight: 600;
				}
			}
		}
		.sell_nw_stps2 {
			width: 15%;
			padding: 20px 10px;
			background: $white;
			border-radius: 16px;
			box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.09);
			cursor: pointer;
			.inr_stps {
				background: url('../../../public//static/images/stepper-img.svg');
				height: 40px;
				width: 95%;
				background-repeat: no-repeat;
				display: flex;
				justify-content: right;
				background-size: contain;
				align-items: center;
				margin-left: auto;
				filter: invert(0%) sepia(10%) saturate(84%) hue-rotate(207deg)
					brightness(110%) contrast(64%);

				h4 {
					color: rgba($text, 0.5);
					font-size: 18px;
					font-weight: 500;
				}
				h2 {
					color: rgba($text, 0.5);
					margin-right: 10px;
					font-size: 30px;
				}
			}
			.stp_nm {
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				h3 {
					text-align: center;
					font-size: 20px;
					line-height: 26px;
					padding-top: 6px;
					color: $headings;
					font-weight: 600;
				}
			}
		}
	}
}
.cpn_prc {
	padding: 10px;
	.mn_cpn-dtls {
		padding: 10px;
		background: #ffff;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		border-radius: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		margin-bottom: 50px;
		.cpn-details-ryt {
			width: 30%;
			.cpn_crd {
				width: 90%;
				.crd_btns {
					display: none;
				}
			}
			.cpn-sell-tips {
				width: 84%;
				border: 1px solid rgba($text, 0.1);
				background: $white;
				padding: 10px 20px 70px;
				margin-top: 20px;
				border-radius: 14px;
				h4 {
					font-size: 20px;
					font-weight: 400;
					color: $text;
					padding: 20px 0;
				}
				ul {
					padding-left: 16px;
					padding-top: 20px;
					li {
						list-style: disc;
						padding: 10px 0;
						p {
							font-size: 14px;
							color: rgba($text, 0.5);
						}
						&::marker {
							font-size: 14px;
							color: rgba($text, 0.5);
						}
					}
				}
			}
		}
		.cpn-details-lft {
			width: 60%;
			border-radius: 10px;
			// border: 1px solid rgba($secondary, 0.3);
			// border-radius: 0 0 10px 10px;
			div {
				h2 {
					padding: 10px;
					// background: rgba($text, 0.5);
					background: white;
					border: 1px solid rgba($secondary, 0.3);
					font-size: 28px;
					font-weight: 500;
					color: $text;
					border-radius: 10px 10px 0 0;
				}
			}
			.mn-dtls-lft {
				padding: 10px 30px;
				background: $white;
				border: 1px solid rgba($secondary, 0.3);
				.stppr-sec {
					padding: 30px 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					div {
						h4 {
							font-size: 22px;
							font-weight: 400;
							color: rgba($text, 0.5);
						}
						h2 {
							padding: 10px 0 0 0;
							background: transparent;
							font-size: 22px;
							border: none;
							font-weight: 600;
							color: $secondary;
						}
					}
				}
				.min-prc {
					padding: 30px 0;
					h4 {
						font-size: 22px;
						font-weight: 400;
						color: rgba($text, 0.5);
					}
					.min-prc-inpt {
						display: flex;
						padding: 10px 0;
						justify-content: flex-start;
						align-items: baseline;
						input {
							width: 5%;
							padding: 16px;
							// background: rgba($text, 0.1);
							border: 1px solid $text;
							// border: none;
							border-radius: 10px;
							margin-right: 10px;
						}
						p {
							font-size: 14px;
							color: rgba($text, 0.5);
						}
					}
					.sale-end {
						padding: 10px 0;
						.end-dt-optns {
							display: flex;
							justify-content: flex-start;
							align-items: end;
							.end-dt {
								padding-top: 10px;
								.end-dt-dta {
									display: flex;
									justify-content: flex-start;
									align-items: center;
									margin-bottom: 10px;

									input {
										margin-top: 0;
										margin-right: 10px;
										accent-color: #fe6e6b;
									}
									p {
										color: rgba($text, 0.5);
										font-size: 14px;
									}
								}
							}
							.clndr-sec {
								display: flex;
								justify-content: space-between;
								align-items: center;
								margin-left: 40px;
								margin-bottom: 10px;
								input[type='date'] {
									// background: rgba($text, 0.1);
									border: 1px solid $text;
									padding: 10px 6px;
									border-radius: 6px;
									width: 150px;
								}
								figure {
									height: 40px;
									width: 70px;
								}
							}
						}
					}
				}
				.cpn-count {
					padding: 30px 0;
					h4 {
						font-weight: 500;
						font-size: 22px;
						color: rgba($text, 0.5) !important;
					}
					.no-of-cpns {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						padding-top: 30px;
						p {
							font-size: 14px;
							color: rgba($text, 0.5);
							margin-right: 10px;
						}
						.cpn-counter {
							display: flex;
							justify-content: space-between;
							align-items: center;
							width: 28%;
							background: rgba($text, 0.1);
							padding: 10px;
							border-radius: 40px;
							margin-right: 10px;
							div {
								background: $white;
								height: 20px;
								width: 20px;
								display: flex;
								justify-content: center;
								align-items: center;
								border-radius: 50%;
								padding: 10px;
								h2 {
									color: $secondary;
									background: transparent;
									font-size: 22px;
									padding: 0;
								}
							}
							.count-val {
								border-radius: 10px;
								background: rgba($text, 0.2);
								padding: 10px 20px;
								h2 {
									color: rgba($text, 0.5);
								}
							}
						}
					}
					.entr-id {
						padding-top: 20px;
						width: 50%;
						input {
							padding: 12px;
							border-radius: 10px;
							background: rgba($text, 0.08);
							border: 1px solid rgba($text, 0.08);
							width: 80%;
						}
					}
				}
				.slct-cpn-typ {
					padding: 10px 0;
					div {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						margin: 20px 0;
						input {
							margin-right: 10px;
							color: $secondary;
						}
						p {
							font-size: 14px;
							color: rgba($text, 0.5);
						}
					}
				}
				.cpn-dtl-btns {
					padding: 30px 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.btn {
						min-width: 90px;
						background-color: #289f25;
						border: #289f25;
					}
					.btn2 {
						min-width: 100px;
					}
				}
			}
		}
	}
}
.summary-sec {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 70px;
	background: #ffffff;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
	border-radius: 10px;

	.summary-left {
		width: 49%;
		background: #fe6e6b;
		text-align: center;
		padding: 280px 0;
		border-radius: 10px;
		figure {
			height: 360px;
		}
	}
	.summry-ryt {
		width: 40%;
		margin: 0 auto;
		h2 {
			text-align: center;
			font-size: 38px;
			color: #fe6e6b;
			margin-bottom: 20px;
			font-weight: 600;
		}
		h4 {
			font-size: 24px;
			text-align: center;
			color: #253d4e;
			font-weight: 500;
		}
		hr {
			margin: 40px 0;
			background: #5a5f68;
			opacity: 0.1;
		}
		h6 {
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 16px;
			color: #253d4e;
		}
		.scl-icns-lst {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			.scl-icns {
				border: 1px solid rgba(86, 86, 86, 0.1);
				box-shadow: 0 1px 1px -2px rgba(50, 50, 93, 0.25),
					0 3px 7px -3px rgba(0, 0, 0, 0.3);
				border-radius: 10px;
				margin-right: 24px;
				width: 72px;
				height: 44px;
				display: flex;
				align-items: center;
				justify-content: center;

				cursor: pointer;

				figure {
					height: 25px;

					img {
						filter: brightness(0.2);
					}
				}
				&:hover img {
					filter: none !important;
				}
				&:hover {
					background: rgba(254, 110, 107, 0.1);
					border: 1px solid rgba(254, 110, 107, 0.3);
				}
			}
		}
		.drb_link {
			background: #ffffff;
			box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
			border-radius: 10px;
			padding: 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #d9d9d9;
			margin-bottom: 30px;
			p {
				color: #8f8f8f;
			}
			.pink_text {
				color: #fe6e6b;
				font-weight: 600;
				cursor: pointer;
			}
		}
		.usr-pnl-btn {
			display: flex;
			justify-content: flex-end;
		}
	}
}

.prsnl-info {
	padding: 10px;
	.mn_cpn-dtls {
		padding: 10px;
		background: #ffff;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		border-radius: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		margin-bottom: 50px;
		.cpn-details-ryt {
			width: 30%;
			.cpn_crd {
				width: 90%;
				.crd_btns {
					display: none;
				}
			}
			.dsclmr {
				font-size: 14px;
				padding-top: 10px;
				line-height: 26px;
				font-weight: 400;
				width: 90%;
				color: #ff0000;
			}
		}
		.cpn-details-lft {
			width: 60%;
			border-radius: 10px;
			border-radius: 0 0 10px 10px;
			div {
				h2 {
					padding: 10px;
					// background: rgba($text, 0.5);
					background: white;
					border: 1px solid rgba($secondary, 0.3);
					font-size: 28px;
					font-weight: 500;
					color: $text;
					border-radius: 10px 10px 0 0;
				}
			}
			.mn-dtls-lft {
				padding: 10px 30px;
				background: $white;
				border: 1px solid rgba($secondary, 0.3);
				.signUp-screen {
					text-align: center;
					max-width: 520px;
					margin: 0 auto;

					.grey_text {
						color: $text;
					}

					h3 {
						font-size: 28px;
						margin-bottom: 15px;
						font-weight: 600;
						padding-top: 20px;
					}
					p {
						font-size: 14px;
						color: $text;
						margin-bottom: 20px;

						span {
							color: $secondary;
							cursor: pointer;
						}
					}
				}
				.scl-login {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 10px;

					figure {
						width: 44%;
						cursor: pointer;
					}
				}
				.or_opt {
					position: relative;
					margin: 50px 0;

					p {
						position: absolute;
						background: #f7f7f7;
						width: 50px;
						height: 50px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						top: 0;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
				.sign_bx {
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
					.names-sec {
						display: flex;
						justify-content: space-between;
						// align-items: center;
						width: 100%;
						.txt-fld-auth {
							width: 49%;
							margin-bottom: 10px;
						}
					}
					.txt-fld-auth {
						p {
							color: red;
							font-size: 12px;
							margin: -6px 0 10px;
						}
						& > div {
							width: 100%;
							margin-bottom: 10px;
							border-bottom: 1px solid rgba(86, 86, 86, 0.2);
							background: rgba(86, 86, 86, 0.04);
							border-radius: 6px;
							height: 50px;
							input {
								font-size: 14px;
								&::placeholder {
									font-size: 14px;
								}
							}
							fieldset {
								display: none;
							}
						}
					}
				}
				.chckbx-sec {
					display: flex;
					align-items: start;
					margin: 20px 0;
					span {
						padding: 0;
						color: $secondary;
					}
					svg {
						font-size: 18px !important;
					}
					p {
						font-size: 12px;
						margin-left: 4px;
						text-align: left;
						margin-bottom: 0;

						span {
							color: $secondary;
						}
					}
				}
				.btn {
					width: 100%;
					margin-bottom: 20px;
				}
				.btm-auth {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 40px;
					padding: 10px 20px;
					position: absolute;
					bottom: 0;
					width: 100%;

					h5 {
						font-size: 14px;
						font-weight: 400;
					}
					h6 {
						font-size: 14px;
						font-weight: 400;
						color: $secondary;
						margin-bottom: 10px !important;
					}
				}
			}
			.auth-ryt {
				width: 49%;

				figure {
					position: absolute;
					bottom: 0;
				}
			}
		}
		.frgt-sec {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.frgt_pass {
				text-decoration: underline;
				cursor: pointer;
				color: $secondary !important;
			}
		}
	}
}

// responsive/
section.sell_nw_stpr .mn_stpr .sell_nw_stps .inr_stps {
	@media (max-width: 1920px) {
		height: 58px;
	}
	@media (max-width: 1700px) {
		height: 52px;
	}
	@media (max-width: 1500px) {
		height: 44px;
	}
	@media (max-width: 1200px) {
		height: 36px;
	}
}
section.sell_nw_stpr .mn_stpr .sell_nw_stps2 .inr_stps {
	@media (max-width: 1920px) {
		height: 58px;
	}
	@media (max-width: 1700px) {
		height: 52px;
	}
	@media (max-width: 1500px) {
		height: 44px;
	}
	@media (max-width: 1200px) {
		height: 36px;
	}
}

.summary-sec .summry-ryt {
	h2 {
		@media (max-width: 1200px) {
			font-size: 36px;
		}
	}
	h4 {
		@media (max-width: 1200px) {
			font-size: 22px;
		}
	}
	h6 {
		@media (max-width: 1200px) {
			font-size: 16px;
		}
	}
	.scl-icns-lst .scl-icns {
		@media (max-width: 1300px) {
			width: 66px;
			height: 42px;
		}
		figure {
			@media (max-width: 1300px) {
				height: 22px;
			}
		}
	}
	.drb_link p {
		@media (max-width: 1300px) {
			font-size: 13px;
		}
	}
}
