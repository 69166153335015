@import '../../assets/theme/variable.scss';
.pl {
	padding-left: 30px;
}
.mn-preDtls {
	.pre-details {
		padding-top: 80px;
		.cpn-dtls {
			padding-top: 30px;
			display: flex;
			justify-content: space-between;
			align-items: stretch;

			.dtls-lft {
				width: 50%;
				h2 {
					font-size: 26px;
					color: $headings;
					@media (max-width: 1300px) {
						font-size: 24px;
					}
					@media (max-width: 1200px) {
						font-size: 22px;
					}
				}
				.view-sec {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 10px 0;
					.eye-icn {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						figure {
							padding: 4px 10px;
							height: 20px;
							background: $secondary;
							width: 20px;
							border-radius: 6px;
						}
						p {
							font-size: 13px;
							color: rgba($text, 0.5);
							font-weight: 500;
							margin-left: 10px;
						}
					}
					.lctn-sec {
						display: flex;
						justify-content: flex-end;
						align-items: stretch;
						width: 50%;
						figure {
							height: 24px;
							width: 17px;
						}
						p {
							color: rgba($text, 0.8);
							margin-left: 6px;
							font-size: 13px;
							font-weight: 500;
							span {
								color: rgba($text, 0.5);
								font-weight: 500;
							}
						}
					}
				}
				.crd-des {
					padding: 10px 0 30px;
					font-size: 14px;
					line-height: 26px;
					color: rgba($text, 0.8);
					@media (max-width: 1300px) {
						padding: 8px 0 24px;
						font-size: 13px;
					}
					@media (max-width: 1200px) {
						padding: 8px 0 20px;
						font-size: 12px;
					}
				}
				.bk-aptmnt {
					// background: rgba($text, 0.1);
					background: white !important;
					border: 1px solid rgba($secondary, 0.3);
					padding: 10px;
					border-radius: 10px;
					margin-top: 0px;
					margin-bottom: 20px;
					@media (max-width: 1200px) {
						margin-bottom: 40px;
						margin-top: 25px;
					}

					h2 {
						padding-left: 20px;
						font-size: 26;
						padding-bottom: 10px;
						@media (max-width: 1200px) {
							padding: 10px 30px 12px;
						}
					}
				}
				.srvcs {
					padding: 10px;
					// background: rgba($text, 0.1);
					// box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
					box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
					border: 1px solid rgba($secondary, 0.3);
					border-radius: 6px;
					margin-bottom: 30px;

					h3 {
						font-size: 20px;
						text-align: center;
						font-weight: 600;
						color: #333333;
						padding-bottom: 10px;
						@media (max-width: 1200px) {
							font-size: 16px;
							padding-bottom: 7px;
						}
					}
					.all-srvcs {
						border-radius: 6px;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;

						.srvc-lst {
							display: flex;
							background: #fff;
							padding: 6px 12px 6px 6px;
							width: 48%;
							justify-content: space-between;
							align-items: center;
							margin-top: 10px;
							border-radius: 6px;
							box-sizing: border-box;
							cursor: pointer;
							box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px
								0px;
							figure {
								width: 54px;
								height: 44px;
								@media (max-width: 1200px) {
									width: 48px;
									height: 42px;
								}
							}
							div {
								font-size: 12px;
								color: $headings;
								@media (max-width: 1200px) {
									font-size: 11px;
								}
							}
							.ryt-arr {
								background: $secondary;
								height: 20px;
								width: 20px;
								display: flex;
								justify-content: center;
								align-items: center;
								border-radius: 50%;
								svg {
									font-size: 12px;
									color: $white;
								}
							}
						}
					}
				}
				.qty {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 20px 0;
					@media (max-width: 1300px) {
						margin: 14px 0;
					}

					.cpn-counter {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 30%;
						box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
						border: 1px solid rgba($secondary, 0.3);
						// background: rgba($text, 0.1);
						padding: 8px;
						border-radius: 40px;
						@media (max-width: 1300px) {
							padding: 6px;
						}
						@media (max-width: 1200px) {
							width: 32%;
						}

						.incre-decre {
							background: $white;
							height: 20px;
							width: 20px;
							box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
							display: flex;
							justify-content: center;
							align-items: center;
							border-radius: 50%;
							padding: 10px;
							color: $secondary;
							cursor: pointer;
							@media (max-width: 1200px) {
								height: 16px;
								width: 16px;
							}
							&:hover {
								background: $secondary;
								color: $white !important;
								transition: ease-in 0.1s;
							}
							h5 {
								font-size: 30px;
								font-weight: 400;
								user-select: none;
							}
							// background: $white;
							// padding: 10px;
							// border-radius: 50%;
							// height: 20px;
							// width: 20px;
							// display: flex;
							// justify-content: center;
							// align-items: center;
							// cursor: pointer;

							// h2 {
							// 	font-size: 30px;
							// 	font-weight: 300;
							// 	color: $secondary;
							// }
						}
						.count-val {
							// background: rgba(197, 197, 197, 0.4);
							// box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px
							// 0px;
							border: 1px solid rgba($secondary, 0.3);
							height: 40px;
							width: 76px;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 6px;
							@media (max-width: 1200px) {
								height: 38px;
								width: 72px;
							}

							h2 {
								font-size: 14px;
								font-weight: 400;
								color: rgba($text, 0.5);
								span {
									font-size: 20px;
								}
							}
						}
					}
					.itm-prc {
						font-size: 28px;
						color: $secondary;
						@media (max-width: 1300px) {
							font-size: 26px;
						}
						@media (max-width: 1200px) {
							font-size: 24px;
						}

						span {
							font-size: 18px;
							font-weight: 400;
							color: rgba($text, 0.5);
						}
					}
				}
				.pesale-end {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					figure {
						height: 20px;
						width: 14px;
					}
					p {
						padding: 0 !important;
						margin-left: 10px;
						font-size: 14px;
						font-weight: 500;
						color: $secondary;
						@media (max-width: 1200px) {
							font-size: 12px;
						}

						span {
							font-weight: 400;
						}
					}
				}
				.btns-section {
					display: flex;
					justify-content: space-between;
					margin-top: 20px;
					.btn {
						width: 60%;
					}
					.btn2 {
						width: 30%;
					}
				}
			}
			.dtls-ryt {
				width: 45%;
				.presale-sldr {
					position: relative;
					.slick-arrow.slick-prev {
						position: absolute;
						background: white;
						color: $secondary;
						left: 20px;
						z-index: 1;
						top: 380px;
						box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
						padding: 10px;
						border-radius: 50%;
						@media (max-width: 1200px) {
							padding: 6px;
						}
					}
					.slick-arrow.slick-next {
						position: absolute;
						background: white;
						color: $secondary;
						right: 20px;
						top: 380px;
						padding: 10px;
						border-radius: 50%;
						box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
						@media (max-width: 1200px) {
							padding: 6px;
						}
					}
					.sldr-img-sec {
						position: relative;
						.sldr-img {
							height: 420px;

							img {
								object-fit: cover;
								width: 100%;
								border-radius: 10px;
							}
						}
						.shr-btn {
							position: absolute;
							height: 40px;
							width: 40px;
							top: 10px;
							right: 10px;
							cursor: pointer;
						}
					}
				}
				.scl-icns-sec {
					display: flex;
					position: absolute;
					top: 60px;
					right: 4px;
					justify-content: space-between;
					width: 18%;
					background: white;
					padding: 10px;
					border-radius: 10px;

					figure {
						background: rgba($text, 0.1);
						padding: 6px 8px;
						border-radius: 4px;
						margin-right: 4px;
						&:last-child {
							position: relative;
							&::before {
								position: absolute;
								content: '';
								height: 10px;
								width: 10px;
								background: #fff;
								transform: rotate(45deg);
								top: -15px;
								right: 6px;
							}
						}
					}
				}
				.btmsldr-imgs {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 20px 0;
					figure {
						width: 28%;
						height: 155px;
						@media (max-width: 1300px) {
							height: 136px;
						}
						@media (max-width: 1200px) {
							height: 128px;
						}

						img {
							object-fit: cover;
							border: 1px solid rgba($text, 0.2);
							border-radius: 10px;
						}
					}
				}
				.pymnt-mthds {
					display: flex;
					justify-content: space-between;
					align-items: center;
					background: rgba($primary, 0.5);
					border-radius: 10px;
					figure {
						height: 90px;
						width: 20%;
					}
				}
			}
		}
		.pre-hdng {
			font-size: 28px;
			color: $text;
			padding-bottom: 10px;
			@media (max-width: 1300px) {
				font-size: 26px;
			}
		}
		.plcs {
			padding: 20px 0 0 30px !important;
		}

		.pre-pt {
			padding-top: 40px;

			h3 {
				font-size: 18px;
				font-weight: 500;
				padding: 10px 30px;
				color: #565656;
				@media (max-width: 1300px) {
					font-size: 17px;
				}
			}
			h6 {
				font-size: 14px;
				font-weight: 500;
				color: #5c5f67;
				padding-bottom: 10px;
				@media (max-width: 1300px) {
					font-size: 13px;
				}
			}
			p {
				color: rgba($text, 0.75);
				font-size: 14px;
				line-height: 26px;
				font-weight: 400;
				padding-bottom: 10px;
				@media (max-width: 1300px) {
					font-size: 13px;
					line-height: 25px;
				}
			}
			ul {
				padding-left: 16px;
				::marker {
					color: $secondary;
				}
				li {
					list-style: disc;
					font-size: 14px;
					line-height: 26px;
					font-weight: 400;
					color: rgba($text, 0.75);
					@media (max-width: 1300px) {
						font-size: 13px;
					}
				}
			}
		}
		.mr-offrs {
			h2 {
				span {
					font-size: 14px;
					font-weight: 400;
					color: $secondary;
				}
			}
			.preOfrs {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				padding-top: 20px;
				li {
					// background: rgba($text, 0.1);
					// box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
					// border: 1px solid rgba($secondary, 0.3);
					padding: 10px 10px 10px 20px;
					box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 46%;
					margin-top: 10px;
					border-radius: 10px;
					.ofr-txt {
						width: 64%;
						position: relative;
						padding-left: 16px;
						::before {
							position: absolute;
							content: '';
							height: 6px;
							width: 6px;
							top: 10px;
							left: 0;
							background: $text;
							border-radius: 50%;
						}
					}
					.ofr-val {
						width: 16%;
						border-left: 2px solid rgba($secondary, 0.3);
						padding-left: 14px;
						p {
							padding-bottom: 0;
						}
						h6 {
							font-size: 16px;
							font-weight: 700;
							@media (max-width: 1300px) {
								font-size: 15px;
							}
						}
					}
				}
			}
		}
		.g-map {
			padding-top: 60px;
		}
	}
	.dscount_srvc {
		background: transparent;
		margin-top: 0;
	}
	.section2 {
		.max-minPrc {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.prcs {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 49%;
				p {
					font-size: 14px;
					color: $text;
				}
				h2 {
					font-size: 20px;
					font-weight: 500;
					margin: 10px;
					color: $secondary;
					// background: rgba($text, 0.1);
					box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
					padding: 2px 6px;
					border-radius: 6px;
					@media (max-width: 1200px) {
						font-size: 18px;
					}
				}
			}
			.prcs2 {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 49%;
				p {
					font-size: 14px;
					color: $text;
				}
				h2 {
					font-size: 20px;
					font-weight: 500;
					margin: 10px;
					color: $secondary;
					// background: rgba($text, 0.1);
					box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
					padding: 2px 6px;
					border-radius: 6px;
				}
			}
		}
		.offrd-prc {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			padding-top: 20px;
			@media (max-width: 1200px) {
				padding-top: 10px;
			}

			p {
				font-size: 13px;
				width: 50%;
				color: $text;
			}
			.ofrdPrc-ryt {
				width: 40%;
				.Ofrd-Prc-Top {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					// background: rgba($text, 0.1);
					box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
					border-radius: 6px;
					padding: 4px;
					input[type='text'] {
						width: 100px;
						padding: 8px;
						border-radius: 4px;
						outline: none;
						border: 1px solid rgba($secondary, 0.3);
						&::placeholder {
							font-size: 17px;
							font-weight: 700;
						}
					}
					// h4 {
					// 	font-size: 20px;
					// 	font-weight: 500;
					// 	width: 70%;
					// 	background: rgba($text, 0.2);
					// 	color: rgba($text, 0.5);
					// 	padding: 4px 6px 2px 30px;
					// 	border-radius: 6px;
					// 	@media (max-width: 1366px) {
					// 		width: 76%;
					// 	}
					// 	@media (max-width: 1300px) {
					// 		width: 88%;
					// 	}
					// 	@media (max-width: 1200px) {
					// 		font-size: 15px;
					// 	}
					// }
					p {
						color: $text;
						text-align: center;
					}
				}
				p {
					color: #ff0000;
					width: 100%;
					font-size: 12px;
					@media (max-width: 1200px) {
						font-size: 11px;
					}
				}
			}
		}
	}
}

// modals
.gift {
	.crossbar {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $secondary;
		padding: 16px 0;
		border-radius: 14px 14px 0 0;
		position: relative;
		h5 {
			font-size: 16px;
			color: white;
		}
		div {
			position: absolute;
			right: 10px;
			figure {
				width: 30px;
				height: 30px;
				cursor: pointer;
			}
		}
	}
	.logdata {
		figure {
			width: 130px;
			height: 130px;
			margin: auto;
		}
		.inputdiv {
			padding: 30px 20px;
			.gift-inpt {
				width: 100%;
				& > div {
					margin-right: 14px;
					background: rgba(86, 86, 86, 0.02) !important;
					border-bottom: 1px solid rgba(86, 86, 86, 0.2) !important;
					border-radius: 5px !important;
					margin-bottom: 10px !important;
					height: 50px;
				}
				p {
					color: red;
					font-size: 14px;
					margin-top: -6px;
					margin-bottom: 10px;
					margin-left: 0 !important;
				}
			}
			.w_100 {
				width: 100% !important;
			}
			.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
				background: none !important;
				border: none !important;
			}
			.css-2y464i-MuiInputBase-root-MuiFilledInput-root:before {
				border-bottom: none !important;
			}
		}
		.btndiv {
			width: 100%;
			display: flex;
			justify-content: center;
			button {
				width: 50%;
			}
		}
	}
}

.plc-bid {
	.top-sec {
		display: flex;
		justify-content: flex-end;
		background: rgba($text, 0.5);
		padding: 10px;
		figure {
			height: 30px;
			width: 30px;
			cursor: pointer;
		}
	}
	.mdl-sec {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 40px;
		.img-sec {
			width: 30%;
			figure {
				height: 170px;
				width: 100%;
			}
		}
		.modl-info {
			width: 65%;
			h2 {
				font-size: 18px;
				font-weight: 700;
				color: $text;
				padding-bottom: 8px;
			}

			p {
				font-size: 13px;
				line-height: 26px;
				color: rgba($text, 0.8);
				.red_text {
					color: #ff0000;
				}
				.green-text {
					color: #0cba3d;
				}
			}
		}
	}
	.modl-btns {
		padding: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.btn2 {
			padding: 12px 40px;
		}
		.btn {
			padding: 12px 40px;
		}
	}
}
