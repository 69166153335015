@import '../../assets/theme/variable.scss';
.privacybnr {
	background: #fe6e6b;
	padding: 60px 0;
	position: relative;
	&:before {
		position: absolute;
		content: '';
		background: url('../../../public/static/images/logo-bg.svg');
		background-size: contain;
		background-repeat: no-repeat;
		height: 500px;
		width: 300px;
		top: 700px;
	}

	.ppbanner {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		.ppleft {
			h1 {
				font-size: 36px;
				font-weight: 500;
				margin-bottom: 20px;
				padding-top: 30px;
				color: #fff;
				@media (max-width: 1200px) {
					font-size: 32px;
					padding-left: 20px;
				}
				// @media (max-width: 991px) {
				// 	font-size: 46px;
				// }
			}
		}
		.ppright {
			figure {
				height: 400px;
				width: 100%;
				opacity: 0.8;
			}
		}
	}
}
.policycontent {
	background: #ffffff;
	box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.06);
	border-radius: 10px;
	padding: 30px;
	margin-top: -224px;
	z-index: 1;
	position: relative;
	margin-bottom: 50px;

	h2 {
		font-size: 22px;
		border-bottom: 1px solid rgba(86, 86, 86, 0.2);
		color: #565656;
		margin-bottom: 20px;
		@media (max-width: 1200px) {
			font-size: 20px;
		}
	}
	h5 {
		font-size: 16px;
		color: #565656;
		margin: 10px 0;
	}
	h6 {
		font-size: 13px;
		color: rgba(86, 86, 86, 0.7);
		font-weight: 500;
		margin-bottom: 5px;
	}
}
