@import '../../assets/theme/variable.scss';

.myOrder {
	.ormain {
		.tbldv {
			margin: 20px 0;
			& > div {
				&::-webkit-scrollbar {
					height: 3px;
				}
			}
			table {
				width: 100%;
				background: #ffffff;
				border-radius: 10px;
				thead {
					tr {
						background: transparent !important;
					}
				}
				tr {
					&:nth-of-type(odd) {
						background: #f7f7f7;
					}
				}
				th {
					color: #253d4e;
					font-size: 14px;
					font-weight: 600;
					text-align: center;
					padding: 14px 0;
					border-bottom: none !important;
				}
				td {
					color: $text;
					font-size: 14px;
					font-weight: 400;
					text-align: center;
					padding: 14px 0;
					border-bottom: none !important;
					h4 {
						font-size: 14px;
						font-weight: 400;
					}
					figure {
						height: 14px;
						width: 18px;
						margin: 0 auto;
					}
				}

				.status h4 {
					color: #00baf2;
					background: #c2f1fe;
					padding: 8px 2px;
					box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
					border-radius: 6px;
					width: fit-content;
					min-width: 70%;
					margin: 0 auto;
					font-size: 13px;
					font-weight: bold;
				}
				.status2 h4 {
					color: #3bb77e;
					background: #bcefd8;
					padding: 8px 2px;
					border-radius: 6px;
					width: fit-content;
					min-width: 70%;
					box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
					margin: 0 auto;
					font-size: 13px;
					font-weight: bold;
				}
			}
		}
		.orderMain {
			background: linear-gradient(
				74.43deg,
				#ffffff 2.15%,
				#ffffff 98.88%
			);
			border: 1px solid rgba(86, 86, 86, 0.1);
			border-radius: 20px;
			padding: 14px 14px 0;

			.productt {
				background: #ffffff;

				box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

				border-radius: 20px;
				padding: 20px;
				margin-bottom: 20px;

				.prtop {
					display: flex;
					justify-content: space-between;

					.prleft {
						width: 80%;
						display: flex;
						align-items: center;

						.plleft {
							padding-right: 20px;

							figure {
								width: 150px;
								height: 150px;

								img {
									height: 100%;
									object-fit: cover;
									border-radius: 10px;
								}
							}
						}

						.plright {
							h3 {
								font-weight: normal;
							}

							h4 {
								color: $secondary;
								font-size: 25px;
							}

							.bar {
								display: flex;
								align-items: center;

								h5 {
									font-weight: normal;
									font-size: 12px;
									padding-left: 10px;
								}
							}

							.services {
								color: $secondary;
								font-size: 10px;
							}
						}
					}
				}

				.prbtm {
					border-top: 1px solid #5656566e;
					margin: 15px 0;
					padding-top: 10px;

					ul {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;

						li {
							width: 48%;
							display: flex;
							align-items: flex-start;
							padding-top: 18px;
							.cLeft {
								line-height: 0;
								svg {
									font-size: 20px;
								}
							}
							.chckbx-clr {
								color: $secondary;
								padding: 0;
								margin-right: 8px;
							}
						}
					}
				}
			}
		}
	}

	.trackDiv {
		background: linear-gradient(74.43deg, #ffffff 2.15%, #ffffff 98.88%);
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 20px;
		padding: 60px 20px;
		margin-top: 20px;

		ul {
			display: flex;
			justify-content: space-between;

			li {
				width: 24%;
				text-align: center;
				position: relative;
				&:last-child .stepfig2::after {
					display: none;
				}
				&:last-child .stepfig::after {
					display: none;
				}

				&:last-child {
					&::after {
						content: unset;
					}
				}
				&.active {
					h3 {
						color: #000;
					}
				}

				h3 {
					color: $para;
					font-size: 15px;
					padding-bottom: 20px;
				}

				.stepfig {
					padding: 20px 0;
					&::after {
						content: '';
						position: absolute;
						background: $secondary;
						border-radius: 10px;
						height: 11px;
						width: 100%;
						top: 38%;
						left: 191px;
						@media (max-width: 1920px) {
							left: 162px;
						}
						@media (max-width: 1700px) {
							left: 137px;
						}
						@media (max-width: 1500px) {
							left: 133px;
						}
						@media (max-width: 1440px) {
							top: 105px;
							left: 120px;
						}
						@media (max-width: 1200px) {
							left: 108px;
						}
					}

					figure {
						width: 90px;
						height: 90px;
						box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25),
							0px 0px 0px 17px rgba(255, 156, 154, 0.14);
						border-radius: 50px;
						margin: auto;
						background: $secondary;
						display: flex;
						justify-content: center;
						align-items: center;
						img {
							width: 40%;
							height: 40%;
						}

						&.default {
							background: #e0e0e0;
							box-shadow: 0px 0px 0px 17px
								rgba(224, 224, 224, 0.14);
						}
					}
				}
				.stepfig2 {
					padding: 20px 0;

					&::after {
						content: '';
						position: absolute;
						background: #e0e0e0;
						border-radius: 10px;
						height: 11px;
						width: 100%;
						top: 38%;
						left: 191px;
						@media (max-width: 1920px) {
							left: 162px;
						}

						@media (max-width: 1700px) {
							left: 139px;
						}
						@media (max-width: 1500px) {
							left: 130px;
						}
						@media (max-width: 1440px) {
							top: 105px;
						}
						@media (max-width: 1204px) {
							left: 120px;
						}
						@media (max-width: 1100px) {
							left: 90px;
						}
					}

					figure {
						width: 90px;
						height: 90px;
						box-shadow: rgba(224, 224, 224, 0.14) 0px 0px 1px 14px;
						border-radius: 50px;
						margin: auto;
						background: #e0e0e0;
						display: flex;
						justify-content: center;
						align-items: center;
						img {
							width: 40%;
							height: 40%;
						}

						&.default {
							background: #e0e0e0;
							box-shadow: 0px 0px 0px 17px
								rgba(224, 224, 224, 0.14);
						}
					}
				}

				h5 {
					color: $para;
					font-size: 14px;
					padding-top: 20px;
					padding-bottom: 6px;
				}

				p {
					font-weight: normal;
					color: $para;
					font-size: 13px;
					line-height: 25px;
				}
			}
		}
	}
}

//wishlist

.wishlist {
	padding-top: 20px;
	.mn-chck-avlbl {
		width: 90%;
	}
	.cardsdiv {
		display: flex;
		justify-content: space-between;
		.wishOrder {
			border: 0.866296px solid rgba(86, 86, 86, 0.06);
			border-radius: 12.9944px;
			background: #ffffff;
			width: 29%;
			margin-right: 20px;
			margin-bottom: 20px;
			padding: 0;
			.cpn_crd {
				width: 100%;
				figure {
					height: auto;
					img {
						height: 100%;
						object-fit: cover;
					}
				}
				h2 {
					color: $text;
					font-size: 15px;
					@media (max-width: 1300px) {
						font-size: 14px;
					}
					@media (max-width: 1200px) {
						font-size: 13px;
						margin-top: 4px;
					}
				}
				.adrss {
					@media (max-width: 1200px) {
						margin-top: 4px;
					}
					svg {
						width: 15px;
					}
					p {
						font-size: 13px;
						@media (max-width: 1300px) {
							font-size: 13px;
							width: 100%;
						}
						@media (max-width: 1200px) {
							font-size: 11px;
						}
					}
				}
				.prc2 {
					h2 {
						color: $secondary;
						font-size: 19px;
						@media (max-width: 1300px) {
							font-size: 20px !important;
						}

						@media (max-width: 1200px) {
							font-size: 18px !important;
						}
					}
				}
				.rtng-crd {
					display: flex;
					padding-top: 10px;
					figure {
						height: 18px;
						width: 18px;
						margin-right: 4px;
					}
				}
				.prc {
					align-items: center;
					// @media (max-width: 1440px) {
					margin: 10px 0 0;
					// }

					h2 {
						color: $secondary;
						font-size: 19px;
						@media (max-width: 1300px) {
							font-size: 20px !important;
							margin: 0;
						}
					}
					button {
						@media (max-width: 1440px) {
							font-size: 12px;
							padding: 12px 18px;
						}
					}
				}
				.crd_btns {
					@media (max-width: 1200px) {
						margin-top: 4px;
					}
					button {
						@media (max-width: 1440px) {
							font-size: 12px;
							padding: 12px 18px;
						}
						@media (max-width: 1200px) {
							font-size: 10px;
						}
					}
				}
			}
		}
	}
}
.grph-size {
	height: 150px;
	width: 150px;
	line-height: 0 !important;
}
// upgrades/appointments
.check-avlbl {
	background: #ffffff;
	border: 1px solid rgba(254, 110, 107, 0.3490196078);
	border-radius: 10px;
	padding: 22px 30px;
	h2 {
		font-weight: 600;
		font-size: 28px;
		line-height: 52px;
		color: #565656;
		span {
			font-style: italic;
			color: #fe6e6b;
			font-weight: 400;
			font-size: 16px;
			line-height: 52px;
		}
	}
	p {
		font-weight: 400;
		font-size: 15px;
		line-height: 34px;
		color: rgb(86 86 86 / 54%);
	}
	.css-9mgopn-MuiDivider-root {
		border-bottom: 1px solid #fe6e6b59;
		padding-top: 20px;
	}
	.serv_list {
		.rdo {
			display: flex;
			align-items: center;
			h4 {
				font-weight: 500;
				font-size: 18px;
				line-height: 25px;
				color: #565656;
				opacity: 0.5;
			}
			span {
				color: #fe6e6b;
			}
		}
		.chk_list {
			padding: 20px 50px;
			.lst {
				padding-top: 5px;
				display: flex;
				align-items: center;
				span {
					color: #fe6e6b;
					padding: 0;
					margin-right: 10px;
				}
			}
		}
	}
}
.bk-aptmnt {
	// background: rgb(92 95 103 / 10%);

	background: white;
	border: 1px solid rgba($secondary, 0.3);
	border-radius: 10px;
	margin: 28px 0;
	h2 {
		font-weight: 700;
		font-size: 34px;
		line-height: 61px;
		color: #565656;
		padding: 20px 30px 12px;
	}
	.MuiDivider-root {
		border-bottom: 1px solid rgba(86, 86, 86, 0.1);
	}
}
