.contactmain {
	padding-top: 40px;
	.faqs {
		// padding: 60px 0;
		.faqdi {
			margin-top: 30px;
			h3 {
				color: #565656;
				font-size: 23px;
				font-weight: 600;
				margin-bottom: 15px;
			}
		}
		.termsofuse {
			background: #ffffff;
			border: 1px solid rgba(86, 86, 86, 0.2);
			box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.06);
			border-radius: 10px;
			margin-top: 20px;
			// padding: 20px;
			h5 {
				color: #808080;
				font-size: 17px;
				font-weight: 500;
				border-bottom: 1px solid rgba(86, 86, 86, 0.2);
				padding: 10px;
				text-transform: capitalize;
				span {
					color: #fa6866;
				}
			}
			p {
				color: #565656;
				font-size: 14px;
				padding: 10px;
			}
		}
	}
}

.ppbanner {
	.ppleft {
		h3 {
			color: #ffff;
			font-size: 26px;
			font-weight: 500;
			padding-top: 200px;
		}
	}
}
.faqs {
	margin-top: -170px;
	position: relative;
	background-color: transparent;
	@media (max-width: 1632px) {
		margin-top: -150px;
	}
	@media (max-width: 1500px) {
		margin-top: -130px;
	}
	@media (max-width: 1366px) {
		margin-top: -110px;
	}
	@media (max-width: 1201px) {
		margin-top: -100px;
	}
	@media (max-width: 1155px) {
		margin-top: -80px;
	}
	.Mui-expanded {
		position: relative;
		background: #f0605d;
		box-shadow: -6px 6px 24px rgba(250, 91, 60, 0.25);
		border-radius: 10px;
		p {
			color: #fff;
		}
		.white_img {
			display: block;
			position: absolute;
			right: 26px;
			width: 13px;
		}
		.grey_img {
			display: none;
		}
	}
	.MuiAccordionSummary-content {
		font-weight: 500;
		font-size: 20px;
		line-height: 30px;
		display: flex;
		align-items: center;
		text-transform: lowercase;

		color: #808080;
	}
	.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
	}
	.MuiCollapse-entered {
		background-color: #fff;
		p {
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			letter-spacing: 0.01em;
			color: #565656;
		}
	}
	.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
		min-height: 46px;
	}
	.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
		margin: 0;
	}
	.accd {
		margin: 0 !important;
	}
	.acc-dtls {
		padding: 18px 16px 32px;
		background: #f7f7f7;
	}
	.white_img {
		display: none;
	}
	.grey_img {
		position: absolute;
		right: 41px;
		width: 13px;
	}
	// .css-15v22id-MuiAccordionDetails-root {
	// 	padding: 16px 16px 57px;
	// }
}
