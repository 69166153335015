@import '../../assets/theme/variable.scss';
.red-hdng {
	color: $secondary !important;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		background: url('../../../public/static/images/underlined_hdng.svg');
		height: 20px;
		width: 100%;
		bottom: -20px;
		left: 10px;
		background-repeat: no-repeat;
	}
	span {
		color: $headings !important;
	}
}

section.presl_cpns {
	padding: 40px 0 150px;
	background-color: rgba($background, 0.1);
	overflow: hidden;

	.presl_hdng {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			font-size: 30px;
			font-weight: 700;
			color: $headings;

			span {
				color: $secondary;
				position: relative;

				&::after {
					height: 14px;
					bottom: -16px;
				}
			}
		}
	}

	.slider_outr {
		padding-top: 70px;
		width: 95%;
		margin-left: auto;

		svg.slick-arrow.slick-prev {
			background: $secondary;
			color: $white;
			font-size: 20px;
			position: absolute;
			top: 490px;
			border: 1px solid $secondary;
			border-radius: 50%;
			left: 45%;
			padding: 12px;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;

			&:hover {
				background: rgba($secondary, 0.8);
				border: 1px solid rgba($secondary, 0.8);
			}
		}

		.slick-disabled {
			color: gray !important;
			background: $white !important;
			border: 1px solid $white !important;
		}

		svg.slick-arrow.slick-next {
			background: $secondary;
			color: $white;
			font-size: 20px;
			position: absolute;
			top: 490px;
			border: 1px solid $secondary;
			border-radius: 50%;
			right: 45%;
			padding: 12px;
			box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px;
			&:hover {
				background: rgba($secondary, 0.8);
				border: 1px solid rgba($secondary, 0.8);
			}
		}
	}
}

.auction_cpns {
	padding: 40px 0 150px;

	overflow: hidden;

	.presl_hdng {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			font-size: 30px;
			font-weight: 700;
			color: $headings;

			span {
				color: $secondary;
				position: relative;

				&::after {
					bottom: -18px;
					height: 14px;
				}
			}
		}
	}

	.slider_outr {
		padding-top: 70px;
		width: 95%;
		margin-left: auto;

		svg.slick-arrow.slick-prev {
			background: $secondary;
			color: $white;
			font-size: 20px;
			position: absolute;
			top: 490px;
			border: 1px solid $secondary;
			border-radius: 50%;
			left: 45%;
			padding: 12px;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
			&:hover {
				background: rgba($secondary, 0.8);
				border: 1px solid rgba($secondary, 0.8);
			}
		}

		.slick-disabled {
			color: gray !important;
			background: $white !important;
			border: 1px solid $white !important;
		}

		svg.slick-arrow.slick-next {
			background: $secondary;
			color: $white;
			font-size: 20px;
			position: absolute;
			top: 490px;
			border: 1px solid $secondary;
			border-radius: 50%;
			right: 45%;
			padding: 12px;
			box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px;
			&:hover {
				background: rgba($secondary, 0.8);
				border: 1px solid rgba($secondary, 0.8);
			}
		}
	}
}

.dscount_srvc {
	padding: 40px 0 150px;
	background: linear-gradient(rgba(#fca177, 50%), rgba(#fca177, 0%));
	margin-top: 60px;
	overflow: hidden;

	.presl_hdng {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			font-size: 30px;
			font-weight: 700;
			color: $headings;

			span {
				color: $secondary;
				position: relative;

				&::after {
					bottom: -20px;
					left: 14%;
					height: 20px;
					width: 200px;
				}
			}
		}
	}

	.slider_outr {
		padding-top: 70px;
		width: 95%;
		margin-left: auto;

		svg.slick-arrow.slick-prev {
			background: $secondary;
			color: $white;
			font-size: 20px;
			position: absolute;
			top: 490px;
			border: 1px solid $secondary;
			border-radius: 50%;
			left: 45%;
			padding: 12px;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;
			&:hover {
				background: rgba($secondary, 0.8);
				border: 1px solid rgba($secondary, 0.8);
			}
		}

		.slick-disabled {
			color: gray !important;
			background: $white !important;
			border: 1px solid $white !important;
		}

		svg.slick-arrow.slick-next {
			background: $secondary;
			color: $white;
			font-size: 20px;
			position: absolute;
			top: 490px;
			border: 1px solid $secondary;
			border-radius: 50%;
			right: 45%;
			padding: 12px;
			box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px;
			&:hover {
				background: rgba($secondary, 0.8);
				border: 1px solid rgba($secondary, 0.8);
			}
		}
	}
}
section.presl_cpns {
	@media (max-width: 1200px) {
		// padding: 40px 0 150px;
	}
	.presl_hdng {
		h2 {
			@media (max-width: 1300px) {
				font-size: 24px;
				span {
					&::after {
						width: 100%;
					}
				}
			}

			@media (max-width: 1200px) {
				font-size: 22px;
			}
		}
	}

	.slider_outr {
		@media (max-width: 1300px) {
			padding-top: 80px;
		}
		@media (max-width: 1200px) {
			padding-top: 60px;
		}

		svg.slick-arrow.slick-prev {
			@media (max-width: 2000px) {
				font-size: 30px;
				top: 520px;
			}

			@media (max-width: 1700px) {
				top: 500px;
			}

			@media (max-width: 1500px) {
				font-size: 20px;
				top: 480px;
			}

			@media (max-width: 1440px) {
				top: 460px;
			}

			@media (max-width: 1300px) {
				top: 460px;
			}

			@media (max-width: 1200px) {
				top: 450px;
				// left: 40%;
			}

			@media (max-width: 767px) {
				display: none !important;
			}
		}

		svg.slick-arrow.slick-next {
			@media (max-width: 2000px) {
				font-size: 30px;
				top: 520px;
			}

			@media (max-width: 1700px) {
				top: 500px;
			}

			@media (max-width: 1500px) {
				font-size: 20px;
				top: 480px;
			}

			@media (max-width: 1440px) {
				top: 460px;
			}

			@media (max-width: 1300px) {
				top: 460px;
			}

			@media (max-width: 1200px) {
				top: 450px;
				right: 40%;
			}
			@media (max-width: 767px) {
				display: none !important;
			}
		}
	}
}

section.auction_cpns {
	@media (max-width: 1200px) {
		// padding: 60px 0 150px;
	}
	.presl_hdng {
		h2 {
			@media (max-width: 1300px) {
				font-size: 24px;
				span {
					&::after {
						width: 100%;
					}
				}
			}

			@media (max-width: 1200px) {
				font-size: 22px;
			}
		}
	}

	.slider_outr {
		@media (max-width: 1300px) {
			padding-top: 80px;
		}
		@media (max-width: 1200px) {
			padding-top: 60px;
		}

		svg.slick-arrow.slick-prev {
			@media (max-width: 2000px) {
				font-size: 30px;
				top: 550px;
			}

			@media (max-width: 1700px) {
				top: 530px;
			}

			@media (max-width: 1500px) {
				top: 500px;
				font-size: 20px;
			}

			@media (max-width: 1440px) {
				top: 490px;
			}

			@media (max-width: 1300px) {
				top: 510px;
				font-size: 18px;
			}

			@media (max-width: 1200px) {
				top: 460px;
				// left: 40%;
			}
			@media (max-width: 767px) {
				display: none !important;
			}
		}

		svg.slick-arrow.slick-next {
			@media (max-width: 2000px) {
				font-size: 30px;
				top: 550px;
			}

			@media (max-width: 1700px) {
				top: 530px;
			}

			@media (max-width: 1500px) {
				top: 500px;
				font-size: 20px;
			}

			@media (max-width: 1440px) {
				top: 490px;
			}

			@media (max-width: 1300px) {
				top: 510px;
				font-size: 18px;
			}

			@media (max-width: 1200px) {
				top: 460px;
				right: 40%;
			}
			@media (max-width: 767px) {
				display: none !important;
			}
		}
	}
}

section.dscount_srvc {
	@media (max-width: 1200px) {
		padding: 40px 0 150px;
	}
	.presl_hdng {
		h2 {
			@media (max-width: 1300px) {
				font-size: 24px;
				span {
					&::after {
						width: 150px;
					}
				}
			}

			@media (max-width: 1200px) {
				font-size: 22px;
			}
		}
	}

	.slider_outr {
		@media (max-width: 1300px) {
			padding-top: 80px;
		}
		@media (max-width: 1200px) {
			padding-top: 60px;
		}

		svg.slick-arrow.slick-prev {
			@media (max-width: 2000px) {
				font-size: 30px;

				top: 510px;
			}

			@media (max-width: 1700px) {
				top: 490px;
			}

			@media (max-width: 1500px) {
				top: 470px;
				font-size: 20px;
			}

			@media (max-width: 1440px) {
				top: 440px;
			}

			@media (max-width: 1300px) {
				top: 430px;
				font-size: 18px;
			}

			@media (max-width: 1200px) {
				top: 460px;
				// left: 40%;
			}
			@media (max-width: 767px) {
				display: none !important;
			}
		}

		svg.slick-arrow.slick-next {
			@media (max-width: 2000px) {
				font-size: 30px;

				top: 510px;
			}

			@media (max-width: 1700px) {
				top: 490px;
			}

			@media (max-width: 1500px) {
				top: 470px;
				font-size: 20px;
			}

			@media (max-width: 1440px) {
				top: 440px;
			}

			@media (max-width: 1300px) {
				top: 430px;
				font-size: 18px;
			}

			@media (max-width: 1200px) {
				top: 460px;
				right: 40%;
			}
			@media (max-width: 767px) {
				display: none !important;
			}
		}
	}
}
