@import '../../assets/theme/variable.scss';
.prdct-dtl {
	padding-top: 80px;
	padding-bottom: 70px;
	.productdmain {
		display: flex;
		justify-content: space-between;
		gap: 40px;
		flex-wrap: wrap;
		margin-top: 40px;
		.pdleft {
			width: 48%;
			@media (max-width: 1200px) {
				width: 47%;
			}
			h2 {
				font-weight: 700;
				font-size: 28px;
				line-height: 40px;
				color: #565656;
			}
			.views {
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid rgba(86, 86, 86, 0.1);
				padding-bottom: 20px;
				padding-top: 20px;
				align-items: center;
				.vleft {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: 50%;
					.vcleft {
						figure {
							width: 20px;
							height: 17px;
							background: #fe6e6b;
							border-radius: 10px;
							padding: 10px;
						}
					}
					.vcright {
						padding-left: 10px;
						// padding-top: 26px;
						color: rgba(86, 86, 86, 0.5);
						font-weight: 500;
						font-size: 15px;
						p {
							font-size: 14px;
							line-height: 26px;
						}
					}
				}
				.vright {
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					width: 50%;
					figure {
						background: transparent !important;
						padding: 5px !important;
						width: 20px !important;
						height: 20px !important;
					}
					p {
						margin-left: 6px;
						font-size: 14px;
						line-height: 26px;
						color: rgba($text, 0.5);
						span {
							color: rgba($text, 0.8);
						}
					}
				}
			}
			.content {
				margin: 20px 0;
				h4 {
					font-weight: 500;
					font-size: 16px;
					line-height: 37px;
					color: #5c5f67;
				}
				p {
					font-weight: 400;
					font-size: 14px;
					line-height: 28px;
					color: rgba(86, 86, 86, 0.75);
					margin-bottom: 18px;
				}
				.dots_para {
					margin-top: 7px;

					li {
						display: flex;
						justify-content: flex-start;
						gap: 10px;
						margin-left: 12px;
					}
					p {
						margin-bottom: 0px;
					}
				}
			}
		}
		.pdright {
			width: 48%;
			@media (max-width: 1200px) {
				width: 47%;
			}
			figure {
				width: 100%;
				height: 460px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 14px;
				}
			}
			.buttn {
				padding-top: 78px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.btn {
					width: 38%;
					margin-right: 20px;
				}
				.btn2 {
					width: 58%;
				}
			}
		}
	}
}
