@import '../../assets/theme/variable.scss';

.cart-pg {
	padding-top: 80px;
	display: flex;
	justify-content: space-between;
	gap: 20px;
	margin-bottom: 120px;
	.crt-lft {
		width: 60%;
		.stppr-sec {
			padding: 40px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 80%;
			margin: 0 auto;
			&::after {
				position: absolute;
				content: '';
				background: url('../../../public/static/images/bg-line.svg');
				width: 300px;
				height: 10px;
				top: 38%;
				bottom: 0;
				background-repeat: no-repeat;
				background-size: 100%;
				left: 140px;
			}
			.stpr-dv {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.stpr_inr {
					position: relative;
					background-color: $secondary;
					position: relative;
					width: 70px;
					height: 70px;
					margin: 0 40px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					box-shadow: rgba(255, 156, 154, 0.14) 0px 0px 1px 14px;
					z-index: 2;
					@media (max-width: 1200px) {
						width: 60px;
						height: 60px;
					}
				}
				.stpr_inrh4 {
					font-size: 16px;
					font-weight: 600;
					text-align: center;
					color: $secondary;
				}
				.stpr_in2rh4 {
					color: $text;
				}
				.stpr_inr2 {
					position: relative;
					background-color: #b9b9b9;
					position: relative;
					width: 70px;
					height: 70px;
					margin: 0 40px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					box-shadow: rgba(86, 86, 86, 0.1) 0px 0px 1px 14px;
					z-index: 2;
					@media (max-width: 1200px) {
						width: 60px;
						height: 60px;
					}

					// h2 {
					// 	font-size: 44px;
					// 	font-weight: 700;
					// 	color: rgba($text, 0.5);

					// 	background-color: transparent;
					// }
				}
				h4 {
					font-size: 16px;
					font-weight: 600;
					text-align: center;
					padding-top: 20px;
					opacity: 0.6;
				}
			}
		}
		.stppr-sec2 {
			padding: 40px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 80%;
			margin: 0 auto;
			&::after {
				position: absolute;
				content: '';
				background: url('../../../public/static/images/bg-line.svg');
				width: 300px;
				height: 10px;
				top: 38%;
				bottom: 0;
				background-repeat: no-repeat;
				background-size: 100%;
				left: 140px;
				@media (max-width: 1920px) {
					width: 360px;
					left: 210px;
				}
				@media (max-width: 1700px) {
					left: 170px;
				}
				@media (max-width: 1500px) {
					left: 150px;
				}
				@media (max-width: 1440px) {
					left: 120px;
				}
				@media (max-width: 1300px) {
					left: 100px;
				}
				@media (max-width: 1200px) {
					left: 90px;
					width: 320px;
				}
			}
			.stpr-dv {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.stpr_inr {
					position: relative;
					background-color: $secondary;
					position: relative;
					width: 70px;
					height: 70px;
					margin: 0 40px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					box-shadow: rgba(255, 156, 154, 0.14) 0px 0px 1px 14px;
					z-index: 2;
					@media (max-width: 1200px) {
						width: 60px;
						height: 60px;
					}
					figure {
						// line-height: 0;
					}
				}
				.stpr_inrh4 {
					font-size: 16px;
					font-weight: 600;
					text-align: center;
					color: $secondary;
				}
				.stpr_in2rh4 {
					color: $text;
				}
				.stpr_inr2 {
					position: relative;
					background-color: #b9b9b9;
					position: relative;
					width: 70px;
					height: 70px;
					margin: 0 40px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					box-shadow: rgba(86, 86, 86, 0.1) 0px 0px 1px 14px;
					z-index: 2;
					@media (max-width: 1200px) {
						width: 60px;
						height: 60px;
					}

					// h2 {
					// 	font-size: 44px;
					// 	font-weight: 700;
					// 	color: rgba($text, 0.5);

					// 	background-color: transparent;
					// }
				}
				h4 {
					font-size: 16px;
					font-weight: 600;
					text-align: center;
					padding-top: 20px;
					opacity: 0.6;
				}
			}
		}
		.cart-crd {
			padding: 10px;
			border: 1px solid rgba($text, 0.1);
			border-radius: 10px;
			.inr-crt-crd {
				padding: 10px 20px;
				border: 1px solid rgba($secondary, 0.5);
				border-radius: 10px;
				h2 {
					font-size: 24px;
					font-weight: 600;
					color: #565656;
					line-height: 40px;
					padding-left: 20px;
				}
				p {
					font-size: 15px;
					color: rgba($text, 0.8);
					padding-bottom: 10px;
					padding-left: 20px;
				}
				.ordr-dtl {
					padding: 10px 10px 10px 20px;
					position: relative;

					.dtl-crd {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 10px 0;
						position: relative;
						&::after {
							height: 1px;
							position: absolute;
							content: '';
							background: rgba($text, 0.1);
							width: 100%;
							bottom: -10px;
						}

						.dtl-lft {
							width: 10%;
							@media (max-width: 1300px) {
								width: 16%;
							}
							@media (max-width: 1200px) {
								width: 20%;
							}

							figure {
								height: 100px;
								width: 100px;
								img {
									border-radius: 10px;
								}
							}
						}
						.dtl-mddl {
							width: 60%;
							@media (max-width: 1440px) {
								width: 54%;
							}
							@media (max-width: 1200px) {
								width: 50%;
							}

							h4 {
								font-size: 20px;
								font-weight: 600;
								color: #565656;
							}
							p {
								padding-left: 0;
							}
							button {
								border: none;
								background: transparent;
								color: #0037fc;
								padding-right: 10px;
								cursor: pointer;
								border-right: 1px solid #0037fc;
								margin-right: 6px;
								font-size: 14px;
								&:last-child {
									border-right: 1px solid white;
								}
							}
						}
						.dtl-ryt {
							// width: 15%;
						}
					}
				}
			}
			.crt-btn-sec {
				padding: 20px 0 10px;
				display: flex;
				justify-content: space-between;
			}
		}
		.cart-crd2 {
			border: 1px solid rgba($secondary, 0.3);
			border-radius: 10px;
			h2 {
				font-size: 24px;
				padding: 10px;
				border-bottom: 1px solid rgba($secondary, 0.3);
				// background: rgba($text, 0.5);
				background: white;
				color: $text;
				font-weight: 600;
				border-radius: 10px 10px 0 0;
				@media (max-width: 1200px) {
					font-size: 22px;
				}
			}
			.mn-cntct-info {
				padding: 10px;
				.eml-fld {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					.selectr {
						width: 80%;
						.eml-txtfld {
							// width: 90%;
							padding-bottom: 0px;
							@media (max-width: 1300px) {
								// width: 80%;
							}

							input {
								padding: 13.5px 14px;
								background: white;
								border: 1px solid rgba($secondary, 0.3);
								border-radius: 8px;
								@media (max-width: 1200px) {
									padding: 9.5px 14px;
								}
								&::placeholder {
									@media (max-width: 1200px) {
										font-size: 13px;
									}
								}
							}
							fieldset {
								display: none;
							}
						}
					}
					.eml-txtfld {
						// width: 82%;
						padding-bottom: 16px;
						@media (max-width: 1300px) {
							// width: 80%;
						}

						input {
							padding: 13.5px 14px;
							background: #d9d9d966;
							border-radius: 8px;
							@media (max-width: 1200px) {
								padding: 9.5px 14px;
							}
							&::placeholder {
								@media (max-width: 1200px) {
									font-size: 13px;
								}
							}
						}
						fieldset {
							display: none;
						}
					}

					.btn {
						// width: 16%;
						min-width: 18%;
						width: max-content;
						border-radius: 10px;
						margin-bottom: 16px;
						@media (max-width: 1300px) {
							// width: 17%;
						}
						@media (max-width: 1200px) {
							// width: 18%;
						}
					}
				}
				h3 {
					font-size: 18px;
					padding: 10px 0;
					color: $text;
					font-weight: 500;
				}
				.name-fld {
					display: flex;
					justify-content: space-between;
					// align-items: center;
					.cntct-info-txtfld {
						width: 49%;
						input {
							padding: 13.5px 14px;
							background: white;
							border: 1px solid rgba($secondary, 0.3);
							border-radius: 8px;
							@media (max-width: 1200px) {
								padding: 9.5px 14px;
							}
							&::placeholder {
								@media (max-width: 1200px) {
									font-size: 13px;
								}
							}
						}
						fieldset {
							display: none;
						}
					}
					fieldset {
						display: none;
					}
				}
				.adrss-flds {
					display: flex;
					justify-content: space-between;
					// align-items: center;
					.cntct-info-txtfld {
						width: 32%;
						fieldset {
							display: none;
						}
					}
				}
				.sv-info {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					span {
						color: $secondary;
					}
					p {
						font-size: 14px;
						color: $text;
					}
				}

				.cntct-info-txtfld {
					width: 100%;
					padding-bottom: 16px;
					input {
						padding: 13.5px 14px;
						background: white;
						border: 1px solid rgba($secondary, 0.3);
						border-radius: 8px;
						@media (max-width: 1200px) {
							padding: 9.5px 14px;
						}
						&::placeholder {
							@media (max-width: 1200px) {
								font-size: 13px;
							}
						}
					}
					fieldset {
						display: none;
					}
				}
			}
			.crt-btn-sec {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 10px 20px;
			}
			.pymnt-mthd {
				.mn-pymnt-sec {
					padding: 10px;
					& > div {
						width: 100%;
						.mthds {
							padding: 10px;
							border: 1px solid rgba($secondary, 0.5);
							margin-bottom: 10px;
							border-radius: 10px;

							.slct-icns {
								display: flex;
								justify-content: space-between;
								align-items: center;
								span {
									color: $secondary;
								}
								h4 {
									color: $text !important;
									font-size: 18px;
									font-weight: 700;
									@media (max-width: 1200px) {
										font-size: 16px;
									}
								}
								figure {
									height: 16px;
									width: 16px;
									cursor: pointer;
								}
							}
							p {
								font-size: 14px;
								color: rgba($text, 0.5);
								@media (max-width: 1200px) {
									font-size: 13px;
								}
							}
							.crd-dtls {
								display: flex;
								justify-content: space-between;
								align-items: center;
								.cntct-info-txtfld {
									width: 49%;
								}
							}
							.cntct-info-txtfld {
								width: 100%;
								padding-bottom: 16px;
								input {
									padding: 13.5px 14px;
									background: white;
									border: 1px solid rgba($secondary, 0.3);
									border-radius: 8px;
									@media (max-width: 1200px) {
										padding: 9.5px 14px;
									}
									&::placeholder {
										@media (max-width: 1200px) {
											font-size: 13px;
										}
									}
								}
								fieldset {
									display: none;
								}
							}
						}
					}
				}
				.pymnt-btn-sec {
					display: flex;
					justify-content: flex-end;
					padding: 10px;
				}
			}
		}
	}
}

// responsive--------
.cart-pg .crt-lft .stppr-sec::after {
	@media (max-width: 1920px) {
		left: 240px;
	}
	@media (max-width: 1700px) {
		left: 180px;
	}
	@media (max-width: 1440px) {
		left: 146px;
	}
	@media (max-width: 1200px) {
		left: 85px;
	}
}
