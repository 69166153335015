@import '../assets/theme/variable.scss';
.brdcrmb {
	color: $secondary !important;
	display: flex;

	cursor: pointer;
}
.cms_brcm {
	color: white !important;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.mn_lyout {
	.header {
		position: relative;
		.hdr_mn {
			// position: fixed;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 10px 0px;
			// width: 100%;
			// z-index: 999;
			.hdr {
				padding: 20px 0 20px;
				background-color: $primary;

				.mn_hdr {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.hdr_lft {
						width: 28%;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						.burger {
							background-color: transparent;
							background-image: none;
							border: none;
							width: 24px;
							transition: 0.2s ease-in-out;
							&:hover {
								transform: rotate(-90deg);
								span {
									animation: dance 1s ease-in-out infinite;
									transition-duration: 0.5s;
									&:first-child {
										animation-delay: 0.1s;
									}
									&:nth-child(2) {
										animation-delay: 0.3s;
									}
									&:nth-child(3) {
										animation-delay: 0.5s;
									}
								}
							}
							.burger-block {
								display: flex !important;
								flex-direction: column;
								border-radius: 0.25rem;
								display: inline-block;
								cursor: pointer;
								width: 1.5rem;
								gap: 6px;

								span {
									background-color: $text;
									border-radius: 0.25rem;
									display: inline-block;
									height: 2px;
									width: 1.5rem;
								}
								@keyframes dance {
									0%,
									100% {
										-webkit-transform: scaleX(1);
										transform: scaleX(1);
									}
									33% {
										-webkit-transform: scaleX(0.55);
										transform: scaleX(0.55);
									}
									66% {
										-webkit-transform: scaleX(1.15);
										transform: scaleX(1.15);
									}
								}
							}
						}

						.mnu {
							width: 30px;
							height: 50px;
							cursor: pointer;
							filter: opacity(0.7);
						}

						figure {
							width: 190px;
							height: 60px;
							margin-left: 15px;
							cursor: pointer;
						}
					}

					.hdr_mdl {
						width: 26%;
						display: flex;
						justify-content: space-between;
						align-items: center;

						li {
							div {
								h6 {
									color: $text;
									font-size: 14px;
									font-weight: 500;
									cursor: pointer;
									border-bottom: 1px solid transparent;
									&:hover {
										color: $secondary;
									}
								}
							}
						}
					}

					.hdr_ryt {
						width: 32%;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.hdr-txt {
							cursor: pointer;
							width: 130px;
							@media (max-width: 2000px) {
								width: 180px;
							}
							@media (max-width: 1700px) {
								width: 150px;
							}
							@media (max-width: 1500px) {
								width: 130px;
							}
							h6 {
								color: $text;
								font-size: 14px;
								font-weight: 500;
								min-width: fit-content;
								width: fit-content !important;
								margin-left: auto !important;
								border-bottom: 1.5px solid transparent;
								&:hover {
									color: $secondary;
								}
							}
						}
						.hdr_ryt_cntnt {
							background-color: $secondary;
							border-radius: 50%;
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;
							line-height: 0;
							box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
							&:hover {
								background: rgba($secondary, 0.8);
							}
						}
						.lang_chng {
							height: 26px;
							width: 28px;
							padding: 10px;
							h6 {
								color: $white;
								font-size: 14px;
								font-weight: 500;
							}
						}

						.hdr_crt {
							padding: 12px;
							figure {
								height: 22px;
								width: 22px;
							}
						}

						.hdr_acc {
							padding: 12px;
							figure {
								height: 22px;
								width: 22px;
							}
						}

						button {
							color: $secondary;
							background: $primary;
							cursor: pointer;

							width: 70px;
							@media (max-width: 1920px) {
								min-width: 100px;
							}
							@media (max-width: 1500px) {
								min-width: 85px;
							}
							@media (max-width: 1440px) {
								min-width: 75px;
							}
							@media (max-width: 1200px) {
								min-width: 70px;
							}

							&:hover {
								background: $secondary;
								color: $white;
							}
						}
					}
				}
			}

			.mn_hdr2 {
				width: 34%;
				position: absolute;
				top: 60px;
				left: 30%;
				z-index: 1;

				.txt_fld {
					width: 100%;
					padding: 20px;

					div {
						background: $white;
						border-radius: 40px;
						padding: 0px 2px 0px 6px;
						color: $text;
						box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
						input {
							height: 14px;
							font-size: 14px;

							&::placeholder {
								font-size: 14px;
							}
						}

						fieldset {
							border: none;
							border-radius: 30px !important;
						}
					}

					.srch_dv {
						cursor: pointer;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						background-color: #f5f5f5;
						padding: 5px 6px;
						border-radius: 30px;
						width: 110px;
						text-align: center;
						@media (max-width: 1200px) {
							width: 90px;
						}

						figure {
							width: 30px;
							height: 30px;
							margin-right: 4px;
							img {
								opacity: 0.5;
							}
						}

						h6 {
							font-size: 14px;
							color: rgba($text, 0.5);
						}
					}
				}
			}
		}
	}

	footer.ft_r {
		background: linear-gradient(rgba(#fe6e6b, 0%), rgba(#fe6e6b, 20%));
		margin-top: auto;
		// border-top: 1px solid rgba($text, 0.1);

		.top_ftr {
			padding: 40px 0;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.tp_ftr_lft {
				width: 50%;

				p {
					font-size: 14px;
					font-weight: 600;
					color: $headings;
					opacity: 80%;
					line-height: 30px;
					width: 62%;
				}

				.subscrb {
					padding-top: 20px;

					.txt_fld {
						width: 60%;
						box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
						background: $white;
						border-radius: 30px;
						padding: 2px;

						input {
							height: 10px;
							font-size: 14px;
							color: $text;

							&::placeholder {
								font-size: 14px;
							}
						}

						fieldset {
							border: none;
						}

						figure {
							width: 26px;
							height: 26px;
						}
					}

					button {
						margin-left: 10px;
					}
				}
			}

			.tp_ftr_ryt {
				width: 26%;

				h3 {
					color: $headings;
					opacity: 80%;
					font-size: 18px;
					font-weight: 600;
				}

				ul {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-top: 20px;

					a {
						width: 18%;
						border: 1px solid rgba($text, 0.2);
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 10px 0;
						border-radius: 10px;
						box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 1px -2px,
							rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
						cursor: pointer;

						&:hover img {
							filter: none !important;
						}

						img {
							filter: brightness(0.2);
						}

						&:hover {
							background: rgba(254, 110, 107, 0.1);
							border: 1px solid rgba(254, 110, 107, 0.3);
						}

						figure {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}

		ul.mdl_ftr {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 40px 0;

			li.ftr_sec {
				width: 30%;

				figure {
					width: 130px;
					height: 50px;
					margin-top: -10px;
					padding-bottom: 8px;
				}

				p {
					cursor: auto;
				}
			}

			li {
				width: 14%;

				h4 {
					font-size: 14px;
					font-weight: 600;
					color: $text;
					padding-bottom: 20px;
				}

				p {
					font-size: 12px;
					font-weight: 500;
					color: $headings;
					// opacity: 50%;
					line-height: 28px;
					cursor: pointer;
					a {
						color: #565656;
						text-decoration: none;
					}
				}
			}

			.ftr_sec2 {
				div {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					cursor: pointer;

					figure {
						height: 18px;
						width: 18px;
						margin-right: 6px;
					}
				}
			}

			.lang_select {
				background: $white;
				border: 1px solid $white;
				border-radius: 30px;
				font-size: 12px;
				height: 40px;
				color: $text;
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
				fieldset {
					border: none;
				}
			}
		}

		.btm_ftr {
			padding: 20px 0;

			p {
				color: $text;

				font-size: 12px;
				font-weight: 500;
			}
		}
	}
}

// -----------responsive---------
.mn_lyout {
	.header {
		.hdr_mn {
			.hdr {
				.mn_hdr {
					.hdr_lft {
						@media (max-width: 2000px) {
							width: 360px;
						}
						@media (max-width: 1700px) {
							// width: 23%;
						}
						@media (max-width: 1500px) {
							// width: 24%;
						}
						@media (max-width: 1440px) {
							width: 280px;
						}
						@media (max-width: 1300px) {
							// width: 25%;
						}
						@media (max-width: 1200px) {
							// width: 26%;
							// width: 23%;
						}
						@media (max-width: 1120px) {
							// width: 25%;
						}

						@media (max-width: 1024px) {
							// width: 28%;
						}

						.mnu {
							@media (max-width: 2000px) {
								width: 36px;
								height: 48px;
							}
							@media (max-width: 1700px) {
								width: 32px;
								height: 46px;
							}
							@media (max-width: 1500px) {
								width: 30px;
							}
							@media (max-width: 1440px) {
								width: 28px;
							}
							@media (max-width: 1300px) {
								width: 26px;
							}

							@media (max-width: 1200px) {
								width: 22px;
							}

							@media (max-width: 992px) {
								width: 22px;
							}
						}

						figure {
							@media (max-width: 2000px) {
								width: 210px;
								height: 80px;
								margin-left: 30px;
							}
							@media (max-width: 1700px) {
								width: 160px;
								height: 56px;
							}
							@media (max-width: 1500px) {
								width: 155px;
								height: 54px;
							}
							@media (max-width: 1440px) {
								width: 140px;
								height: 52px;
							}
							@media (max-width: 1300px) {
								width: 148px;
								height: 50px;
							}

							@media (max-width: 1200px) {
								width: 118px;
								height: 44px;
								margin-left: 24px;
							}

							@media (max-width: 992px) {
							}
						}
					}

					.hdr_mdl {
						@media (max-width: 2000px) {
							// width: 23%;
							width: 420px;
						}
						@media (max-width: 1700px) {
							// width: 26%;
							width: 350px;
						}
						@media (max-width: 1500px) {
							// width: 22%;
							width: 320px;
						}
						@media (max-width: 1440px) {
							// width: 300px;
						}
						@media (max-width: 1200px) {
							width: 270px;
						}
						@media (max-width: 1024px) {
						}

						li {
							div {
								border-bottom: 1px solid transparent;
								h6 {
									@media (max-width: 2000px) {
										font-size: 16px;
									}
									@media (max-width: 1800px) {
										font-size: 15px;
									}
									@media (max-width: 1700px) {
										font-size: 14px;
									}
									@media (max-width: 1500px) {
										font-size: 12px;
									}

									@media (max-width: 1300px) {
										font-size: 12px;
									}

									@media (max-width: 1200px) {
										font-size: 11px;
									}
								}
							}
						}
					}

					.hdr_ryt {
						@media (max-width: 2000px) {
							// width: 25%;
							width: 440px;
						}

						@media (max-width: 1700px) {
							// width: 27%;
							width: 410px;
						}
						@media (max-width: 1500px) {
							// width: 25%;
							width: 370px;
						}
						@media (max-width: 1440px) {
							// width: 26%;
							width: 320px;
						}
						@media (max-width: 1300px) {
							// width: 29%;
						}

						@media (max-width: 1200px) {
							// width: 29% !important;
							width: 300px;
						}
						@media (max-width: 1024px) {
							// width: 30% !important;
						}

						.hdr-txt {
							// min-width: 126px;
							// text-align: center;
							// width: 130px;
							h6 {
								@media (max-width: 2000px) {
									white-space: nowrap;
									font-size: 16px;
									// min-width: 180px;
								}
								@media (max-width: 1800px) {
									font-size: 15px;
								}
								@media (max-width: 1700px) {
									font-size: 14px;
								}
								@media (max-width: 1500px) {
									font-size: 12px;
									// min-width: 150px;
								}
								@media (max-width: 1440px) {
									// min-width: 126px;
								}
								@media (max-width: 1300px) {
									font-size: 12px;
								}

								@media (max-width: 1200px) {
									font-size: 11px;
								}
							}
						}

						.lang_chng {
							@media (max-width: 2000px) {
								height: 23px;
								width: 25px;
							}
							@media (max-width: 1800px) {
								height: 19px;
								width: 19px;
							}
							@media (max-width: 1700px) {
								height: 16px;
								width: 16px;
							}
							@media (max-width: 1500px) {
								height: 16px;
								width: 18px;
							}
							@media (max-width: 1440px) {
								height: 11px;
								width: 12px;
							}
							@media (max-width: 1300px) {
							}
							@media (max-width: 1200px) {
								height: 9px;
								width: 9px;
							}
							@media (max-width: 1024px) {
								height: 8px;
								width: 8px;
							}
							h6 {
								@media (max-width: 2000px) {
									font-size: 15px;
								}
								@media (max-width: 1800px) {
									font-size: 14px;
								}
								@media (max-width: 1700px) {
									font-size: 13px;
								}
								@media (max-width: 1500px) {
									font-size: 12px;
								}
								@media (max-width: 1440px) {
									font-size: 11px;
								}
								@media (max-width: 1024px) {
									font-size: 10px;
								}
							}
						}

						.hdr_crt {
							@media (max-width: 2000px) {
								padding: 11px;
							}

							@media (max-width: 1700px) {
								padding: 10px;
							}
							@media (max-width: 1500px) {
								padding: 11px;
							}
							@media (max-width: 1440px) {
								padding: 8px;
							}
							@media (max-width: 1300px) {
								padding: 8px;
							}

							@media (max-width: 1200px) {
								padding: 7px;
							}
							@media (max-width: 1024px) {
								padding: 6px;
							}
							figure {
								@media (max-width: 2000px) {
									height: 22px;
									width: 22px;
								}

								@media (max-width: 1800px) {
									height: 20px;
									width: 20px;
								}
								@media (max-width: 1700px) {
									height: 18px;
									width: 18px;
								}
								@media (max-width: 1500px) {
									height: 16px;
									width: 16px;
								}
								@media (max-width: 1300px) {
									// height: 22px;
									// width: 22px;
								}

								@media (max-width: 1200px) {
									// height: 20px;
									// width: 20px;
								}
							}
						}

						.hdr_acc {
							@media (max-width: 2000px) {
								padding: 11px;
							}
							@media (max-width: 1440px) {
								padding: 9px;
							}
							@media (max-width: 1300px) {
								// padding: 11px;
							}
							@media (max-width: 1200px) {
								padding: 8px;
							}
							figure {
								@media (max-width: 2000px) {
									height: 21px;
									width: 21px;
									line-height: 0;
								}

								@media (max-width: 1800px) {
									height: 18px;
									width: 18px;
								}
								@media (max-width: 1700px) {
									height: 16px;
									width: 16px;
								}
								@media (max-width: 1500px) {
									height: 16px;
									width: 16px;
								}
								@media (max-width: 1440px) {
									height: 14px;
									width: 14px;
								}
								@media (max-width: 1300px) {
									// height: 16px;
									// width: 15px;
								}
								@media (max-width: 1200px) {
									height: 14px;
									width: 14px;
								}
								@media (max-width: 1024px) {
									height: 12px;
									width: 12px;
								}
							}
						}

						button {
							@media (max-width: 2000px) {
								padding: 12px 16px;
								font-size: 14px;
							}
							@media (max-width: 1800px) {
								padding: 12px 14px;
							}
							@media (max-width: 1700px) {
								padding: 11px 16px;
								font-size: 14px;
							}
							@media (max-width: 1500px) {
								padding: 10px 12px;
								font-size: 12px;
							}
							@media (max-width: 1440px) {
								font-size: 11px;
							}
							@media (max-width: 1300px) {
								font-size: 11px;
								padding: 10px 12px;
							}
							@media (max-width: 1200px) {
								font-size: 10px;
								padding: 9px 12px;
							}
						}
					}
				}
			}

			.mn_hdr2 {
				@media (max-width: 2000px) {
					width: 30%;
					left: 32%;
					top: 78px;
				}
				@media (max-width: 1700px) {
					top: 55px;
				}
				@media (max-width: 1440px) {
					width: 32%;
					left: 31%;
					top: 48px;
				}
				@media (max-width: 1200px) {
					width: 35%;
					left: 30%;
				}
				.txt_fld {
					div {
						input {
							@media (max-width: 1200px) {
								height: 10px;
								font-size: 12px;
							}

							// @media (max-width: 2000px) {
							// 	height: 34px;
							// }
							&::placeholder {
								@media (max-width: 1200px) {
									font-size: 12px;
								}
							}
						}
					}

					.srch_dv {
						figure {
							@media (max-width: 1200px) {
								width: 26px;
								height: 26px;
							}
						}

						h6 {
							@media (max-width: 1200px) {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
	footer.ft_r {
		.top_ftr {
			@media (max-width: 992px) {
				padding: 20px 0;
			}

			.tp_ftr_lft {
				@media (max-width: 1300px) {
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
					width: 100%;
					text-align: center;
				}

				p {
					@media (max-width: 1300px) {
						font-size: 13px;
						line-height: 28px;
					}
					@media (max-width: 1200px) {
						font-size: 12px;
						line-height: 26px;
					}
					@media (max-width: 992px) {
						width: 100%;
						line-height: 22px;
					}
				}

				.subscrb {
					.txt_fld {
						input {
							@media (max-width: 1300px) {
							}
							@media (max-width: 1200px) {
								height: 6px;
							}
							@media (max-width: 992px) {
								height: 4px;
							}

							&::placeholder {
								@media (max-width: 1300px) {
								}
								@media (max-width: 1200px) {
									font-size: 12px;
								}
							}
						}

						figure {
							@media (max-width: 1300px) {
							}
							@media (max-width: 1200px) {
							}
							@media (max-width: 992px) {
								width: 18px;
								height: 18px;
							}
						}
					}
				}
			}

			.tp_ftr_ryt {
				@media (max-width: 1300px) {
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
					width: 50%;
					margin: 0 auto;
					text-align: center;
					padding-top: 20px;
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
				}

				h3 {
					@media (max-width: 1300px) {
						font-size: 17px !important;
					}
					@media (max-width: 1200px) {
						font-size: 16px !important;
					}
					@media (max-width: 992px) {
					}
					@media (max-width: 767px) {
					}
					@media (max-width: 600px) {
					}
					@media (max-width: 480px) {
					}
				}

				ul {
					@media (max-width: 1300px) {
					}
					@media (max-width: 1200px) {
					}
					@media (max-width: 992px) {
						width: 70%;
						margin: auto;
					}
					@media (max-width: 767px) {
					}
					@media (max-width: 600px) {
					}
					@media (max-width: 480px) {
					}

					a {
						@media (max-width: 1300px) {
						}
						@media (max-width: 1200px) {
							padding: 8px 0;
						}
						@media (max-width: 992px) {
						}
						@media (max-width: 767px) {
						}
						@media (max-width: 600px) {
						}
						@media (max-width: 480px) {
						}

						figure {
							@media (max-width: 1300px) {
								width: 18px;
								height: 18px;
							}
							@media (max-width: 1200px) {
							}
							@media (max-width: 992px) {
								width: 16px;
								height: 16px;
							}
							@media (max-width: 767px) {
							}
							@media (max-width: 600px) {
							}
							@media (max-width: 480px) {
							}
						}
					}
				}
			}
		}

		ul.mdl_ftr {
			@media (max-width: 1300px) {
			}
			@media (max-width: 1200px) {
			}
			@media (max-width: 992px) {
			}
			@media (max-width: 767px) {
			}
			@media (max-width: 600px) {
			}
			@media (max-width: 480px) {
				padding: 20px 0;
			}

			li.ftr_sec {
				@media (max-width: 1300px) {
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
					width: 40%;
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
					width: 100%;
				}
				figure {
					@media (max-width: 1300px) {
						width: 126px;
						height: 46px;
					}
					@media (max-width: 1200px) {
					}
					@media (max-width: 992px) {
					}
					@media (max-width: 767px) {
					}
					@media (max-width: 600px) {
					}
					@media (max-width: 480px) {
						width: 50%;
						margin: 0 auto;
					}
				}

				p {
					@media (max-width: 1300px) {
						font-size: 11px;
						line-height: 25px;
					}
					@media (max-width: 1200px) {
					}
					@media (max-width: 992px) {
					}
					@media (max-width: 767px) {
					}
					@media (max-width: 600px) {
					}
					@media (max-width: 480px) {
					}
				}
			}

			li {
				@media (max-width: 1300px) {
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
					width: 40%;
					margin-bottom: 20px;
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
					width: 100%;
					text-align: center;
				}

				h4 {
					@media (max-width: 1300px) {
						font-size: 13px;
						padding-bottom: 10px;
					}
					@media (max-width: 1200px) {
					}
					@media (max-width: 992px) {
					}
					@media (max-width: 767px) {
					}
					@media (max-width: 600px) {
					}
					@media (max-width: 480px) {
					}
				}

				p {
					@media (max-width: 1300px) {
						font-size: 11px;
						line-height: 30px;
					}
					@media (max-width: 1200px) {
					}
					@media (max-width: 992px) {
					}
					@media (max-width: 767px) {
					}
					@media (max-width: 600px) {
					}
					@media (max-width: 480px) {
					}
				}
			}

			.ftr_sec2 {
				@media (max-width: 1300px) {
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
				}
				div {
					@media (max-width: 1300px) {
					}
					@media (max-width: 1200px) {
					}
					@media (max-width: 992px) {
					}
					@media (max-width: 767px) {
					}
					@media (max-width: 600px) {
					}
					@media (max-width: 480px) {
						width: 50%;
						margin: 0 auto;
						justify-content: center;
					}
					figure {
						@media (max-width: 1300px) {
						}
						@media (max-width: 1200px) {
						}
						@media (max-width: 992px) {
						}
						@media (max-width: 767px) {
						}
						@media (max-width: 600px) {
						}
						@media (max-width: 480px) {
						}
					}
				}
			}

			.lang_select {
				@media (max-width: 1300px) {
					height: 36px;
				}
				@media (max-width: 1200px) {
					height: 35px;
				}
				@media (max-width: 992px) {
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
					width: 50%;
					margin: 0 auto;
				}

				fieldset {
				}
			}
		}

		.btm_ftr {
			@media (max-width: 1300px) {
			}
			@media (max-width: 1200px) {
			}
			@media (max-width: 992px) {
			}
			@media (max-width: 767px) {
			}
			@media (max-width: 600px) {
			}
			@media (max-width: 480px) {
			}

			p {
				@media (max-width: 1300px) {
					font-size: 11px;
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
				}
			}
		}
	}
}

//sidebar header

.mnu {
	.itemlogo {
		background: #ffe8e8;
		box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
		border-radius: 0px 20px 0px 0px;
		padding: 20px 0;
		.logo {
			figure {
				// width: 130px;
				// height: 60px;
				width: 110px;
				height: 40px;
				margin: auto;
			}
		}
	}
	.menuitem {
		padding-top: 7px;
		padding-bottom: 7px;
		font-size: 14px;
		padding-left: 40px;
		color: $text;
		font-weight: 500;
		border: 1px solid transparent;
		cursor: pointer;
		&:hover {
			background: #fe6e6b;
			border: 1px solid rgba(86, 86, 86, 0.1);
			color: $white;
			font-weight: 500;
		}
	}
	.item {
		background: #fff4f4;
		padding: 10px 0px;
	}
	.btndiv {
		padding: 10px 10px 0 10px;
		button {
			width: 100%;
			margin-bottom: 10px;
			padding: 10px 20px;
			font-size: 12px;
		}
		.gry-txt {
			color: $text;
		}
	}
	.contatus {
		padding: 10px 10px;
		border-top: 1px solid #c9cdd5;
		p {
			font-size: 11px;
			line-height: 16px;
			color: $text;
			padding-top: 4px;
		}

		.inputdiv {
			display: flex;
			justify-content: space-between;
			margin: 16px 0 0px;
			.enter {
				background: #ffffff;
				border: 1px solid rgba(86, 86, 86, 0.1);
				border-radius: 79px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 49%;
				padding: 6px;
				figure {
					width: 20px;
					height: 20px;
				}
				input {
					border: none;
					outline: none;
					background: none;
					margin-left: 10px;
					width: 90%;
				}
			}
			.btndiv {
				width: 45%;
				padding: 0px;
				button {
					width: 100%;
					margin-bottom: 0px;
				}
			}
		}
		.contctdiv {
			background: #5c5f67;
			border-radius: 20px;
			padding: 20px;
			color: white;
			margin-top: 30px;
			position: relative;
			figure.clogoo {
				width: 90px;
				height: 80px;
				position: absolute;
				top: -23px;
				left: 36%;
			}
			.cdtop {
				display: flex;
				justify-content: space-between;

				.cdleft {
					width: 50%;
					h4 {
						font-size: 13px;
						font-weight: 400;
					}
					div {
						.lang_select {
							background: #4e5158;
							border: 1px solid #4e5158;
							border-radius: 10px;
							font-size: 12px;
							padding: 0px !important;
							color: $white;
							margin-top: 10px;
							height: 30px;

							fieldset {
								border: none;
							}
						}
					}
				}
				.cdRyt {
					width: 48%;
					.ftr_sec2 {
						h4 {
							font-size: 13px;
							font-weight: 400;
						}
						.flexdiv {
							display: flex;
							align-items: center;
							// padding-top: 10px;
							figure {
								width: 15px;
							}
							p {
								a {
									color: white;
									text-decoration: none;
								}
								color: $white;
								margin-left: 8px;
								border-bottom: 1px solid transparent;
								&:hover {
									border-bottom: 1px solid white;
								}
							}
						}
					}
				}
			}
			.cdbtm {
				// padding-top: 10px;
				h4 {
					font-size: 13px;
					font-weight: 400;
				}
				ul {
					display: flex;
					align-items: center;
					padding-top: 4px;
					li {
						margin-right: 16px;
						// padding: 6px 15px;
						// background: #ffffff;
						// border-radius: 8.16279px;
						cursor: pointer;
						// border: 1px solid $white;
						&:hover {
							// background: #5c5f67;
							// border: 1px solid $white;
						}
						figure {
							width: 18px;
							height: 18px;
							filter: brightness(10);
							&:hover {
								filter: brightness(100%);
							}
						}
					}
				}
			}
		}
	}
}

.mnu_itm_size {
	font-size: 12px !important;
}

.MuiDrawer-paperAnchorLeft {
	border-radius: 0 22px 22px 0 !important;
}
