@import '../../assets/theme/variable.scss';

section.sell_cpns {
	padding: 80px 0;
	.mn_sl_cpns {
		display: flex;
		justify-content: space-between;
		// align-items: center;
		flex-wrap: wrap;
		.sl_cpns_lft {
			width: 50%;
			.sell_lottie {
				width: 100%;
				height: 450px;
			}
		}
		.sl_cpns_ryt {
			width: 45%;
			padding-top: 70px;
			div {
				h2 {
					font-size: 36px;
					font-weight: 600;
					width: 80%;
					color: $secondary;
					line-height: 54px;
				}
				figure {
					width: 160px;
					height: 60px;
				}
			}
			p {
				font-size: 15px;
				width: 86%;
				font-weight: 400;
				line-height: 30px;
				padding: 20px 0;
				color: $text;
			}
			.btn2 {
				margin-left: 20px;
			}
		}
	}
}
section.sell-cpn-wrk {
	// background: url('../../../public/static/images/sell-coupon-background.png');
	background: #fb6662;
	position: relative;
	// min-height: 1470px;
	// padding: 100px 0;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
	// margin-top: 270px;
	margin-top: 180px;
	margin-bottom: 270px;
	z-index: 1;
	@media (max-width: 1300px) {
		margin-bottom: 170px;
	}
	&::before {
		content: '';
		position: absolute;
		background-image: url('../../../public/static/images/sell-coupon-shape.png');
		height: 350px;
		width: 100%;
		top: 99%;
		left: 0px;
		background-repeat: no-repeat;
		background-size: cover;
		@media (max-width: 1500px) {
			height: 280px;
		}
		@media (max-width: 1300px) {
			height: 240px;
		}
	}
	&::after {
		content: '';
		position: absolute;
		background-image: url('../../../public/static/images/sell-coupon-shape2.svg');
		background-repeat: no-repeat;
		height: 300px;
		width: 100%;
		background-size: cover;
		left: 0;
		top: -280px;
		@media (max-width: 1920px) {
			height: 310px;
			top: -305px;
		}
		@media (max-width: 1500px) {
			top: -285px;
		}
		@media (max-width: 1300px) {
			top: -245px;
			height: 260px;
		}
		@media (max-width: 1200px) {
			top: -205px;
			height: 230px;
		}
	}
	h2 {
		padding-top: 10px;
		text-align: center;
		color: $headings;
		font-size: 40px;

		// @media (max-width: 1920px) {
		// 	padding-top: 350px;
		// }
		// @media (max-width: 1700px) {
		// 	padding-top: 300px;
		// }
		// @media (max-width: 1500px) {
		// 	padding-top: 280px;
		// }
		// @media (max-width: 1440px) {
		// 	padding-top: 260px;
		// }
		// @media (max-width: 1300px) {
		// 	padding-top: 260px;
		// }

		span {
			color: $white;
			position: relative;
			&::before {
				content: '';
				position: absolute;
				background-image: url('../../../public/static/images/bg_dots.svg');
				height: 100px;
				width: 280px;
				background-repeat: no-repeat;
				background-size: contain;
				top: 86px;
				left: 50px;
			}
			&::after {
				content: '';
				position: absolute;
				background-image: url('../../../public/static/images/underlined_hdng2.svg');
				height: 30px;
				width: 100%;
				background-repeat: no-repeat;
				top: 50px;
				left: 0;
			}
		}
	}
	.sellcpn-wrk-cntnt {
		width: 70%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding-top: 30px;
		position: relative;
		gap: 20px;
		&::before {
			position: absolute;
			content: '';
			background: url('../../../public/static/images/plus-img.svg');
			background-repeat: no-repeat;
			height: 40px;
			width: 40px;
			opacity: 50%;
			background-size: contain;
			left: 50px;
			bottom: 80px;
		}
		@media (max-width: 1200px) {
			width: 85%;
		}

		.sellcpn_wrkcrd {
			width: 28%;
			padding: 10px 10px;
			border-radius: 20px;

			position: relative;
			background: $white;
			min-height: 320px;
			&:nth-child(1) {
				margin-top: 30px;
			}
			&:nth-child(2) {
				margin-top: 30px;
			}
			&:nth-child(3) {
				margin-top: 30px;
			}
			&:nth-child(4)::before {
				position: absolute;
				content: '';
				background: url('../../../public/static/images/sell-coupon-bgcircle.svg');
				height: 380px;
				width: 380px;
				background-repeat: no-repeat;
				background-size: contain;
				left: -310px;
				bottom: 150px;
				z-index: -1;
				@media (max-width: 1200px) {
					display: none;
				}
			}
			&:nth-child(5)::before {
				position: absolute;
				content: '';
				background: url('../../../public/static/images/sell-coupon-bglines.svg');
				height: 116px;
				width: 340px;
				background-repeat: no-repeat;
				background-size: contain;
				left: 160px;
				top: 50%;
				opacity: 50%;
				z-index: -1;
				@media (max-width: 1920px) {
					left: 260px;
				}
				@media (max-width: 1700px) {
					left: 180px;
				}
				@media (max-width: 1200px) {
					left: 120px;
					width: 290px;
				}
			}
			&:nth-child(5)::after {
				position: absolute;
				content: '';
				background: url('../../../public/static/images/plus-img.svg');
				width: 40px;
				height: 40px;
				background-size: contain;
				opacity: 50%;
				bottom: -310px;
				right: -220px;
				@media (max-width: 1920px) {
					bottom: -370px;
					right: -266px;
				}
				@media (max-width: 1500px) {
					bottom: -310px;
					right: -196px;
				}
			}

			.wrkcrd_img {
				height: 50px;
				width: 50px;
				background: rgba($secondary, 0.2);
				padding: 8px 10px;
				border-radius: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			h4 {
				font-size: 22px;
				font-weight: 500;
				padding: 10px 0;
				color: $text;
			}
			p {
				font-size: 12px;
				line-height: 24px;
				text-align: left;
				color: $text;
			}

			.crd_no {
				height: 160px;
				width: 40%;
				position: absolute;
				top: 10px;
				right: 10px;
			}
		}
	}
}

.sell-wt_cpfy_main {
	padding: 120px 0;
	position: relative;
	@media (max-width: 1500px) {
		padding: 70px 0;
	}
	@media (max-width: 1300px) {
		padding: 120px 0;
	}

	&::after {
		content: '';
		position: absolute;
		background-image: url('../../../public/static/images/bg-semicircle.svg');
		height: 100%;
		width: 290px;
		right: 0;
		top: 300px;
		background-repeat: no-repeat;
		background-size: contain;
		z-index: -1;
	}
	h2 {
		text-align: center;
		color: $headings;
		font-size: 36px;
		display: flex;
		justify-content: center;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			background-image: url('../../../public/static/images/bg_dots.svg');

			height: 100px;
			width: 370px;
			left: 150px;
			top: 125px;
			background-repeat: no-repeat;
			background-size: contain;
		}
		&::after {
			content: '';
			position: absolute;
			background-image: url('../../../public/static/images/bg-circle.svg');
			height: 70px;
			width: 75px;
			background-repeat: no-repeat;
			background-size: contain;
			opacity: 30%;
			right: 280px;
			top: 84px;
		}
		figure {
			margin: -6px 10px 0 10px;
			position: relative;
			@media (max-width: 1920px) {
				height: 75px;
				width: 160px;
			}
			@media (max-width: 1700px) {
				height: 73px;
				width: 155px;
			}
			@media (max-width: 1440px) {
				height: 70px;
				width: 129px;
			}
			&::after {
				content: '';
				position: absolute;
				background-image: url('../../../public/static/images/underlined_hdng.svg');
				height: 30px;
				width: 100%;
				background-repeat: no-repeat;
				top: 64px;
				left: 0;
				@media (max-width: 1440px) {
					top: 58px;
				}
			}
		}
		span {
			color: $secondary;
		}
	}
	.sellcpn-wrk-cntnt {
		width: 70%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		padding-top: 40px;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			background-image: url('../../../public/static/images/bg-triangle.svg');
			height: 260px;
			width: 300px;
			background-repeat: no-repeat;
			bottom: -16px;
			left: -130px;
			background-size: contain;
			opacity: 80%;
		}

		&::after {
			content: '';
			position: absolute;
			background-image: url('../../../public/static/images/bg-parallel.svg');
			height: 50px;
			width: 340px;
			background-repeat: no-repeat;
			bottom: -38px;
			right: 0;
			background-size: contain;
		}
		.sell_wt_cpfy_crd {
			width: 26%;
			padding: 10px 10px;
			border-radius: 20px;
			margin: 40px 0;
			background: $white;
			min-height: 250px;
			box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
			position: relative;

			.top_sellcpfy {
				position: relative;
				overflow: hidden;
				&::after {
					position: absolute;
					content: '';
					background: #fff;
					width: 70px;
					height: 74px;
					right: 0;
					bottom: 0;
					border-radius: 50%;
					bottom: -47px;
					right: 32px;
				}
				h4 {
					font-size: 21px;
					font-weight: 600;
					padding: 10px 20px;
					color: $headings;
					background: rgba($text, 0.08);
					height: 80px;
					display: flex;
					border-radius: 10px;
					align-items: center;
					margin: 0;
				}
			}
			figure {
				height: 22px;
				width: 22px;
				position: absolute;
				right: 50px;
				top: 90px;
				background: white;
				padding: 10px;
				border-radius: 50%;
				border: 5px solid rgba($text, 0.1);
			}
			p {
				font-size: 12px;
				line-height: 24px;
				text-align: center;
				color: $text;
				padding-top: 40px;
			}
		}
	}
}

// responsive-------
section.sell_cpns {
	@media (max-width: 992px) {
		padding: 50px 0;
	}
	.mn_sl_cpns {
		.sl_cpns_lft {
			@media (max-width: 992px) {
				width: 100%;
			}
			figure {
				@media (max-width: 1300px) {
					height: 420px;
				}
				@media (max-width: 1200px) {
					height: 390px;
				}
				@media (max-width: 992px) {
					width: 100%;
					height: 360px;
				}
				@media (max-width: 767px) {
					height: 340px;
				}
				@media (max-width: 600px) {
					height: 320px;
				}
			}
		}
		.sl_cpns_ryt {
			@media (max-width: 992px) {
				width: 100%;
				text-align: center;
			}
			@media (max-width: 600px) {
				padding-top: 40px;
			}
			div {
				h2 {
					@media (max-width: 1300px) {
						font-size: 32px;
						line-height: 50px;
					}
					@media (max-width: 1200px) {
						width: 100%;
						font-size: 30px;
						line-height: 46px;
					}
					@media (max-width: 992px) {
						font-size: 28px;
					}
					@media (max-width: 767px) {
						font-size: 25px;
					}
					@media (max-width: 600px) {
						font-size: 20px;
					}
				}
				figure {
					@media (max-width: 1300px) {
						width: 155px;
						height: 56px;
					}
					@media (max-width: 1200px) {
						width: 150px;
						height: 52px;
					}
					@media (max-width: 992px) {
						width: 100%;
						height: 48px;
					}
					@media (max-width: 767px) {
						height: 46px;
					}
					@media (max-width: 600px) {
						height: 44px;
					}
				}
			}
			p {
				@media (max-width: 1300px) {
					font-size: 14px;
					line-height: 26px;
				}
				@media (max-width: 1200px) {
					font-size: 12px;
					line-height: 24px;
				}
				@media (max-width: 992px) {
					width: 100%;
				}
				@media (max-width: 767px) {
					padding: 10px 0;
				}
			}
		}
	}
}

section.sell-cpn-wrk {
	@media (max-width: 2000px) {
		// min-height: 2160px;
		// background-repeat: no-repeat;
	}
	&::before {
		@media (max-width: 1300px) {
		}
		@media (max-width: 1200px) {
		}
		@media (max-width: 992px) {
		}
		@media (max-width: 767px) {
		}
		@media (max-width: 600px) {
		}
		@media (max-width: 480px) {
		}
	}
	&::after {
		@media (max-width: 1300px) {
		}
		@media (max-width: 1200px) {
		}
		@media (max-width: 992px) {
		}
		@media (max-width: 767px) {
		}
		@media (max-width: 600px) {
		}
		@media (max-width: 480px) {
		}
	}
	h2 {
		@media (max-width: 1300px) {
			// padding-top: 220px;
			font-size: 36px;
		}
		@media (max-width: 1200px) {
		}
		@media (max-width: 992px) {
		}
		@media (max-width: 767px) {
		}
		@media (max-width: 600px) {
		}
		@media (max-width: 480px) {
		}

		span {
			@media (max-width: 1300px) {
			}
			@media (max-width: 1200px) {
			}
			@media (max-width: 992px) {
			}
			@media (max-width: 767px) {
			}
			@media (max-width: 600px) {
			}
			@media (max-width: 480px) {
			}
			&::before {
				@media (max-width: 1300px) {
					width: 240px;
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
				}
			}
			&::after {
				@media (max-width: 1300px) {
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
				}
			}
		}
	}
	.sellcpn-wrk-cntnt {
		@media (max-width: 1300px) {
		}
		@media (max-width: 1200px) {
		}
		@media (max-width: 992px) {
		}
		@media (max-width: 767px) {
		}
		@media (max-width: 600px) {
		}
		@media (max-width: 480px) {
		}

		.sellcpn_wrkcrd {
			@media (max-width: 1300px) {
				min-height: 290px;
				// margin-bottom: 20px !important;
			}
			@media (max-width: 1200px) {
			}
			@media (max-width: 992px) {
			}
			@media (max-width: 767px) {
			}
			@media (max-width: 600px) {
			}
			@media (max-width: 480px) {
			}
			.wrkcrd_img {
				@media (max-width: 1300px) {
					height: 40px;
					width: 40px;
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
				}
			}

			h4 {
				@media (max-width: 1300px) {
					font-size: 20px;
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
				}
			}
			p {
				@media (max-width: 1300px) {
					font-size: 11px;
					line-height: 22px;
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
				}
			}

			.crd_no {
				@media (max-width: 1300px) {
					height: 144px;
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
				}
			}
		}
	}
}

.sell-wt_cpfy_main {
	@media (max-width: 2000px) {
	}
	@media (max-width: 1700px) {
	}
	@media (max-width: 1440px) {
	}
	@media (max-width: 1300px) {
	}
	@media (max-width: 1200px) {
	}
	@media (max-width: 992px) {
	}
	@media (max-width: 767px) {
	}
	@media (max-width: 600px) {
	}
	@media (max-width: 480px) {
	}
	&::after {
		@media (max-width: 2000px) {
		}
		@media (max-width: 1700px) {
		}
		@media (max-width: 1440px) {
		}
		@media (max-width: 1300px) {
		}
		@media (max-width: 1200px) {
		}
		@media (max-width: 992px) {
			display: none;
		}
		@media (max-width: 767px) {
		}
		@media (max-width: 600px) {
		}
		@media (max-width: 480px) {
		}
	}
	h2 {
		@media (max-width: 2000px) {
			font-size: 46px;
		}
		@media (max-width: 1700px) {
			font-size: 40px;
		}
		@media (max-width: 1500px) {
			font-size: 38px;
		}
		@media (max-width: 1440px) {
			font-size: 36px;
		}
		@media (max-width: 1300px) {
			font-size: 32px;
		}
		@media (max-width: 1200px) {
			font-size: 30px;
		}
		@media (max-width: 992px) {
			font-size: 28px;
		}
		@media (max-width: 767px) {
			font-size: 26px;
		}
		@media (max-width: 600px) {
			font-size: 20px;
		}
		@media (max-width: 480px) {
			font-size: 24px;
			display: block;
		}
		&::before {
			@media (max-width: 2000px) {
			}
			@media (max-width: 1700px) {
			}
			@media (max-width: 1440px) {
			}
			@media (max-width: 1300px) {
			}
			@media (max-width: 1200px) {
			}
			@media (max-width: 992px) {
				display: none;
			}
			@media (max-width: 767px) {
			}
			@media (max-width: 600px) {
			}
			@media (max-width: 480px) {
			}
		}
		&::after {
			@media (max-width: 2000px) {
			}
			@media (max-width: 1700px) {
			}
			@media (max-width: 1440px) {
			}
			@media (max-width: 1300px) {
			}
			@media (max-width: 1200px) {
			}
			@media (max-width: 992px) {
				display: none;
			}
			@media (max-width: 767px) {
			}
			@media (max-width: 600px) {
			}
			@media (max-width: 480px) {
			}
		}
		figure {
			@media (max-width: 2000px) {
			}
			@media (max-width: 1700px) {
			}
			@media (max-width: 1440px) {
			}
			@media (max-width: 1300px) {
				height: 50px;
			}
			@media (max-width: 1200px) {
				height: 48px;
			}
			@media (max-width: 992px) {
				height: 44px;
			}
			@media (max-width: 767px) {
			}
			@media (max-width: 600px) {
				height: 36px;
			}
			@media (max-width: 480px) {
			}

			&::after {
				@media (max-width: 2000px) {
				}
				@media (max-width: 1700px) {
				}
				@media (max-width: 1440px) {
				}
				@media (max-width: 1300px) {
					top: 52px;
				}
				@media (max-width: 1200px) {
					top: 50px;
				}
				@media (max-width: 992px) {
					top: 46px;
					height: 20px;
					width: 145px;
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
					display: none;
				}
				@media (max-width: 480px) {
				}
			}
		}
		span {
			@media (max-width: 2000px) {
			}
			@media (max-width: 1700px) {
			}
			@media (max-width: 1440px) {
			}
			@media (max-width: 1300px) {
			}
			@media (max-width: 1200px) {
			}
			@media (max-width: 992px) {
			}
			@media (max-width: 767px) {
			}
			@media (max-width: 600px) {
			}
			@media (max-width: 480px) {
			}
		}
	}
	.sellcpn-wrk-cntnt {
		@media (max-width: 2000px) {
		}
		@media (max-width: 1700px) {
		}
		@media (max-width: 1440px) {
		}
		@media (max-width: 1300px) {
		}
		@media (max-width: 1200px) {
		}
		@media (max-width: 992px) {
		}
		@media (max-width: 767px) {
		}
		@media (max-width: 600px) {
		}
		@media (max-width: 480px) {
		}
		&::before {
			@media (max-width: 2000px) {
			}
			@media (max-width: 1700px) {
			}
			@media (max-width: 1440px) {
			}
			@media (max-width: 1300px) {
			}
			@media (max-width: 1200px) {
			}
			@media (max-width: 992px) {
				display: none;
			}
			@media (max-width: 767px) {
			}
			@media (max-width: 600px) {
			}
			@media (max-width: 480px) {
			}
		}

		&::after {
			@media (max-width: 2000px) {
			}
			@media (max-width: 1700px) {
			}
			@media (max-width: 1440px) {
			}
			@media (max-width: 1300px) {
			}
			@media (max-width: 1200px) {
			}
			@media (max-width: 992px) {
				display: none;
			}
			@media (max-width: 767px) {
			}
			@media (max-width: 600px) {
			}
			@media (max-width: 480px) {
			}
		}
		.sell_wt_cpfy_crd {
			@media (max-width: 2000px) {
				min-height: 330px;
			}
			@media (max-width: 1700px) {
				min-height: 290px;
			}
			@media (max-width: 1440px) {
				min-height: 270px;
			}
			@media (max-width: 1300px) {
				min-height: 260px;
			}
			@media (max-width: 1200px) {
				min-height: 220px;
				margin-bottom: 20px !important;
			}
			@media (max-width: 992px) {
				width: 40%;
				margin: 20px 0 !important;
			}
			@media (max-width: 767px) {
			}
			@media (max-width: 600px) {
				width: 100%;
				margin: 10px 0 !important;
			}
			@media (max-width: 480px) {
			}

			.top_sellcpfy {
				@media (max-width: 2000px) {
				}
				@media (max-width: 1700px) {
				}
				@media (max-width: 1440px) {
				}
				@media (max-width: 1300px) {
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
				}
				&::after {
					@media (max-width: 2000px) {
					}
					@media (max-width: 1700px) {
					}
					@media (max-width: 1440px) {
					}
					@media (max-width: 1300px) {
						right: 24px;
						width: 65px;
					}
					@media (max-width: 1200px) {
					}
					@media (max-width: 992px) {
					}
					@media (max-width: 767px) {
					}
					@media (max-width: 600px) {
					}
					@media (max-width: 480px) {
					}
				}
				h4 {
					@media (max-width: 2000px) {
						font-size: 28px;
					}
					@media (max-width: 1700px) {
						font-size: 25px;
					}
					@media (max-width: 1500px) {
						font-size: 23px;
					}
					@media (max-width: 1440px) {
						font-size: 21px;
					}
					@media (max-width: 1300px) {
						font-size: 20px;
						height: 70px;
					}
					@media (max-width: 1200px) {
						font-size: 18px;
						height: 70px;
					}
					@media (max-width: 992px) {
					}
					@media (max-width: 767px) {
					}
					@media (max-width: 600px) {
					}
					@media (max-width: 480px) {
					}
				}
			}
			figure {
				@media (max-width: 2000px) {
					height: 26px;
					width: 26px;
				}
				@media (max-width: 1700px) {
					height: 24px;
					width: 24px;
				}
				@media (max-width: 1440px) {
				}
				@media (max-width: 1300px) {
					height: 18px;
					width: 18px;
					top: 80px;
					right: 43px;
				}
				@media (max-width: 1200px) {
				}
				@media (max-width: 992px) {
				}
				@media (max-width: 767px) {
				}
				@media (max-width: 600px) {
				}
				@media (max-width: 480px) {
				}
			}
			p {
				@media (max-width: 2000px) {
					font-size: 16px;
					line-height: 30px;
					padding-top: 50px;
				}
				@media (max-width: 1700px) {
					font-size: 14px;
					line-height: 28px;
				}
				@media (max-width: 1500px) {
					font-size: 13px;
					line-height: 26px;
				}
				@media (max-width: 1440px) {
					font-size: 12px;
					line-height: 22px;
				}
				@media (max-width: 1300px) {
					font-size: 11px;
					line-height: 20px;
					padding-top: 44px;
				}
				@media (max-width: 1200px) {
					font-size: 10px;
					line-height: 18px;
					// padding-top: 30px;
				}
			}
		}
	}
}
