@import '../../assets/theme/variable.scss';

.accnt {
	padding-top: 80px;
	position: relative;
	&::before {
		position: absolute;
		content: '';
		background: url('../../../public/static/images/account-bg1.png');
		height: 370px;
		width: 640px;
		background-repeat: no-repeat;
		top: 0;
		z-index: -1;
		left: 0;
		background-size: 100% 100%;
	}
	&::after {
		position: absolute;
		content: '';
		background: url('../../../public/static/images/account-bg2.png');
		height: 960px;
		width: 500px;
		background-repeat: no-repeat;
		top: 40px;
		z-index: -1;
		right: 0;
		background-size: 100% 100%;
	}
	.accMain {
		display: flex;
		padding-top: 40px;
		justify-content: space-between;
		margin-bottom: 50px !important;
		width: 90%;
		margin: 0 auto;
		@media (max-width: 1200px) {
			justify-content: flex-start;
			width: 100%;
		}

		.accleft {
			width: 23%;
			background: #fe6e6b;
			border-radius: 16px;
			margin-right: 20px;
			min-width: 260px;
			@media (max-width: 1920px) {
				width: 19%;
			}
			.accsidebar {
				.acctop {
					display: flex;
					padding: 20px 10px;
					align-items: center;
					gap: 8px;

					.atleft {
						figure {
							width: 60px;
							height: 60px;
							border-radius: 100px;
							overflow: hidden;
							cursor: pointer;
							background: white;
							// border: 3px solid white;
							// outline: 3px solid black;

							img {
								object-fit: cover;
								height: 100%;
								// filter: invert(30%) sepia(0%) saturate(0%)
								// 	hue-rotate(167deg) brightness(98%)
								// 	contrast(80%);
							}
						}
					}

					.atright {
						h2 {
							color: $text;
							cursor: pointer;
							font-size: 15px;
							font-weight: 500;
						}
						.mebr {
							border-radius: 3px;
							background: #fff;
							display: flex;
							align-items: center;
							padding: 0px 6px;
							margin: 4px 0 6px;
							width: 65%;
							figure {
								width: 10px;
								padding-right: 5px;
								line-height: 0;
							}
						}
						h5 {
							color: $para;
							font-size: 10px;
							font-weight: normal;
						}
					}
					.bar {
						display: flex;
						align-items: center;
						justify-content: space-between;
						figure {
							line-height: 0;
						}
						h5 {
							color: #fff;
							font-weight: normal;
							font-size: 10px;
						}
					}
					p {
						color: #fff;
						font-weight: normal;
						font-size: 10px;
					}
				}
			}
			.accbtm {
				ul {
					li {
						margin-bottom: 8px;
						background: transparent;
						cursor: pointer;
						position: relative;
						&:nth-child(7)::after {
							background: #565656 !important;
							opacity: 10%;
							width: 100%;
							height: 1px;
							bottom: -20px;
							content: '';
							position: absolute;
						}
						&:nth-child(8) {
							margin-top: 35px;
						}

						.accmain {
							display: flex;
							width: 100%;
							padding: 10px 10px 10px 40px;
							box-sizing: border-box;
							text-align: left;
							background: #ff9693;
							border-radius: 6px;

							figure {
								width: 18px;
								line-height: 0;
								padding-right: 10px;
								img {
									filter: brightness(2);
								}
							}

							h3 {
								color: #fff;
								font-weight: normal;
								font-size: 14px;
								// word-break: break-word;
							}
						}

						&:hover {
							background: #ff9693;
							border-radius: 5px;
						}
						.accmain2 {
							display: flex;
							width: 100%;
							padding: 10px 10px 10px 40px;
							box-sizing: border-box;
							text-align: left;
							figure {
								width: 18px;
								padding-right: 10px;
								line-height: 0;
								img {
									filter: brightness(2);
								}
							}

							h3 {
								color: #fff;
								font-weight: normal;
								font-size: 14px;
								// word-break: break-word;
							}
						}
					}
				}
			}
		}
	}

	.accright {
		width: 74%;
		@media (max-width: 1920px) {
			width: 79%;
		}
		@media (max-width: 1200px) {
			width: 76%;
		}
		@media (max-width: 1024px) {
			width: 71%;
		}

		h3 {
			color: #253d4e;
			font-size: 18px;
			padding-bottom: 4px;
			font-weight: 600;
		}
		p {
			color: $text;
			font-size: 14px;
		}
	}

	//modal
	.sharemodal {
		width: 100%;
		margin: auto;
		.crossbar {
			background: #565656;
			opacity: 0.5;
			border-radius: 15px 15px 0px 0px;
			padding: 10px;
			figure {
				width: 35px;
				height: 35px;
				margin-left: auto;
				cursor: pointer;
			}
		}
		.sharedata {
			padding: 20px 0;
			.Gmail {
				width: 30%;
				margin: auto;
				background: #ffffff;
				box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
				border-radius: 500px;
				padding: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				figure {
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}
			}
			.sharediv {
				width: 70%;
				margin: auto;
				padding-top: 20px;
				h3 {
					font-size: 16px;
					color: $text;
				}
				ul {
					display: flex;
					width: 100%;
					justify-content: space-between;
					padding: 14px 0;
					li {
						padding: 6px 15px;
						border: 1px solid rgba(86, 86, 86, 0.2);
						// background: rgba(254, 110, 107, 0.1);
						// border: 1px solid rgba(254, 110, 107, 0.3);
						border-radius: 10px;
						// margin-right: 20px;
						&:hover {
							background: rgba(254, 110, 107, 0.1);
							border: 1px solid rgba(254, 110, 107, 0.3);
							img {
								filter: none !important;
							}
						}
						figure {
							width: 20px;
							height: 20px;
						}
						img {
							filter: brightness(0.2);
						}
					}
				}
				.copycode {
					display: flex;
					align-items: center;
					.MuiFormControl-root {
						width: 90%;
						margin-right: 14px;
						background: rgba(86, 86, 86, 0.02) !important;
						border-bottom: 1px solid rgba(86, 86, 86, 0.2) !important;
						border-radius: 5px !important;
						// margin-bottom: 20px !important;
					}
					.w_100 {
						width: 100% !important;
					}
					.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
						background: none !important;
						border: none !important;
					}
					.css-2y464i-MuiInputBase-root-MuiFilledInput-root:before {
						border-bottom: none !important;
					}
					h3 {
						font-size: 14px;
						color: $secondary;
						font-weight: 600;
					}
				}
			}
		}
	}

	fieldset {
		display: none !important;
	}

	.accMain .accleft .accsidebar .accbtm ul li .accmain2 h3 {
		@media (max-width: 1366px) {
			word-break: break-word;
		}
	}
	.accMain .accleft .accsidebar .accbtm ul li .accmain h3 {
		@media (max-width: 1366px) {
			word-break: break-word;
		}
	}
}
