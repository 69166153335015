@import '../../assets/theme/variable.scss';

.personalinfo {
	padding-top: 20px;
	width: 90%;
	.profilediv {
		figure {
			width: 140px;
			height: 140px;
			border-radius: 100px;
			overflow: hidden;
			background: white;
			border: 1px solid $secondary;
			// outline: 3px solid black;
			img {
				height: 100%;
				object-fit: cover;
				// filter: invert(30%) sepia(0%) saturate(0%) hue-rotate(167deg)
				// 	brightness(98%) contrast(80%);
			}
		}
		.uploader {
			position: relative;
			figure {
				cursor: pointer;
				position: absolute;
				width: 20px;
				height: 20px;
				background: $secondary;
				padding: 6px;

				top: -46px;
				left: 105px;
				img {
					height: 100%;
					object-fit: contain;
					filter: none;
				}
			}
			input#myfile {
				display: none;
			}
		}
		.inputdiv,
		.inputdiv2 {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 20px 0;
			border-bottom: 1px solid rgba(86, 86, 86, 0.1);

			.info-inpt {
				width: 49%;
				// margin-right: 14px;

				border-radius: 5px !important;
				margin-bottom: 20px !important;
				@media (max-width: 1130px) {
					width: 100%;
				}
				div {
					background: white !important;
					border: 1px solid rgba($secondary, 0.3);
					// border-bottom: 1px solid rgba(86, 86, 86, 0.2) !important;
				}
			}
			.w_100 {
				width: 100% !important;
			}
			.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
				background: none !important;
				border: none !important;
			}
			.css-2y464i-MuiInputBase-root-MuiFilledInput-root:before {
				border-bottom: none !important;
			}
			.btndiv {
				width: 100%;
				display: flex;
				justify-content: flex-end;
			}
			input {
				// background: rgba(217, 217, 217, 0.1019607843);
				background: white;
				border-radius: 10px;
				padding: 15px 12px;
				color: #565656;
				font-weight: 400;
				border-bottom: transparent;
				&::-webkit-input-placeholder {
					color: #565656;
					font-weight: 400;
				}
			}
			//@at-root
		}
		.address {
			padding: 30px 0;
			h2 {
				font-size: 20px;
				padding-bottom: 20px;
				font-weight: 500;
				color: #3c3c3c;
			}
			ul {
				display: flex;
				justify-content: flex-start;
				align-items: stretch;

				.billing-adrss {
					width: 21%;
					background: $white;
					display: flex;
					justify-content: space-between;
					box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
					border-radius: 10px;
					padding: 14px;
					margin-right: 20px;
					margin-bottom: 20px;
					@media (max-width: 1920px) {
						width: 18%;
					}
					@media (max-width: 1700px) {
						width: 20%;
					}
					@media (max-width: 1500px) {
						width: 22%;
					}
					@media (max-width: 1440px) {
						width: 24%;
					}
					@media (max-width: 1300px) {
						width: 27%;
					}
					@media (max-width: 1200px) {
						width: 25%;
					}

					.bill-ryt {
						h3 {
							font-size: 14px;
							color: #253d4e;
						}
						p {
							font-size: 12px;
							padding-top: 10px;
							width: 65%;
							color: #858c8e;
							@media (max-width: 1700px) {
								width: 66%;
							}
							@media (max-width: 1440px) {
								width: 68%;
							}
							@media (max-width: 1200px) {
								width: 80%;
							}
						}
					}
				}
				.body {
					figure {
						width: 30px;
						height: 20px;
						cursor: pointer;
						margin: 0 0 0 auto;
						img {
							object-fit: contain;
						}
					}
				}
				.shipping-adrss {
					width: 27%;
					background: $white;
					display: flex;
					justify-content: space-between;
					box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
					border-radius: 10px;
					padding: 14px;
					margin-right: 20px;
					margin-bottom: 20px;
					@media (max-width: 1920px) {
						width: 23%;
					}
					@media (max-width: 1700px) {
						width: 25%;
					}
					@media (max-width: 1500px) {
						width: 27%;
					}
					@media (max-width: 1440px) {
						width: 30%;
					}
					@media (max-width: 1300px) {
						width: 34%;
					}
					@media (max-width: 1200px) {
						width: 35%;
					}
					.ship-ryt {
						width: 10%;
						span {
							color: #253d4e;
							padding: 0 !important;
							margin-right: 30px;
						}
					}
					.ship-mdl {
						width: 64%;
						h3 {
							font-size: 14px;
							color: #253d4e;
							line-height: 26px;
						}
						p {
							font-size: 12px;
							padding-top: 10px;
							width: 90%;
							color: #858c8e;
							@media (max-width: 1700px) {
								width: 87%;
							}
						}
						figure {
							width: 20px;
							height: 20px;
							cursor: pointer;
							img {
								object-fit: contain;
							}
						}
					}
					.body {
						width: 10%;
						justify-content: space-between;
						display: flex;
						flex-direction: column;

						p {
							color: #acacac;
							font-size: 14px;
						}
						figure {
							width: 30px;
							height: 20px;
							cursor: pointer;
							margin: 0 0 0 auto;
							img {
								object-fit: contain;
							}
						}
					}
				}
			}
		}
		.paymentmethod {
			padding-top: 20px;
			h2 {
				font-size: 20px;
				padding-bottom: 20px;
				font-weight: 500;
				color: #565656;
			}
			.paymain {
				display: flex;
				.payoption {
					background: $secondary;
					margin-right: 20px;
					padding: 10px 7px;
					border-radius: 10px;
					width: 117px;
					cursor: pointer;
					box-shadow: 0 12px 25px #ff000085;
					figure {
						width: 40px;
						height: 24px;
						border-radius: 0px;
						margin: auto;
						border: none;
						outline: none;
						background: none;

						img {
							object-fit: contain;
						}
					}
					p {
						color: $white;
						font-size: 12px;
						padding-top: 6px;
						text-align: center;
					}
				}
			}
		}
		.cardInfo {
			width: 50%;
			padding-top: 30px;
			.MuiFormControl-root {
				margin-right: 14px;
				background: white !important;
				border: 1px solid rgba($secondary, 0.3);
				border-radius: 5px !important;
				margin-bottom: 20px !important;
			}
			.w_100 {
				width: 100% !important;
			}
			.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
				background: none !important;
				border: none !important;
			}
			.css-2y464i-MuiInputBase-root-MuiFilledInput-root:before {
				border-bottom: none !important;
			}
			.cvv {
				display: flex;
				width: 100%;
				justify-content: space-between;
				.MuiFormControl-root {
					width: 49%;
					margin-right: 0px;
					background: white !important;
					border: 1px solid rgba($secondary, 0.3);
					border-radius: 5px !important;
					margin-bottom: 20px !important;
				}
				.w_100 {
					width: 100% !important;
				}
				.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
					background: none !important;
					border: none !important;
				}
				.css-2y464i-MuiInputBase-root-MuiFilledInput-root:before {
					border-bottom: none !important;
				}
			}
			.btndiv {
				width: 100%;
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}

//dashboard

.dashboardmain {
	.dtop {
		margin: 30px 0;
		background: linear-gradient(74.43deg, #ffffff 2.15%, #ffffff 98.88%);
		border: 1px solid rgba(86, 86, 86, 0.1);
		border-radius: 20px;
		padding: 40px;
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		ul {
			display: flex;
			justify-content: space-between;
			width: 70%;
			margin: auto;
			align-items: center;
			li {
				width: 20%;
				position: relative;
				figure {
					width: 70px;
					height: 70px;
					margin: auto;
					border-radius: 100%;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 100%;
					}
				}
				.silverstar {
					position: absolute;
					width: 35px !important;
					height: 36px !important;
					top: 47%;
					left: 62%;
				}

				h6 {
					font-size: 13px;
					text-align: center;
					padding-top: 6px;
					color: #ab5704;
				}
				p {
					font-size: 13px;
					text-align: center;
					color: #ab5704;
					line-height: 11px;
				}
				&:nth-child(1)::after {
					content: '';
					position: absolute;
					background: url(../../../public/static/images/rightarrow.svg);
					background-size: 100%;
					width: 208px;
					height: 40px;
					background-repeat: no-repeat;
					top: 27%;
					left: 76%;
					@media (max-width: 1800px) {
						width: 188px;
					}
					@media (max-width: 1600px) {
						width: 158px;
						left: 83%;
					}
					@media (max-width: 1500px) {
						width: 148px;
						left: 83%;
					}
					@media (max-width: 1400px) {
						width: 128px;
						left: 83%;
					}
					@media (max-width: 1300px) {
						width: 113px;
						left: 88%;
					}
					@media (max-width: 1200px) {
						width: 93px;
						left: 88%;
					}
					@media (max-width: 1100px) {
						width: 100px;
						left: 93%;
					}
					@media (max-width: 1050px) {
						width: 89px;
					}
					@media (max-width: 997px) {
						width: 75px;
					}
				}
				&:nth-child(2)::after {
					content: '';
					position: absolute;
					background: url(../../../public/static/images/leftarray.svg);
					background-size: 100%;
					width: 208px;
					height: 40px;
					background-repeat: no-repeat;
					top: 3%;
					left: 85%;
					@media (max-width: 1800px) {
						width: 188px;
					}
					@media (max-width: 1600px) {
						width: 158px;
						left: 92%;
						top: 13%;
					}
					@media (max-width: 1500px) {
						width: 148px;
					}
					@media (max-width: 1400px) {
						width: 128px;
						left: 96%;
					}
					@media (max-width: 1300px) {
						width: 113px;
						left: 100%;
					}
					@media (max-width: 1200px) {
						width: 93px;
						top: 17%;
						left: 103;
					}
					@media (max-width: 1100px) {
						width: 100px;
						// left: 93%;
						left: 108%;
					}
					@media (max-width: 1050px) {
						width: 89px;
					}
					@media (max-width: 997px) {
						width: 75px;
						left: 116%;
					}
				}
			}
			&:nth-child(2) {
				p {
					color: 'red';
				}
			}
		}
	}
	.dBtm {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		.dBleft {
			box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
			background: linear-gradient(
				74.43deg,
				#ffffff 2.15%,
				#ffffff 98.88%
			);
			border: 1px solid rgba(86, 86, 86, 0.1);
			border-radius: 20px;
			// width: 56%;
			width: 65%;
			padding: 20px;
			@media (max-width: 1440px) {
				width: 63%;
			}
			@media (max-width: 1281px) {
				width: 58%;
			}
			@media (max-width: 1000px) {
				width: 55%;
			}
			p {
				color: #acacac;
				font-size: 14px;
				span {
					color: #253d4e;
					font-weight: 600;
				}
			}
			.dBright {
				ul {
					padding-top: 20px;
					@media (max-width: 1000px) {
						padding: 10px;
					}
					li {
						position: relative;
						.dashline {
							display: flex;
							justify-content: space-between;
							padding: 8px;
							// margin-bottom: 1px;
							background: #f7f7f7;
							@media (max-width: 1000px) {
								padding: 5px;
							}
							.dashicon {
								display: flex;
								width: 50%;
								align-items: center;
								figure {
									width: 20px;
									height: 20px;
									background: $white;
									padding: 7px;
									box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
									border-radius: 7px;
									margin-right: 19px;
								}
								h5 {
									font-size: 14px;
									font-weight: 500;
									color: $text;
								}
							}
							.dashpoint {
								display: flex;
								// width: 10%;
								justify-content: space-between;

								align-items: center;
								p {
									color: #acacac;
									font-size: 14px;
									padding-right: 20px;
								}
								figure {
									width: 30px;
									height: 30px;
									margin-left: 30px;
								}
							}
						}
					}
				}
			}
		}
		.dBRight {
			box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
				rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
				rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;

			background: #fe6e6b;
			width: 27%;
			padding: 20px 10px;
			border-radius: 20px;
			@media (max-width: 1281px) {
				width: 31%;
			}

			.perntdiv {
				background: linear-gradient(
					147.18deg,
					rgba(255, 255, 255, 0.8) -8.06%,
					rgba(255, 255, 255, 0.1) 93.94%
				);
				border-bottom: 1px solid $white;
				box-shadow: 0px 30px 15px -15px rgba(0, 0, 0, 0.15);
				border-radius: 20px;
				width: 45%;
				margin: auto;
				padding: 15px;
				.pernt {
					background: linear-gradient(
						107.33deg,
						rgba(230, 230, 230, 0.8) 0%,
						rgba(213, 213, 213, 0.1) 100%
					);
					background-blend-mode: multiply;
					border-bottom: 1px solid $white;
					border-radius: 11px;
					h2 {
						font-size: 35px;
						color: $white;
						text-align: center;
						@media (max-width: 1000px) {
							font-size: 25px;
						}
					}
				}
			}
			figure {
				width: 90%;
				margin: auto;
				margin-top: 20px;
			}
			p {
				color: $white;
				font-size: 14px;
				text-align: center;
				@media (max-width: 1000px) {
					font-size: 12px;
				}
			}
		}
	}
}
.grph-size2 {
	height: 40px;
	width: 40px;
	line-height: 0;
}
.arrw-img {
	width: 10px !important;
	height: 10px !important;
	margin-left: 0 !important;
}
.dtop li:nth-child(2) h6 {
	color: #858c8e !important;
}
.dtop li:nth-child(2) p {
	color: #858c8e !important;
}
.dtop li:nth-child(3) h6 {
	color: #be7e00 !important;
}
.dtop li:nth-child(3) p {
	color: #be7e00 !important;
}
.dtop li:nth-child(2) figure {
	width: 80px !important;
	height: 80px !important;
}
.dtop li:nth-child(1)::after {
	background: url('../../../public/static/images/rightarrow.svg');
	background-size: 100%;
	width: 40px;
	height: 30px;
}
