@import '../../assets/theme/variable.scss';

//chat with me

.chatmain {
	.chatcontainer {
		margin-top: 30px;
		padding: 10px;
		background: #ffff;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		border-radius: 20px;
		display: flex;
		justify-content: space-between;
		.chatleft {
			width: 40%;
			background: $white;
			padding: 10px;
			border-radius: 15px;
			@media (max-width: 1200px) {
				width: 45%;
			}
			.chatbtn {
				display: flex;
				button.chat {
					background: $white;
					border: 1px solid #e4e4e4;
					border-radius: 5px;
					color: $secondary;
					font-size: 13px;
					padding: 10px 35px;
					margin-right: 20px;
					cursor: pointer;
					@media (max-width: 1200px) {
						padding: 10px 35px;
					}
					@media (max-width: 1100px) {
						padding: 10px 25px;
					}
				}
				button.vendorlist {
					background: #ffffff;
					border: 1px solid #e4e4e4;
					border-radius: 5px;
					color: $text;
					font-size: 13px;
					padding: 10px 35px;
					margin-right: 20px;
					cursor: pointer;
					@media (max-width: 1200px) {
						padding: 10px 35px;
					}
					@media (max-width: 1100px) {
						padding: 10px 25px;
					}
				}
			}
			.searc {
				display: flex;
				padding: 10px;
				border-radius: 6px;
				border: 2px solid #e4e4e4;
				font-size: 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 15px;
				&:visited {
					border: 2px solid red;
				}
				input {
					border: none;
					background: no-repeat;
					outline: none;
					width: 100%;
				}
				svg {
					color: $para;
				}
			}
			.users {
				ul {
					li {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 0px 10px;
						background: #ffffff;
						border: 1px solid #e4e4e4;
						border-radius: 9px;
						margin-top: 10px;
						cursor: pointer;
						&:hover {
							border: 1px solid #3bb77e;
						}
						.dbleft {
							display: flex;
							align-items: center;
							.ppic {
								margin-right: 10px;
								position: relative;
								h1 {
									width: 50px;
									background: #00baf2;
									height: 50px;
									border-radius: 100%;
									color: white;
									font-size: 34px;
									font-weight: 600;
									display: flex;
									justify-content: center;
									align-items: center;
								}
								.onlined {
									width: 10px;
									height: 10px;
									background: #3bb77e;
									border-radius: 100%;
									position: absolute;
									border: 1px solid white;
									top: 7px;
									z-index: 1;
									right: 0;
								}
								figure {
									width: 50px;
									border-radius: 100%;
									height: 50px;
								}
							}
							.profile {
								h5 {
									font-size: 15px;
									padding-bottom: 2px;
									color: #3c3c3c;
									@media (max-width: 1200px) {
										font-size: 14px;
									}
								}
								p {
									color: #3c3c3c;
									font-size: 14px;
									font-weight: 500;
									@media (max-width: 1200px) {
										font-size: 13px;
									}
								}
							}
						}
						.dbryt {
							display: flex;
							flex-direction: column;
							button {
								width: 40px;
								// background: yellow;
								display: flex;
								justify-content: flex-end;
								margin-left: auto;
							}
							figure {
								width: 18px;
								margin-left: 40px;
							}
							h4 {
								font-size: 14px;
								font-weight: 500;
								padding-bottom: 10px;
								display: flex;
								color: #565656;
								justify-content: flex-end;
								@media (max-width: 1100px) {
									font-size: 13px;
									padding-top: 0px;
								}
							}
						}
					}
				}
			}
		}
		.chatright {
			width: 55%;
			background: white;
			padding: 10px;
			.chatdiv {
				height: 400px;
				overflow-y: scroll;
				.outgoing {
					border-radius: 40px;
					display: flex;
					justify-content: flex-end;
					padding-bottom: 10px;
					position: relative;
					z-index: 1;
					&::after {
						position: absolute;
						content: '';
						background: #ffe8e8;
						width: 10px;
						height: 10px;
						transform: rotate(45deg);
						bottom: 4px;
						right: 20px;
					}

					p {
						width: 50%;
						background: $primary;
						border-radius: 40px;
						padding: 10px;
						font-size: 14px;
						font-weight: 400;
						color: #3c3c3c;
						text-align: center;
						z-index: 2;
					}
					figure {
						width: 177px;
						height: 130px;
						border: 4px solid #ffbebc;
						border-radius: 28px;
						overflow: hidden;
						z-index: 2;
					}
				}

				.or_opt {
					position: relative;
					margin: 20px 0;
					p {
						position: absolute;
						background: #f8f8f8;
						font-size: 13px;
						width: 50px;
						display: flex;
						align-items: center;
						justify-content: center;
						top: 0;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
				.incoming {
					border-radius: 40px;
					display: flex;
					justify-content: flex-start;
					padding-bottom: 10px;
					position: relative;
					z-index: 1;
					&::after {
						position: absolute;
						content: '';
						background: $white;
						width: 10px;
						height: 10px;
						transform: rotate(45deg);
						bottom: 4px;
						left: 30px;
					}
					figure {
						width: 200px;
						height: 130px;
					}
					p {
						width: 50%;
						background: $white;
						border-radius: 40px;
						padding: 10px;
						font-size: 14px;
						font-weight: 400;
						color: #3c3c3c;
						text-align: center;
					}
				}
				.incomingdoc {
					figure {
						width: 200px;
						height: 130px;
					}
				}
			}
			.sendbar {
				display: flex;
				justify-content: space-between;
				background: #ffffff;
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
				border-radius: 25px;
				padding: 1px;
				align-items: center;
				margin-top: 12px;
				.sharebtn {
					display: flex;
					width: 60px;
					figure {
						width: 30px;
						height: 26px;
						margin-left: 5px;
						cursor: pointer;
						@media (max-width: 1200px) {
							width: 25px;
							height: 22px;
						}
					}
				}
				.sendbarr {
					display: flex;
					width: 90%;
					align-items: center;
					input {
						width: 100%;
						border: none;
						outline: none;
					}
					figure {
						width: 80px;
						height: 40px;
						cursor: pointer;
					}
				}
			}
		}
	}
}

//payout
.payoutmain {
	padding-top: 30px;
	h3 {
		font-size: 20px !important;
		padding-bottom: 20px;
		font-weight: 500;
		color: #565656;
		@media (max-width: 1200px) {
			font-size: 17px !important;
		}
	}
	.detaildiv {
		margin-top: 15px;
		background: red;
		width: 300px;
		padding: 30px 20px;
		background: #ffffff;
		border: 1px solid #e4e4e4;
		box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.05);
		border-radius: 20px;
		.detail {
			display: flex;
			justify-content: space-between;
			padding-bottom: 6px;
			.dleft {
				h4 {
					font-size: 14px;
					font-weight: 500;
				}
			}
			.dright {
				h4 {
					font-size: 14px;
					font-weight: 400;
					color: $text;
				}
			}
		}
	}
	.reveivediv {
		margin-top: 30px;
		background: #ffffff;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
		border-radius: 15px;
		padding: 20px;
		.heading {
			display: flex;
			align-items: center;
			figure {
				width: 25px;
				height: 25px;
				margin-right: 10px;
				background: rgba(250, 104, 102, 0.15);
				border-radius: 7px;
				padding: 7px;
			}
			h3 {
				font-size: 20px !important;
				padding-bottom: 0px !important;
				font-weight: 500;
				color: $text;
				@media (max-width: 1200px) {
					font-size: 17px !important;
				}
			}
		}
		.maincontent {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.mcleft {
				width: 45%;
				padding: 20px;
				.MuiFormControl-root {
					// width: 49%;
					margin-right: 0px;
					// background: rgba(86, 86, 86, 0.02) !important;
					// border-bottom: 1px solid rgba(86, 86, 86, 0.2) !important;
					background: white !important;
					border: 1px solid rgba($secondary, 0.3);
					border-radius: 5px !important;
					margin-bottom: 10px !important;
					background: #f5f5f5;
				}
				.w_100 {
					width: 100% !important;
				}
				.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
					background: none !important;
					border: none !important;
				}
				.css-2y464i-MuiInputBase-root-MuiFilledInput-root:before {
					border-bottom: none !important;
				}
				.btndiv {
					width: 100%;
					.btnnn {
						width: 100%;
						font-size: 14px;
						font-weight: 500;
						padding: 14px 20px;
						border-radius: 5px;
						border: 1px solid #fe6e6b;
						background: #fe6e6b;
						color: #fff;
						cursor: pointer;
						box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
							0px 2px 2px 0px rgba(0, 0, 0, 0.14),
							0px 1px 5px 0px rgba(0, 0, 0, 0.12);
						// font-weight: 600;
					}
				}
			}
			.mcryt {
				width: 45%;
				padding: 20px;
				.balance {
					display: flex;
					align-items: center;
					position: absolute;
					h4 {
						font-size: 19px;
						font-weight: 600;
						color: $text;
						@media (max-width: 1200px) {
							font-size: 16px !important;
						}
					}
					p {
						color: #228400;
						font-size: 14px;
						background: #e5ffdc;
						padding: 10px 9px 10px 13px;
						border-radius: 9px;
						margin-left: 10px;
						font-weight: 600;
						display: flex;
						align-items: center;
					}
				}
				figure {
					width: 350px;
					height: 310px;
					margin-left: 90px;

					margin-top: 30px;
					@media (max-width: 1500px) {
						width: 310px;
						margin-left: auto;
					}
					@media (max-width: 1200px) {
						width: 270px;
						margin: auto;
						margin-top: 30px;
					}
				}
			}
		}
	}
	.tabledv {
		margin-top: 20px;
		margin-bottom: 20px;
		& > div {
			&::-webkit-scrollbar {
				height: 3px;
			}
		}
		table {
			width: 100%;
			// margin-top: 20px;
			background: #ffffff;
			// box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
			border-radius: 10px;
			// margin-bottom: 20px;
			thead {
				tr {
					background: transparent !important;
				}
			}
			tr {
				&:nth-of-type(even) {
					background: #efefef;
				}
			}
			th {
				color: #253d4e;
				font-size: 14px;
				font-weight: 600;
				text-align: center;
				padding: 14px 0;
				border-bottom: none !important;
			}
			td {
				color: $text;
				font-size: 14px;
				font-weight: 400;
				text-align: center;
				padding: 14px 0;
				border-bottom: none !important;
				h4 {
					font-size: 14px;
					font-weight: 400;
				}
				figure {
					height: 14px;
					width: 18px;
					margin: 0 auto;
				}
			}

			.ordr-no {
				color: #f74b81;
			}
			.status h4 {
				color: #00baf2;
				background: #c2f1fe;
				padding: 8px 2px;
				border-radius: 6px;
				box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
				width: fit-content;
				min-width: 70%;

				margin: 0 auto;
				font-size: 13px;
				font-weight: bold;
			}
			.status2 h4 {
				color: #3bb77e;
				background: #bcefd8;
				padding: 8px 2px;
				box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
				border-radius: 6px;
				width: fit-content;
				min-width: 70%;
				margin: 0 auto;
				font-size: 13px;
				font-weight: bold;
			}
		}

		.orderMain {
			background: linear-gradient(
				74.43deg,
				#ffffff 2.15%,
				#ffffff 98.88%
			);
			border: 1px solid rgba(86, 86, 86, 0.1);
			border-radius: 20px;
			padding: 14px;

			.productt {
				background: #ffffff;
				box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
				border-radius: 20px;
				padding: 20px;
				margin-bottom: 20px;

				.prtop {
					display: flex;
					justify-content: space-between;

					.prleft {
						width: 80%;
						display: flex;
						align-items: center;

						.plleft {
							padding-right: 20px;

							figure {
								width: 150px;
								height: 150px;

								img {
									height: 100%;
									object-fit: cover;
								}
							}
						}

						.plright {
							h3 {
								font-weight: normal;
							}

							h4 {
								color: $secondary;
								font-size: 25px;
							}

							.bar {
								display: flex;
								align-items: center;

								h5 {
									font-weight: normal;
									font-size: 12px;
									padding-left: 10px;
								}
							}

							.services {
								color: $secondary;
								font-size: 10px;
							}
						}
					}
				}

				.prbtm {
					border-top: 1px solid #5656566e;
					margin: 15px 0;
					padding-top: 10px;

					ul {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;

						li {
							width: 48%;
							display: flex;
							align-items: center;
							padding-top: 18px;
						}
					}
				}
			}
		}
	}
}

//reference
.refermain {
	padding-top: 30px;
	.rtop,
	.rBtm {
		background: #ffffff;
		box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
		border-radius: 10px;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		.rtleft {
			width: 46%;
			figure {
				width: 100%;
				height: 230px;
			}
		}
		.rtRyt {
			width: 42%;
			padding: 0 40px;

			h2 {
				font-size: 25px;
				color: #253d4e;
				text-align: center;
			}
			p {
				padding: 10px 0;
				text-align: center;
				color: $text;
				font-size: 14px;
			}
			.btndiv {
				width: 100%;
				display: flex;
				padding-top: 10px;
				button {
					margin: auto;
				}
			}
		}
	}
	.modolstyle {
		.sharemodal {
			width: 100%;
			margin: auto;
			.crossbar {
				background: #565656;
				opacity: 0.5;
				border-radius: 15px 15px 0px 0px;
				padding: 10px;
				figure {
					width: 35px;
					height: 35px;
					margin-left: auto;
					cursor: pointer;
				}
			}
			.sharedata {
				padding: 20px 0;
				.Gmail {
					width: 30%;
					margin: auto;
					background: #ffffff;
					box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
					border-radius: 500px;
					padding: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					figure {
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}
				}
				.sharediv {
					width: 70%;
					margin: auto;
					padding-top: 20px;
					h3 {
						font-size: 16px;
						color: $text;
					}
					ul {
						display: flex;
						width: 100%;
						justify-content: space-between;
						padding: 14px 0;
						li {
							padding: 6px 15px;
							border: 1px solid rgba(86, 86, 86, 0.2);
							// background: rgba(254, 110, 107, 0.1);
							// border: 1px solid rgba(254, 110, 107, 0.3);
							border-radius: 10px;
							// margin-right: 20px;
							&:hover {
								background: rgba(254, 110, 107, 0.1);
								border: 1px solid rgba(254, 110, 107, 0.3);
								img {
									filter: none !important;
								}
							}
							figure {
								width: 20px;
								height: 20px;
							}
							img {
								filter: brightness(0.2);
							}
						}
					}
					.copycode {
						display: flex;
						align-items: center;
						.MuiFormControl-root {
							width: 90%;
							margin-right: 14px;
							background: rgba(86, 86, 86, 0.02) !important;
							border-bottom: 1px solid rgba(86, 86, 86, 0.2) !important;
							border-radius: 5px !important;
							// margin-bottom: 20px !important;
						}
						.w_100 {
							width: 100% !important;
						}
						.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
							background: none !important;
							border: none !important;
						}
						.css-2y464i-MuiInputBase-root-MuiFilledInput-root:before {
							border-bottom: none !important;
						}
						h3 {
							font-size: 14px;
							color: $secondary;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}

//account

.logout {
	width: 60%;
	margin: auto;
	padding-top: 70px;
	@media (max-width: 1280px) {
		width: 75%;
	}
	.crossbar {
		background: #565656;
		opacity: 0.5;
		border-radius: 15px 15px 0px 0px;
		padding: 10px;
		figure {
			width: 35px;
			height: 35px;
			margin-left: auto;
			cursor: pointer;
		}
	}
	.logdata {
		background: #ffffff;
		box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
		border-radius: 0px 0px 15px 15px;
		padding: 30px 30px;
		h5 {
			font-size: 15px;
			text-align: center;
			color: $text;
		}
		p {
			color: #acacac;
			font-size: 14px;
			text-align: center;
			padding: 15px 0;
		}
		.btns {
			display: flex;
			justify-content: space-between;
			padding-top: 30px;
		}
	}
}
