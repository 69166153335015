@import '../../assets/theme/variable.scss';

section.ftrs {
	padding: 0px 0 70px;

	ul.mn_ftrs {
		display: flex;
		justify-content: space-between;
		align-items: stretch;

		li {
			border: 1px solid rgba($text, 0.1);
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			width: 20%;
			padding: 30px 10px 20px;
			border-radius: 2px;
			text-align: center;
			background: $white;
			border-radius: 6px;
			figure {
				height: 50px;
				width: 50px;
				margin: 0 auto;
				margin-bottom: 20px;
				background: $primary;
				padding: 20px;
				border-radius: 50%;
			}

			h2 {
				font-size: 18px;
				font-weight: 600;
				color: $headings;
				margin: 6px 0;
			}

			p {
				font-size: 14px;
				line-height: 26px;
				color: $text;
				width: 100%;
				margin: 0 auto;
			}
		}
	}
}

section.buy_n_sell {
	padding: 30px 0 30px;
	background: url('../../../public/static/images/buy_n_sell_bg.svg');
	height: 290px;
	width: 100%;
	background-size: 100%;
	background-repeat: no-repeat;
	@media (max-width: 1920px) {
		height: 430px;
	}
	@media (max-width: 1700px) {
		height: 380px;
	}
	@media (max-width: 1500px) {
		height: 330px;
	}
	@media (max-width: 1300px) {
		height: 270px;
	}
	@media (max-width: 1200px) {
		height: 250px;
	}
	// background: $secondary;

	.mn_sctn {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		align-items: center;
		@media (max-width: 1920px) {
			padding: 80px 0;
		}
		@media (max-width: 1700px) {
			padding: 50px 0;
		}
		@media (max-width: 1500px) {
			padding: 20px 0;
		}
		@media (max-width: 1366px) {
			padding: 0;
		}

		.sctn_lft {
			width: 45%;
			// padding-top: 20px;

			h2 {
				font-size: 34px;
				font-weight: 600;
				padding-bottom: 20px;
				color: $white;
			}

			p {
				font-size: 16px;
				line-height: 30px;
				color: $white;
			}

			button {
				background: white;
				margin-top: 20px;
				&:hover {
					border: 1px solid $white;
					background: transparent;
				}
			}
		}

		.sctn_ryt {
			width: 55%;

			.buy-nw-lottie {
				width: 100%;
				height: 270px;
			}
		}
	}
}

section.hot_dls {
	padding: 40px 0 40px;

	.mn_dls {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.dls_lft {
			width: 45%;

			.hot_dl_lottie {
				width: 100%;
				height: 400px;
			}
		}

		.dls_ryt {
			width: 50%;
			padding-top: 60px;

			h4 {
				font-size: 14px;
				font-weight: 600;
				line-height: 30px;
				color: $secondary;
				background-color: $primary;
				padding: 8px 14px;
				border-radius: 10px;
				width: max-content;
			}

			h2 {
				font-size: 26px;
				font-weight: 700;
				line-height: 60px;
				color: $headings;
			}

			p {
				font-size: 14px;
				line-height: 26px;
				color: $text;
			}

			.price {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				h2 {
					font-size: 34px;
				}

				p {
					margin-left: 10px;
					font-size: 20px;
				}
			}

			.frsh_dls {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				ul.tym_lft {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-left: 10px;

					li {
						h4 {
							font-size: 16px;
							font-weight: 700;
						}
					}

					span {
						font-size: 18px;
						font-weight: 600;
						color: $secondary;
						margin: 0 6px;
					}
				}
			}
		}
	}
}

section.buy_n_sell {
	@media (max-width: 992px) {
		padding: 40px 0 40px;
	}
	.mn_sctn {
		.sctn_lft {
			@media (max-width: 992px) {
				width: 100%;
				text-align: center;
			}
			h2 {
				@media (max-width: 1300px) {
					font-size: 30px;
				}
			}

			p {
				@media (max-width: 1300px) {
					font-size: 13px;
					line-height: 28px;
				}
			}
		}

		.sctn_ryt {
			@media (max-width: 992px) {
				display: none;
			}
			.buy-nw-lottie {
				@media (max-width: 1300px) {
					height: 260px;
				}
				@media (max-width: 1200px) {
					height: 220px;
				}
			}
		}
	}
}

section.ftrs {
	@media (max-width: 1200px) {
		// padding: 40px 0 40px;
	}
	ul.mn_ftrs {
		@media (max-width: 992px) {
			flex-wrap: wrap;
		}
		li {
			@media (max-width: 1200px) {
				padding: 20px 10px;
			}
			@media (max-width: 992px) {
				width: 44%;
				margin-bottom: 20px;
			}
			@media (max-width: 480px) {
				width: 100%;
			}

			figure {
				@media (max-width: 1440px) {
					height: 40px;
					width: 40px;
					padding: 14px;
				}
			}

			h2 {
				@media (max-width: 1300px) {
					font-size: 16px;
				}
				@media (max-width: 1200px) {
					font-size: 14px;
				}

				@media (max-width: 992px) {
					font-size: 11px;
				}
			}

			p {
				@media (max-width: 1300px) {
					font-size: 12px;
				}
				@media (max-width: 1200px) {
					font-size: 12px;
				}

				@media (max-width: 992px) {
					font-size: 9px;
				}
			}
		}
	}
}

section.hot_dls .mn_dls {
	.dls_lft {
		@media (max-width: 992px) {
			display: none;
		}
	}

	.dls_ryt {
		@media (max-width: 1300px) {
			padding-top: 80px;
		}

		@media (max-width: 1200px) {
			padding-top: 90px;
		}
		@media (max-width: 992px) {
			width: 100%;
			padding-top: 30px;
		}

		h4 {
			@media (max-width: 1200px) {
				font-size: 12px;
				padding: 4px 14px;
			}
		}

		h2 {
			@media (max-width: 1200px) {
				font-size: 20px;
				line-height: 42px;
			}

			@media (max-width: 992px) {
				font-size: 22px;
				line-height: 52px;
			}
			@media (max-width: 481px) {
				line-height: 44px;
			}
		}

		p {
			@media (max-width: 1300px) {
				font-size: 12px;
			}

			@media (max-width: 1200px) {
				font-size: 11px;
				line-height: 24px;
			}
		}

		.price {
			@media (max-width: 1200px) {
				padding: 10px 0;
			}

			h2 {
				@media (max-width: 1200px) {
					font-size: 32px;
				}
			}
			p {
				@media (max-width: 1200px) {
					font-size: 20px;
				}
			}
		}
		.frsh_dls {
			@media (max-width: 480px) {
				justify-content: center;
				align-items: flex-start;
				flex-direction: column;
				.tym_lft {
					margin: 10px 0 !important;
				}
			}
		}
	}
}
